import React, { Component } from 'react';
import { connect } from "react-redux";
import helpers from "../helpers";
import { withRouter } from 'react-router-dom';
import { Container, FormGroup, Input, Col, Button, Form, InputGroup, InputGroupAddon } from "reactstrap";
import axios from "axios";
var md5 = require("md5");
const restURL = helpers.restURL;
let url = window.location.origin;
url = url === "http://localhost:3000" ? "http://localhost" : url;
url = url + "/downloads/logo/logo.png";
url = url.toString();
//Test

class ActivateUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            showLoading: "none",
            showSuccessvalidation: "none",
            showError: "none",
            btnText: "Activate",
            passwordFieldType: "password",
            passwordFieldType2: "password",
            invalidCredentials: "",
            timestamp:new Date().getTime()
        };
        this.validateForm = this.validateForm.bind(this);
        this.processData = this.processData.bind(this);
        this.getactivateuserstatus = this.getactivateuserstatus.bind(this);
    }

    componentWillMount() {
        document.body.style.setProperty("background", `#272b35`);
    }

    componentDidMount() {
        this.getactivateuserstatus();
    }

    getactivateuserstatus() {
        var url = window.location.href;
        var token_value = 'nil';
        if (url.indexOf('=') > 0) {
            token_value = url.substring(url.indexOf('=') + 1);
        }
        axios({
            method: "GET",
            url: restURL + '?rest=useractivationstatus&q=' + token_value + '/',
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },

            responseType: "json"
        }).then(res => {
            var currentDate = new Date();
            var unix_timestamp = res.data.data.createddate;
            var createdDate = new Date(unix_timestamp * 1000);
            var milliseconds = currentDate.getTime() - createdDate.getTime();
            var differenceHours = Math.floor(milliseconds / (60 * 60 * 1000));

            if ("" == res.data || (!res.data) || (null == res.data)) {

            } else if (differenceHours >= 48) {
                this.setState({ showError: "inline-block", invalidCredentials: "Activation token expired! Contact Admin" });
                setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 3000);
                this.processData();
            } else {
                if (res.data.data.status == "deleted") {
                    this.setState({ showError: "inline-block", invalidCredentials: "Account Deleted! Contact Admin" });
                    setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 3000);
                    this.processData();
                } else if (res.data.data.status == "active") {
                    this.setState({ showError: "inline-block", invalidCredentials: "Account already active. Redirecting to login" });
                    setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 3000);
                    this.processData();
                }

            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    validateForm() {

        let password = this.state.password;
        let confirmPassword = this.state.confirmPassword;
        if (password === "") {
            this.setState({ showError: "inline-block", invalidCredentials: "Password is required" });
            setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 3000);
            return
        }

        var regularExpression = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
        if (!regularExpression.test(password)) {
            this.setState({ showError: "inline-block", invalidCredentials: "Password should have a minimum of eight characters and include at least one uppercase, one lowercase, one special character, and one number." });
            return
        }

        if (confirmPassword === "") {
            this.setState({ showError: "inline-block", invalidCredentials: "Confirm Password, Password is required" });
            setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 3000);
            return
        }

        if (password !== confirmPassword) {
            //Author - Gayathri Changed Error Message for Password MissMatch - july 3
            this.setState({ showError: "inline-block", invalidCredentials: "Password doesn't match." });
            setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 3000);
            return
        }
        

        this.setState({ showLoading: "inline-block", showError: "none", invalidCredentials: "" });
        var url = window.location.href;
        var token_value = 'nil';
        if (url.indexOf('=') > 0) {
            token_value = url.substring(url.indexOf('=') + 1);
        }
        var data = {

            password: md5(this.state.password),
            confirmpassword: md5(this.state.confirmPassword),
            activationtoken: token_value,
            rest: 'useractivation',
            module: 'users'
        };
        axios({
            method: "POST",
            url: restURL,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            data: data,
            responseType: "json"
        }).then(res => {
            this.setState({ showLoading: "none" });
            if (res.data.success) {
                this.processData();
            } else {
                if(res.data.data != null){
                    this.setState({ showError: "inline-block", invalidCredentials: res.data.data });
                    setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 5000);
                }else if(res.data.displaymessage != null && res.data.displaymessage != "undefined"){
                    this.setState({ showError: "inline-block", invalidCredentials: res.data.displaymessage });
                    setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 5000);
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    processData() {
        this.setState({ btnText: "Redirecting... ", showSuccessvalidation: "inline-block" });
        // let userData = helpers.getSessionStorage("userData");
        // let data = JSON.parse(userData);
        // console.log("userdata",data);
        // data.hassecuraalicence = true;

        // let sessionUpdatedObj = JSON.stringify(data);
        // window.sessionStorage.setItem("userData", sessionUpdatedObj);
        setTimeout(() => {
            // this.props.history.push("/home");
            this.props.history.push("/login");
        }, 1500);
    }

    render() {
        return (
            <Container>
                <Col sm="12">
                    <Col sm="3">
                    </Col>
                    <Col sm="6" className="login_form">
                        <div className={this.state.showError === "none" ? "license-form" : "errorInputForm license-form"} style={{ width: "90%" }}>
                            <form className="login_content" onSubmit={(e) => { e.preventDefault(); }}>
                                <img src={url+ "?t=" + this.state.timestamp} alt="logo" style={{ width: "100%", marginBottom: "15px",height:"auto" }} />
                                <div>
                                {/* Author - Gayathri Changed the color of text - Sep-11 */}
                                    <h5 style={{ color: "#09897e" }}>Activate User</h5>
                                    <InputGroup>
                                        <Input style={{ background: "#343a40", height: "35px", borderColor: "#f8f9fa8c", color: "#cfd2da" }} placeholder="Password" type={this.state.passwordFieldType} name="password" id="password" value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} />
                                        <InputGroupAddon addonType="append">
                                            <Button color="secondary" className="task-input-field-button"
                                                onMouseDown={() => this.setState({ passwordFieldType: "text" })}
                                                onMouseUp={() => this.setState({ passwordFieldType: "password" })}

                                                onMouseOut={() => this.setState({ passwordFieldType: "password" })}
                                                onClick={() => { return false; }}
                                            ><i className="fa fa-eye"></i></Button>
                                        </InputGroupAddon>
                                    </InputGroup>

                                    <InputGroup>
                                        <Input style={{ background: "#343a40", height: "35px", borderColor: "#f8f9fa8c", color: "#cfd2da" }} placeholder="Confirm Password" type={this.state.passwordFieldType2} name="confirmPassword" id="confirmPassword" value={this.state.confirmPassword} onChange={(event) => this.setState({ confirmPassword: event.target.value })} />
                                        <InputGroupAddon addonType="append">
                                            <Button color="secondary" className="task-input-field-button"
                                                onMouseDown={() => this.setState({ passwordFieldType2: "text" })}
                                                onMouseUp={() => this.setState({ passwordFieldType2: "password" })}

                                                onMouseOut={() => this.setState({ passwordFieldType2: "password" })}
                                                onClick={() => { return false; }}><i className="fa fa-eye"></i></Button>
                                        </InputGroupAddon>
                                    </InputGroup>

                                    <FormGroup>
                                        <Button color="success" type="submit" style={{ width: "150px" }} onClick={this.validateForm}>{this.state.btnText}&nbsp;
                                        <i style={{ display: this.state.showLoading }} className="fa fa-circle-o-notch fa-spin"></i>
                                            <i style={{ display: this.state.showSuccessvalidation }} className="fa fa-check"></i>
                                        </Button>
                                    </FormGroup>

                                    {typeof this.state.invalidCredentials!=="undefined" && this.state.invalidCredentials!== null && this.state.invalidCredentials!==""?
                                    <FormGroup>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" style={{ marginTop: "20px" }}>
                                            <div className="alert alert-danger"
                                                style={{ background: "rgba(231,76,60,0.88)", border: "none", width: "100%", display: this.state.showError }}>
                                                <h6 style={{ color: "white", marginTop: "3px", marginBottom: "10px" }}>
                                                    {this.state.invalidCredentials}
                                                </h6>
                                            </div>
                                        </div>
                                    </FormGroup>
                                    :""}
                                </div>
                            </form>
                        </div>

                    </Col>
                    <Col sm="3">
                    </Col>
                </Col>
            </Container>
        )
    }
}

const exportActivateUser = withRouter(connect()(ActivateUser));
export default exportActivateUser;
