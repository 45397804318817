import React, { useEffect, useState } from "react";
import helpers from "../helpers";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Container, FormGroup, Input, Col, Button, Form, Label, Modal, ModalHeader, ModalBody } from "reactstrap";
import logo from "../images/securaa-logo-white-crop.png";
import axios from "axios";
import LoadingSpinner from './LoadingSpinner';
import cogoToast from 'cogo-toast';


const restURL = helpers.restURL;


export const License2 = (props) => {
    const [accesstoken, setaccesstoken] = useState("");
    const [showLoading, setshowLoading] = useState("none");
    const [showSuccessvalidation, setshowSuccessvalidation] = useState("none");
    const [showError, setshowError] = useState("none");
    const [btnText, setbtnText] = useState("Activate License");
    const [licenseExpired, setlicenseExpired] = useState(false);
    const [invalidCredentials, setinvalidCredentials] = useState("");
    const [showWarningModal, setshowWarningModal] = useState(false);
    const [emailvalue, setemailvalue] = useState("");
    const [emailerrorMessage, setemailerrorMessage] = useState("");
    const [showsubmitbtn, setshowsubmitbtn] = useState(true);
    const [loading, setloading] = useState("hide");
    const [licenseErrorMessage,setlicenseErrorMessage]=useState("");
    const [showErrorMessage,setshowErrorMessage] = useState("hide");

    const theme = useSelector(state => state.reducer.theme.theme);
    const dispatch = useDispatch();

    useEffect(() => {
        document.body.style.setProperty("background", `#272b35`);
        let userData = helpers.getSessionStorage("userData");
        if (userData === "" || userData === null || typeof userData === 'undefined') {
            //helpers.checkSessionTimeout(true);
        } else {
            let data = JSON.parse(userData);
            if (data.hassecuraalicence) {
                props.history.push("/home");
            }
        }

        let licenseResponse = helpers.getSessionStorage("licenseResponse");

        if (licenseResponse !== "" || licenseResponse !== null || typeof licenseResponse !== 'undefined') {
            let licenseData;
            try {
                licenseData = JSON.parse(licenseResponse)
            } catch (e) {
                console.log(e);
            }

            if (typeof licenseData !== 'undefined' && licenseData !== null && Object.keys(licenseData).length > 0) {
                
                if(licenseData.securaalicenceErrorMessage !== null && licenseData.securaalicenceErrorMessage !== undefined && licenseData.securaalicenceErrorMessage !== ""){
                    setlicenseExpired(true);
                    if (licenseData.securaalicenceErrorMessage === "License has expired") {
                        setlicenseErrorMessage("License has expired");
                    }else if(licenseData.securaalicenceErrorMessage === "Invalid License key / Invalid macaddress"){
                        setlicenseErrorMessage("Invalid License");
                    }else if(licenseData.securaalicenceErrorMessage === "MAC Address not found, Cannot validate license"){
                        setlicenseErrorMessage("Cannot validate license");
                    }
                }   
            }
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setshowError("none");
            setinvalidCredentials("");
        }, 3000);
        return () => clearTimeout(timer);
    }, [showError, invalidCredentials]);

    const validateForm = () => {
        if (accesstoken === "") {
            setshowError("inline-block");
            setinvalidCredentials("License key is required");
            // setTimeout(() => setshowError("inline-block"),
            //     setinvalidCredentials(""), 3000);
            // return
        }

        if (accesstoken !== "") {
            setshowLoading("inline-block");
            setshowError("none");
            setinvalidCredentials("");
            var data = {
                companycode: helpers.getCookie('companycode'),
                securaa_access_key: accesstoken.trim(),
                rest: 'savesecuraalicence',
                module: 'users'
            };
            axios({
                method: "POST",
                url: restURL,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                data: data,
                responseType: "json"
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (res.data.success) {
                    setDashboardPermissions(res.data);
                    processData();

                } else {
                    if (res.data.displaymessage !== "") {
                        setshowError("inline-block");
                        setinvalidCredentials(res.data.displaymessage);
                    } else {
                        setshowError("inline-block");
                        setinvalidCredentials(res.data.data);
                    }
                    // setTimeout(() => setshowError("inline-block"),
                    //     setinvalidCredentials(""), 5000);
                }
                setshowLoading("none")
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            setshowLoading("none")
        }
    }

    const setDashboardPermissions = (data) => {
        helpers.setCookie("soar_support", data.soar_support);
        helpers.setCookie("avm_support", data.avm_support);
        helpers.setCookie("tip_support", data.tip_support);
    }

    const processData = () => {
        setbtnText("Redirecting... ");
        setshowSuccessvalidation("inline-block");
        let userData = helpers.getSessionStorage("userData");
        let data = JSON.parse(userData);
        data.hassecuraalicence = true;

        let sessionUpdatedObj = JSON.stringify(data);
        helpers.setSessionStorage("userData", sessionUpdatedObj);
        setTimeout(() => {
            // if (data.tenantsinfo.length === 0) {
            //     props.history.push("/configure");
            // } else {
            //     // props.history.push("/home");
            //     setshowErrorMessage("show")
            //     const timer = setTimeout(() => {
            //         props.history.push("/logout");
            //     }, 3000);
            //     return () => clearTimeout(timer);
            // }
                setshowErrorMessage("show")
                const timer = setTimeout(() => {
                    props.history.push("/logout");
                }, 3000);
                return () => clearTimeout(timer);
        }, 500);
        window.sessionStorage.removeItem("licenseResponse");
    }

    const logout = () => {
        props.history.push("/logout");
    }

    const showTrailmodal = () => {
        setshowWarningModal(true);
    }

    const toggleWarningModal = () => {
        setshowWarningModal(!showWarningModal);
        setshowsubmitbtn(true);
        setemailerrorMessage("");
        setemailvalue("");
    }

    const Savelicense = async () => {
        if (emailvalue !== "") {
            try {
                setloading("show");
                let url = restURL;
                let data = {
                    email: emailvalue,
                    module: "manager",
                    rest: "starttriallicense",
                    companycode: helpers.getCookie('companycode')
                }
                let responseData = await helpers.buildAxiosObject("POST", url, data);
                if (responseData !== null) {
                    if (responseData.data.success) {
                       //Since we don't get this value at the time of license creation, we are setting this value by default to 'yes', Currently we support only SOAR module for trial license
                        helpers.setCookie("soar_support", "yes");
                        helpers.securaaNotification(<div>{responseData.data.data} - License Will Expire in 30 days From Today </div> ,"success",10)
                        processlicenseData();
                       // props.history.push("/home");
                    } else {
                        if (responseData.data.displaymessage !== undefined && responseData.data.displaymessage !== undefined && responseData.data.displaymessage !== "") {
                            helpers.securaaNotification(<div>{responseData.data.displaymessage}</div>,"error",10)
                        } else if (responseData.data.error !== undefined && responseData.data.error !== undefined && responseData.data.error !== "") {
                            helpers.securaaNotification(<div>{responseData.data.error}</div>,"error",10)
                        } else {
                            helpers.securaaNotification("Error in response.","error",10)
                        }
                    }
                }
                setloading("hide");
            } catch (e) {

            }

        } else {
            setemailerrorMessage("Email Required");
        }
    }

    const processlicenseData=()=>{
        let userData = helpers.getSessionStorage("userData");
        let data = JSON.parse(userData);
        data.hassecuraalicence = true;

        let sessionUpdatedObj = JSON.stringify(data);
        helpers.setSessionStorage("userData", sessionUpdatedObj);
        setTimeout(() => {
            if (data.tenantsinfo.length === 0) {
                props.history.push("/configure");
            } else {
                props.history.push("/home");
            }
        }, 1000);
        window.sessionStorage.removeItem("licenseResponse");

    }
    const changeemail = (event) => {
        setemailvalue(event.target.value)
        let patt = new RegExp(helpers._pattern["email"]);
        let res = patt.test(event.target.value);
        if (event.target.value === "") {
            setemailerrorMessage("Email is required");
            setshowsubmitbtn(true);
        } else if (!res) {
            setemailerrorMessage("Invalid Email");
            setshowsubmitbtn(true);
        } else {
            setemailerrorMessage("");
            setshowsubmitbtn(false);
        }
    }

    let modalStyle = { color: "" };
    let inputStyle = { color: "" };
    if (typeof props.theme !== 'undefined') {
        inputStyle = props.theme.input;
        modalStyle = props.theme.modal;
    }
    const fontColor = props.theme ? props.theme.color : "#2A3139";

    return (
        <Container>
            <LoadingSpinner showSpinner={loading} />
            <Col sm="12" className=" ">
                <div>
                    <img src={logo} alt="logo" style={{ marginBottom: "15px", height: "120px" }} />
                    {/* <h5 style={{ color: "#f0ffff" }}><i class="fa fa-copyright"></i> Copyright 2022 Bytamorph Zona Pvt Ltd</h5> */}
                </div>
                {licenseExpired ?
                    <React.Fragment>
                        <div style={{ color: "#dc3545",fontSize:"30px" }}>{licenseErrorMessage}</div>
                    </React.Fragment>
                :""}
                <Col sm="6">
                    <div className={showError === "none" ? "license-form" : "errorInputForm license-form"} style={{ width: "90%", maxHeight: "450px" }}>
                        <div style={{ width: "100%" }}>
                            <React.Fragment>
                                <h3 style={{ color: "#f0ffff", textAlign: "left", marginBottom: "20px" }}>Securaa Free Trial</h3>
                            </React.Fragment>
                        </div>
                        <div style={{ textAlign: "left" }}>
                            <h5 style={{ fontWeight: "bold" }}>Supported Features :</h5>
                            <ul>
                                <li className="licenselist">5 Applications</li>
                                <li className="licenselist">2 Users</li>
                                <li className="licenselist">Unlimited incidents ingestion</li>
                                <li className="licenselist">10 Playbooks per day</li>
                                <li className="licenselist">50 Tasks per day</li>
                                <li className="licenselist">10 Reports per day</li>

                            </ul>
                            <FormGroup style={{ textAlign: "center" }}>
                                <Button color="success" id="licenseTrailbtn" size="md" onClick={showTrailmodal}>Start 30 Days Free Trial
                                </Button>
                            </FormGroup>
                        </div>
                    </div>
                </Col>
                <Col sm="6">
                    <div className={showError === "none" ? "license-form" : "errorInputForm license-form"} style={{ width: "90%", maxHeight: "450px" }}>
                        <div style={{ width: "100%" }}>

                            <React.Fragment>
                                <span className="pull-right cursor-point license-logout-btn"
                                    onClick={() => logout()}
                                    style={{ color: "#f0ffff" }} title="Logout">
                                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                                </span>
                                <h3 style={{ color: "#f0ffff", textAlign: "left", marginBottom: "20px" }}>
                                    {/* {helpers.getCookie("build_type") === "mssp" ? "Securaa MSSP" : "Securaa Enterprise"} */}
                                    Securaa
                                    </h3>

                            </React.Fragment>
                        </div>
                        <div style={{ textAlign: "left" }}>
                            {!licenseExpired ?
                                <React.Fragment>
                                    <h5 style={{ color: "#20c997" }}>Just one step behind !
                                    {/* <a href="https://www.securaa.io/" className="documentPath" style={{ "color": "#00BFFF", "fontSize": "14px" }} rel="noopener noreferrer" target="_blank">Documentation</a> */}
                                    </h5>
                                    <h6 style={{ color: "#f0ffff" }}> Enter your license key to continue...</h6>
                                </React.Fragment>
                                : 
                                <React.Fragment>
                                <h6 style={{ color: "#f0ffff" }}>Please renew or enter new license key to continue...</h6>
                            </React.Fragment>    }

                            <FormGroup style={{ marginTop: "30px"}}>
                                <Input type="text" value={accesstoken} onChange={(event) => setaccesstoken(event.target.value)} autoComplete="new-password-type" name="accesstoken" id="licenceKey" placeholder="License key" style={{ background: "#343a40", height: "45px", borderColor: "#f8f9fa8c", color: "#cfd2da", marginBottom: "20px" }} />
                            </FormGroup>

                            <FormGroup style={{ textAlign: "center" }}>
                                <Button color="success" size="md" style={{ width: "150px" }} id="licenseNextbtn" onClick={validateForm}>{btnText}&nbsp;
                                        <i style={{ display: showLoading }} className="fa fa-circle-o-notch fa-spin"></i>
                                    <i style={{ display: showSuccessvalidation }} className="fa fa-check"></i>
                                </Button>
                            </FormGroup>
                            <div className={"text-center "+showErrorMessage} style={{fontSize:"16px",color:"#09897e",fontWeight:"bold",marginTop:"20px"}}>License activated. Redirecting to login screen</div>
                            <FormGroup style={{ marginTop: "24%", width: "98%",paddingBottom:"2px" }}>
                                <h5 className="text-center">
                                    <a href="https://www.securaa.io/contact-us/" style={{ color: "#00a1ff" }} target="_blank">Request for License</a>&nbsp;
                                    or mail us at <span style={{ color: "#00a1ff", textDecoration: "underline" }}>support@securaa.io</span>
                                </h5>
                            </FormGroup>
                            <FormGroup>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" style={{ marginTop: "20px" }}>
                                {invalidCredentials !== null && invalidCredentials !== undefined && invalidCredentials !== "" ?
                                    <div className="alert alert-danger"
                                        style={{ background: "rgba(231,76,60,0.88)", border: "none", width: "100%", height: "50px", display: showError }}>
                                        <h6 style={{ color: "white", marginTop: "3px", marginBottom: "10px" }}>
                                            {invalidCredentials}
                                        </h6>
                                    </div>
                                    :<React.Fragment/>}
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                </Col>

            </Col>
            <Modal size="md" fade={false} isOpen={showWarningModal} toggle={() => toggleWarningModal("")} >
                <ModalHeader toggle={() => toggleWarningModal("")} ><span style={{ fontSize: "20px" }}>Get License </span></ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label style={{ float: 'left' }}>Email</Label>
                        <Input type="text" value={emailvalue} id="licenseEmail" onChange={(event) => changeemail(event)} name="email" id="email" placeholder="Enter Email" style={{ background: "#343a40", height: "35px", borderColor: "#f8f9fa8c", color: "#cfd2da", marginBottom: "20px" }} />
                        <div className={"errorMessage"}>{emailerrorMessage}</div>

                    </FormGroup>

                    <div className="text-right">
                        <Button size="sm" color="success" id="licenseSubmitbtn" onClick={() => Savelicense("")} disabled={showsubmitbtn}>Submit</Button>
                        <Button size="sm" color="secondary" id="licenseCancelbtn" onClick={() => toggleWarningModal("")}>Close</Button>
                    </div>
                </ModalBody>
            </Modal>
        </Container>
    )
}
const exportLicense2 = withRouter(connect()(License2));
export default exportLicense2;
