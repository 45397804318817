export function getDefaultTheme() {
    return {
        type: "getDefaultTheme"
    }
}

export function lightTheme() {
    return {
        type: "light"
    }
}

export function darkTheme() {
    return {
        type: "dark"
    }
}
