import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDefaultTheme, lightTheme, darkTheme } from "../actions/themeAction";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import { Button, Input, Label, FormGroup, Row } from 'reactstrap';
import helpers from "../helpers";
// import { ToastContainer, toast } from 'react-toastify';
const restURL = helpers.restURL;

class AssetBatchSetting extends Component {
    constructor() {
        super();
        this.state = {
            assetRunTime: "",
            disableAssetUpdateTimeBtn: true,
            assetBatchRunTimeError: "hide",
            assetBatchRunTimeErrorMessage: "hide",
        }

        this.UpdateSystemConfigVariables = this.UpdateSystemConfigVariables.bind(this);
        this.redirectMessage = this.redirectMessage.bind(this);
        this.getSystemConfigVariables = this.getSystemConfigVariables.bind(this);
        this.assetTimeChange = this.assetTimeChange.bind(this);
    }

    componentDidMount() {
        this.getSystemConfigVariables();
        if (helpers.getSessionStorage("theme") === "dark") {
            this.props.darkTheme();
        } else {
            this.props.lightTheme();
        }
    }

    redirectMessage(text) {
        helpers.securaaNotification(text,"success",10);
        // toast.success(text, {
        //     position: "top-right",
        //     autoClose: 2000,
        //     closeOnClick: true,
        //     hideProgressBar: true,
        //     pauseOnHover: false,
        //     draggable: false
        //     // transition: "flip"
        // });
    }

    assetTimeChange(event) {
        let value = event.target.value;
        if (value === "") {
            this.setState({ disableAssetUpdateTimeBtn: true, assetBatchRunTimeError: "show", assetBatchRunTimeErrorMessage: "Time is required" });
        } else {
            if (value.match(/^[0-9]*$/)) {
                this.setState({ disableAssetUpdateTimeBtn: false, assetBatchRunTimeError: "hide", assetBatchRunTimeErrorMessage: "" });
            } else {
                this.setState({ disableAssetUpdateTimeBtn: true, assetBatchRunTimeError: "show", assetBatchRunTimeErrorMessage: "Only numbers" });
            }
        }
        this.setState({ assetRunTime: value });
    }

    UpdateSystemConfigVariables(variable) {
        var url = restURL;
        let value = (this.state.assetRunTime).trim();
        var data = {
            tenantcode: helpers.getCookie('tenantcode'),
            rest: "setavmsettingfortenant",
            module: "assetExplorer",
            assetbatchfrequency: parseInt(value)
        };
        axios({
            method: "POST",
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                this.redirectMessage("Saved");
            } else {
                this.setState({
                    errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    getSystemConfigVariables() {

        var url = restURL + "?module=assetExplorer&rest=getavmsettingfortenant&q=" + helpers.getCookie("tenantcode") + "/";
        axios({
            method: "GET",
            url: url,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                if (res.data.data !== null && res.data.data.AssetBatchFrequency !== null) {
                    this.setState({ assetRunTime: res.data.data.AssetBatchFrequency });
                }
            } else {
              if(res.data.displaymessage.toLowerCase() == "securaa server is not reachable"){
                this.setState({ errorMessage: <h6 className="text-center">AVM Server is Not Reachable</h6> });
            }else{
              this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6> });
            }

                this.redirectMessage(this.state.errorMessage);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    render() {
        const fontColor = this.props.theme ? this.props.theme.color : "#2A3139";
        let inputStyle = { color: "" };
        if (typeof this.props.theme !== 'undefined') {
            inputStyle = this.props.theme.input;
        }
        return (
            <React.Fragment>
                <FormGroup>
                    <Label style={{ float: 'left', color: fontColor }}>Asset Batch run time (in minutes)</Label>
                    <Input type="text" maxLength="2" value={this.state.assetRunTime} placeholder="Asset Batch run time" onChange={this.assetTimeChange} style={inputStyle} />
                    <div className={"InputError " + this.state.assetBatchRunTimeError + " errorMessage"} >{this.state.assetBatchRunTimeErrorMessage}</div>
                </FormGroup>
                {helpers.getCookie("_platformWritePersmission") === "true" ? <Button size="sm" style={{ float: 'left' }} color="success" onClick={() => this.UpdateSystemConfigVariables("asset_batch_run_time")} disabled={this.state.disableAssetUpdateTimeBtn}>
                    Save
                </Button> : ""}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getDefaultTheme: getDefaultTheme, lightTheme: lightTheme, darkTheme: darkTheme }, dispatch)
}

const exportAssetBatchSetting = withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetBatchSetting));
export default exportAssetBatchSetting;
