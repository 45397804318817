import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { getDefaultTheme, lightTheme, darkTheme } from "../actions/themeAction";
import Theme from "./theme";
import helpers from "../helpers";

class SessionExpired extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showNotificationModal: true
        }
        this.toggleNotificationModal = this.toggleNotificationModal.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    componentDidMount() {
        if (helpers.getSessionStorage("theme") === "dark") {
            this.props.darkTheme();
        } else {
            this.props.lightTheme();
        }
    }

    toggleNotificationModal() {
        this.setState({ showNotificationModal: !this.state.showNotificationModal });
    }

    redirect() {
        this.setState({ showNotificationModal: false });
        setTimeout(() => {
            this.props.history.push("/logout");
        }, 100);
    }

    render() {

        let modalStyle = { color: "" };
        if (typeof this.props.theme !== 'undefined') {
            modalStyle = this.props.theme.modal;
        }

        const fontColor = this.props.theme ? this.props.theme.color : "#2A3139";

        return (
            <div>
                <Theme showThemeButton="hide" />
                <Modal fade={false} isOpen={this.state.showNotificationModal} toggle={this.toggleNotificationModal} backdrop="static">
                    <ModalHeader toggle={this.redirect} style={modalStyle}><span style={{ color: fontColor, fontSize: "20px" }}>Message</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <h6 style={{ color: fontColor }}>Your session has expired, please login back.</h6>
                        <div className="pull-right" style={{ marginTop: "20px" }}>
                            <Button size="sm" color="secondary" onClick={this.redirect}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getDefaultTheme: getDefaultTheme, lightTheme: lightTheme, darkTheme: darkTheme }, dispatch)
}

const exportSessionExpired = withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionExpired));
export default exportSessionExpired;

