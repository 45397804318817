export default function providersReducer(state = {
    type: "",
    provider: [],
    syncid:""
}, action) {
    switch (action.type) {
        case "providers":
            return action.payload
    }
    return state
}
