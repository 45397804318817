import React, { Component, PureComponent } from 'react';
import Theme from "./theme";
import { NavLink as NavigationLink, Link, withRouter } from "react-router-dom";
import { reloadComponentsForTenant } from "../actions/tenantAction";
import { loadTISearchData } from "../actions/tiSearchAction";
import logo from "../images/securaa_fav2.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import helpers from "../helpers";
import axios from "axios";
import LoadingSpinner from './LoadingSpinner';
import ReactTooltip from 'react-tooltip';
import {
    Collapse, Navbar, NavbarBrand, Nav, NavItem, NavLink,
    UncontrolledDropdown, DropdownToggle, DropdownMenu,
    DropdownItem, NavbarToggler, Button
} from "reactstrap";
import QuickActionCase from "./QuickActionCase";
import NotificationComponent from "./NotificationComponent";
import { GiChewedSkull } from "react-icons/gi";
import { BsBarChart, BsServer } from "react-icons/bs";
import { GiAutoRepair, GiShoppingCart } from "react-icons/gi";
import { AiOutlineSetting } from "react-icons/ai";
import indicatorByTagsAction from "../actions/indicatorByTagsAction";
import cogoToast from "cogo-toast";
import Loader from "./Loader";


let url = window.location.origin;
//let url = "http://localhost/downloads/logo/logo.png";
url = url === "http://localhost:3000" ? "http://localhost" : url;
url = url + "/downloads/logo/logo.png";
url = url.toString();
let dateFormat = require('dateformat');
const restURL = helpers.restURL;

class Menu extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            selectedMenu: "",
            userRoles: [],
            userData: {},
            tenantsData: [],
            selectedTenant: "",
            showNotificationModal: false,
            showIncidentModal: false,
            notificationMessage: "",
            loading: "hide",
            tiSearchString: "",
            selectedTenantFontColor: "white",
            selectedTenantHoverMessage: "",
            tipObjectMenuOpen: false,
            customDashboards: [],
            timestamp:Math.floor(100000000 + Math.random() * 900000000),
            loader:false,
            menuSearchString:""
        };

        this.redirect = this.redirect.bind(this);
        this.updateTenantData = this.updateTenantData.bind(this);
        this.checkTenantExipred = this.checkTenantExipred.bind(this);
        this.getTenatDBStatus = this.getTenatDBStatus.bind(this);
        this.redirectpage = this.redirectpage.bind(this);
    }

    componentDidUpdate(prevProps) {
        
        let Casetenantcode = helpers.getSessionStorage("caseTenantCode");
        let currentTenant = this.props.currentTenant;
        if (Casetenantcode != null && Casetenantcode != undefined) {
            if (Casetenantcode != currentTenant) {
                let tenantsData = this.state.tenantsData;
                let tenantName = "";
                let tenantid = 0;

                for (let i = 0; i < tenantsData.length; i++) {
                    if (tenantsData[i].tenantcode === Casetenantcode) {
                        tenantName = tenantsData[i].tenantname;
                        tenantid = tenantsData[i].id;
                    }
                }
                this.setState({ selectedTenant: tenantName });
                helpers.setCookie('tenantcode', Casetenantcode, 10);
                helpers.setCookie('previoustenant', "master", 10)
                helpers.setCookie('tenantid', tenantid, 10);
                helpers.setCookie('selectedTenant', tenantName, 10);
                //helpers.setLocalStorage('selectedTenant', tenantName);
                //this.props.reloadComponentsForTenant(Casetenantcode);
            } else {
                this.resetTenant();
            }
        } else {
            this.resetTenant();
        }
    }
    resetTenant = () => {
        if (window.location.href.includes("/home")) {
            if (helpers.getCookie('previoustenant') === "master") {
                this.setState({ selectedTenant: "MASTER" });
                helpers.setCookie('tenantcode', "master", 10);
                helpers.setCookie('previoustenant', "", 10)
                helpers.setCookie('tenantid', 1, 10);
                helpers.setCookie('selectedTenant', "MASTER", 10);
                helpers.setCookie("PageName", "");

            } else {
                helpers.setCookie('previoustenant', "", 10);
                helpers.setCookie("PageName", "");
                // helpers.setLocalStorage("searchedquery","",30);
            }
        } else {
            helpers.setCookie('previoustenant', "", 10);
            helpers.setCookie("PageName", "");
            // helpers.setLocalStorage("searchedquery","",30);
        }

    }
    componentDidMount() {
        //Kavya Changes : condition handled to logout if another user logged in with same credentials - 05/07/2023
        let isDuplicatelogin = helpers.getLocalStorage("isDuplicatelogin");
        if(isDuplicatelogin !== null && isDuplicatelogin !== undefined && isDuplicatelogin === "true"){
            this.props.history.push("/logout");
        }
        
        //this.setState({timestamp:new Date().getTime()});
        let tenantcode = helpers.getCookie("tenantcode");
        if (tenantcode === "master") {
            helpers.setCookie('tenantcode', 'master', 10);
            this.setState({ selectedTenant: "MASTER" });
        }
        let userData = JSON.parse(helpers.getSessionStorage("userData"));
        if (userData !== null && userData !== "" && typeof userData !== 'undefined') {
            let userRoles = [];
            for (let i = 0; i < userData.roles.length; i++) {
                userRoles.push(userData.roles[i].role);
            }

            if (userData.tenantsinfo.length > 0) {
                let currentTenantCode = helpers.getCookie("tenantcode");
                if (currentTenantCode !== "" && currentTenantCode !== null) {
                    for (let j = 0; j < userData.tenantsinfo.length; j++) {
                        if (userData.tenantsinfo[j].tenantcode === currentTenantCode) {
                            this.updateTenantData(userData.tenantsinfo[j]);
                            break
                        }
                    }
                } else {
                    for (let j = 0; j < userData.tenantsinfo.length; j++) {
                        if (userData.tenantsinfo[j].connection_status === "connected") {
                            this.updateTenantData(userData.tenantsinfo[j]);
                            break;
                        }
                    }
                }
            }

            
            let tenantlist=[];
             if(userData.tenantsinfo.length>1){
                tenantlist.push({ "id": 2, "tenantcode": "master", "tenantname": "MASTER", "expirytime": "2550987603" })
             }
             
             if(userData.tenantsinfo.length>0){
                for(let j=0;j<userData.tenantsinfo.length;j++){
                    tenantlist.push(userData.tenantsinfo[j])
                }
             }
             
            this.setState({ userData, userRoles, tenantsData:tenantlist,allTenantData: tenantlist });
        }

        let selectedMenu = "home";

        if (helpers.getCookie("tenantcode") != "master") {
            //Kavya Changes :  mycases path added for re-use of serverdatatablecomponent - Start
            if (window.location.href.includes("/home") ||
                window.location.href.includes("/tisearch") || window.location.href.includes("/cases") ||
                window.location.href.includes("/CasePanel") || window.location.href.includes("/myCases")) {
                selectedMenu = "home";
                this.getDashboardList();
            //Kavya Changes :  mycases path added for re-use of serverdatatablecomponent - End
            } else if (window.location.href.includes("/tasks") || window.location.href.includes("/playbook") || window.location.href.includes("/addplaybook") || window.location.href.includes("/utils")) {
                selectedMenu = "automation"
            } else if (window.location.href.includes("/CSAMExplorer") || window.location.href.includes("/UserDetail") || window.location.href.includes("/CSAMOverviewHome") || window.location.href.includes("/List/PotentialExploitAvailability") ||
              window.location.href.includes("/List/MostVulnerableAssets") || window.location.href.includes("/List/AssetUpdateInfo")) {
                selectedMenu = "explorer"
            } else if (window.location.href.includes("/tip") || window.location.href.includes("/IndicatorOverview") ||
                window.location.href.includes("/import-indicator") || window.location.href.includes("/IndicatorsHome")  || window.location.href.includes("/TIPDashboard") ||
                window.location.href.includes("/List/IndicatorSourcesList") || window.location.href.includes("/List/MyHistoryList") || window.location.href.includes("/List/LastNoteList")||
                window.location.href.includes("/List/TopProvidersList") || window.location.href.includes("/feeds") || window.location.href.includes("/List/ThreatActor")) {
                selectedMenu = "ThreatIntelligence"
            } else if (window.location.href.includes("/reports") || window.location.href.includes("/dashboard") || window.location.href.includes("/RSSFeedsList")) {
                selectedMenu = "reports"
            } else if (window.location.href.includes("/marketplace")) {
                selectedMenu = "marketplace"
            }else if(url === "tiparticle"){
                this.setState({ selectedMenu: "ThreatIntelligence" });
                helpers.setCookie('selectedMenu', "ThreatIntelligence", 1);
            } else {
                selectedMenu = "configuration"
            }

        } else {
            selectedMenu = "home";
            this.getDashboardList();
        }


        this.setState({ selectedMenu },function(){
            let userData = JSON.parse(helpers.getSessionStorage("userData"));
            let tenantsinfo = userData.tenantsinfo;
            let checktenantinfo = tenantsinfo.filter(data => data.connection_status === "creating")
            if(checktenantinfo.length > 0){
                this.getTenantsData()
            }
        
        });
       
    }

    componentWillReceiveProps(newProps) {
        //if (newProps.currentTenant !== this.props.currentTenant) {
            if (helpers.getLocalStorage("customupdate") === "true") {
                helpers.setLocalStorage("customupdate", "false");
                this.getDashboardList();
            }

            if (newProps.currentTenant !== this.props.currentTenant && this.props.currentTenant != false) {
                this.setState({customDashboards:[]},function(){
                    this.getDashboardList();
                })
                
            }
            if(newProps.location.pathname !== this.props.location.pathname){
                if(!newProps.location.pathname.toLowerCase().includes("platform")){
                    this.getUsergroupData();
                }else if(newProps.location.pathname.toLowerCase().includes("platform") !== this.props.location.pathname.toLowerCase().includes("platform")){
                    this.getUsergroupData();
                }
            }
            
            // if (typeof newProps.updatedashboardlist !== 'undefined' && newProps.updatedashboardlist !== "" && newProps.updatedashboardlist !== null) {
            //     if(this.props.updatedashboardlist.deleted !==newProps.updatedashboardlist.deleted ){
            //         if (newProps.updatedashboardlist.deleted == "true") {
            //             this.getDashboardList();
            //         }
            //     }
                
            // }
        // }
        
        /*chandru changes -logo update reducer */
        if(typeof newProps.logoupdatedata !== 'undefined' && newProps.logoupdatedata !="" && newProps.logoupdatedata !== null){
            if(newProps.logoupdatedata !== this.props.logoupdatedata){
                this.setState({timestamp:new Date().getTime()})
            }
        }

        if (typeof newProps.tisearchdata !== 'undefined' && newProps.tisearchdata.length > 0 && this.state.tiSearchString === "") {
            this.setState({ tiSearchString: newProps.tisearchdata });
        }
        let selectedMenu = "home";
            //Kavya Changes :  mycases path added for re-use of serverdatatablecomponent - Start
        if (window.location.href.includes("/home") ||
            window.location.href.includes("/tisearch") || window.location.href.includes("/cases") ||
            window.location.href.includes("/CasePanel") || window.location.href.includes("/myCases")) {
            selectedMenu = "home";
            //Kavya Changes :  mycases path added for re-use of serverdatatablecomponent - End
        } else if (window.location.href.includes("/tasks") || window.location.href.includes("/playbook") || window.location.href.includes("/addplaybook") || window.location.href.includes("/utils")) {
            selectedMenu = "automation"
        } else if (window.location.href.includes("/CSAMExplorer") || window.location.href.includes("/UserDetail") || window.location.href.includes("/CSAMdashboard") || window.location.href.includes("/Cloudmonitoring") || window.location.href.includes("/CSAMOverviewHome") ||
            window.location.href.includes("/List/PotentialExploitAvailability") || window.location.href.includes("/List/MostVulnerableAssets") || window.location.href.includes("/List/AssetUpdateInfo")) {
            selectedMenu = "explorer"
        } else if (window.location.href.includes("/tip") || window.location.href.includes("/IndicatorOverview") ||
            window.location.href.includes("/import-indicator") || window.location.href.includes("/IndicatorsHome") || window.location.href.includes("/TIPDashboard")||
            window.location.href.includes("/List/IndicatorSourcesList") || window.location.href.includes("/List/MyHistoryList") || window.location.href.includes("/List/LastNoteList")||
            window.location.href.includes("/List/TopProvidersList") || window.location.href.includes("/feeds") || window.location.href.includes("/List/ThreatActor")) {
            selectedMenu = "ThreatIntelligence"
        } else if (window.location.href.includes("/reports") || window.location.href.includes("AddReports") || window.location.href.includes("/RSSFeedsList") || window.location.href.includes("/dashboard") ) {/*Tojo : custom dashboard navigator is not showing under the report and dashboard tab 22/08/2023*/
            selectedMenu = "reports"
        } else if (window.location.href.includes("/marketplace")) {
            selectedMenu = "marketplace"
        }else if(url === "tiparticle"){
            this.setState({ selectedMenu: "ThreatIntelligence" });
            helpers.setCookie('selectedMenu', "ThreatIntelligence", 1);
        } else {
            selectedMenu = "configuration"
        }
        this.setState({ selectedMenu },function(){ 
            let userData = JSON.parse(helpers.getSessionStorage("userData"));
            let tenantsinfo = userData.tenantsinfo;
            let checktenantinfo = tenantsinfo.filter(data => data.connection_status === "creating")
            if(checktenantinfo.length > 0){
                this.getTenantsData()
            }});
    }

    getUsergroupData=async()=>{
        this.setState({loader:true})
        try{
            let url = helpers.restURL + "?module=user&rest=getsingleusersgroupids&q=" + parseInt(helpers.getCookie('userid'))+"/" +helpers.getCookie('tenantcode')+"/" ;
            let responseData = await helpers.buildAxiosObject("GET",url,"");
            if (responseData.data != null) {
                if (responseData.data.success) {
                    if (responseData.data.data != null && responseData.data.data !== undefined) {
                        let dataresponse = responseData.data.data;
                        let userdata = dataresponse;
                        let userGroupData = []
                        for (let u = 0; u < userdata.length; u++) { 
                            userGroupData.push(userdata[u]["id"].toString());
                        }
                        helpers.setCookie('usergrouptotaldata',JSON.stringify(userdata),10);
                        helpers.setCookie('userGroups', JSON.stringify(userGroupData), 10);
                    }else{
                        helpers.setCookie('usergrouptotaldata',"[]",10);
                        helpers.setCookie('userGroups', "[]", 10);
                    }
                }else{
                    if (responseData.data.displaymessage !== undefined && responseData.data.displaymessage !== null && responseData.data.displaymessage !== "") {
                        helpers.securaaNotification(<div>{responseData.data.displaymessage}</div>,"error",10)
                    }else{
                        helpers.securaaNotification("Error in response.","error",10)
                    }
                }
            }
            
        }catch(e){

        }
    }
    showLoading = (state) => {
        this.setState({ loading: state });
    }

    getDashboardList = () => {
        let defaultDashboards = [];
         let showDefaultdashboard = false;
         let userData = helpers.getSessionStorage("userData");
         if (typeof userData !== 'undefined' && userData !== null && userData !== "") {
             let data = JSON.parse(userData);
             if (data.showdefaultdashboard) {
                 showDefaultdashboard = true;
             } else {
                 showDefaultdashboard = false;
             }
         }
 
         let modules = helpers.getCookie('modulesArray');
         if (modules !== "" && modules !== null && typeof modules !== 'undefined') {
             modules = JSON.parse(modules);
             if (modules.indexOf('metrics (predefined dashboard)') > -1) {
                 defaultDashboards.push("Metrics");
             }
 
             if (modules.indexOf('case panel') > -1) {
                 defaultDashboards.push("Cases");
             }
 
             if (modules.indexOf('threat intelligence (predefined dashboard)') > -1) {
                 defaultDashboards.push("Threat Intelligence");
             }
  
             if (modules.indexOf('soc performance (predefined dashboard)') > -1) {
                 defaultDashboards.push("SOC Performance");
             }
 
             if (modules.indexOf('configuration (predefined dashboard)') > -1) {
                 defaultDashboards.push("Configuration");
             }
 
             if (modules.indexOf('assets (predefined dashboard)') > -1) {
                 defaultDashboards.push("Assets");
             }
         }
 
         if (!showDefaultdashboard) {
             defaultDashboards = [];
         }

        // this.setState({ loading: "show" });
        let data = {
            userid: parseInt(helpers.getCookie('userid')),
            module: "users",
            tenantcode: helpers.getCookie('tenantcode'),
            rest: "getcustomdashboard",
            defaultdashboards: []
        };

        axios({
            method: "POST",
            url: restURL,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            data: data,
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                const customDefaultDashboards = res.data.data.filter(dash => dash.customdefaultdashboard);
                if (customDefaultDashboards.length > 0 && window.location.href.includes("/home")) {
                this.props.history.push("/reports");
                } 
                this.setState({
                    customDashboards: res.data.data
                })
                helpers.setLocalStorage("customupdate", "false");
            } else {
                this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6> });
            }
            this.setState({ loading: "hide" });
        }).catch(function (error) {
            console.log(error);
        });
    }


    checkTenantExipred(tenantData) {
        if (tenantData.expirytime !== "undefined" && tenantData.expirytime !== undefined) {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0');
            let yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd;
            let tenantExpireDate = new Date((parseInt(tenantData.expirytime)) * 1000);
            tenantExpireDate = dateFormat(tenantExpireDate, "yyyy-mm-dd");
            let todayData = Date.parse(today);
            let tenantExpiryDate = Date.parse(tenantExpireDate);
            if (todayData > tenantExpiryDate) {
                return true
            } else {
                return false
            }
        }
    }

    toggleNotificationModal = (state) => {
        this.setState({ showNotificationModal: state });
    }

    toggleIncidentModal = (state) => {
        this.setState({ showIncidentModal: state });
    }


    getTenantsData=()=>{
       try{
        var url = restURL;
        var data = {
            companycode: helpers.getCookie('companycode'),
            tenantids: JSON.parse(helpers.getCookie('tenantIDS')),
            userid: parseInt(helpers.getCookie('userid')),
            rest: 'gettenants',
            module: 'users'
        };
        axios({
            method: "POST",
            url: url,
            data: data,
            headers: {
                access_token: helpers.getCookie("accesstoken"),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                this.updateTenantsInfo(res.data.data)
            }

           
        })
       }catch(e){
        console.log("Filename:Menu.js Funcrion:getTenantsData Error:",e)
       } 
        
    }

    updateTenantsInfo = (tenantsData)=>{
        let userData = helpers.getSessionStorage("userData");
        let tenantsInfo = tenantsData;
        if (typeof userData !== 'undefined' && userData !== null && userData !== "") {
            userData = JSON.parse(userData);
            

            for (let i = 0; i < tenantsInfo.length; i++) {
                tenantsInfo[i].id = parseInt(tenantsInfo[i].id);
                tenantsInfo[i]["tenantname"] = tenantsInfo[i].name;
                tenantsInfo[i]["expirytime"] = tenantsInfo[i].expirydate;
            }
         
            let tenantlist=[];
             if(tenantsInfo.length>1){
                tenantlist.push({ "id": 2, "tenantcode": "master", "tenantname": "MASTER", "expirytime": "2550987603" })
             }
             
             if(tenantsInfo.length>0){
                for(let j=0;j<tenantsInfo.length;j++){
                    tenantlist.push(tenantsInfo[j])
                }
             }
            this.setState({tenantsData:tenantlist,allTenantData: tenantlist });
            userData.tenantsinfo = tenantsInfo;
            helpers.setSessionStorage("userData", JSON.stringify(userData));
            helpers.setLocalStorage("tenantsData",JSON.stringify(userData.tenantsinfo))
           // helpers.setCookie('tenantIDS', JSON.stringify(_tenantIDS), 10);
        }
    }
    updateTenantData(tenantInfo) {

        let userData = JSON.parse(helpers.getSessionStorage("userData"));
        let isSuperAdmin = false;
        if (userData !== null && userData !== "" && typeof userData !== 'undefined') {
            if (userData.issuperadmin === "y") {
                isSuperAdmin = true;
            }
        }

        let selectedTenantFontColor = "white";
        let selectedTenantHoverMessage = "";
        let isExpired = this.checkTenantExipred(tenantInfo);
        if (isExpired) {
            selectedTenantFontColor = "red";
            if (isSuperAdmin) {
                selectedTenantHoverMessage = "Tenant Expired ! Please extend the expiry date to continue.";
                this.setState({ notificationMessage: "Tenant Expired ! Please extend the expiry date to continue." });
            } else {
                selectedTenantHoverMessage = "Tenant Expired ! Please contact your Admin.";
                this.setState({ notificationMessage: "Tenant Expired ! Please contact your Admin." });
            }
            this.setState({ showNotificationModal: true });
            // if (document.getElementsByClassName("homeContainer").length > 0 && !isSuperAdmin) {
            //     document.getElementsByClassName("homeContainer")[0].style.display = "none";
            // }
        } else {
            selectedTenantFontColor = "";
            selectedTenantHoverMessage = "";
            // if (document.getElementsByClassName("homeContainer").length > 0) {
            //     document.getElementsByClassName("homeContainer")[0].style.display = "block";
            //     this.setState({ showNotificationModal: false, notificationMessage: "" });
            // }
        }

        this.setState({ selectedTenant: tenantInfo.tenantname, selectedTenantFontColor, selectedTenantHoverMessage });
        helpers.setCookie('tenantcode', tenantInfo.tenantcode, 10);
        helpers.setCookie('tenantid', tenantInfo.id, 10);
        helpers.setCookie('selectedTenant', tenantInfo.tenantname, 10);
        helpers.setLocalStorage('selectedTenant', tenantInfo.tenantname);
        this.props.reloadComponentsForTenant(tenantInfo.tenantcode);
        this.getUsergroupData();
    }

     getTenatDBStatus(tenantInfo) {
        //  helpers.setLocalStorage("searchedquery","");
        let execAjax = true;
        if(this.props.location.pathname.includes("/addplaybook")){
            if(this.props.currentTenant !== tenantInfo.tenantcode){
                setTimeout(() => { this.props.history.push("/playbook"); }, 300);
            }
            
            execAjax =false
        }

        if(tenantInfo.connection_status === "creating"){
            execAjax =false 
            this.setState({ notificationMessage: "Tenant is still creating, Please try after sometime.", showNotificationModal: true },function(){
                this.getTenantsData()
            });
        }

        if(execAjax){
            //Kavya - commenting this to avoid clearing query onchange of tenant - 1Mar2024
        // window.localStorage.removeItem("searchedquery"+tenantInfo.tenantcode);
        if (tenantInfo.tenantcode != "master") {
            helpers.setCookie('hometenant', "", 1)
            this.showLoading("show");
            let url = restURL + "?module=user&rest=checktenantconnectivity&q=" + tenantInfo.tenantcode + "/";
             axios({
                method: "GET",
                url: url,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                responseType: "json"
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (res.data.success) {
                    if (res.data.data === "connected") {
                        this.updateTenantData(tenantInfo)
                        if (window.location.href.includes("/CasePanel") || window.location.href.includes("/cases") || window.location.href.includes("/dashboard") || window.location.href.includes("/CrossTenantCasesList") || window.location.href.includes("/CrossTenantAssignedCasesList") || window.location.href.includes("/AddReports") ||  window.location.href.includes("/CrossTenantCases") || window.location.href.includes("/myCases")) {
                            setTimeout(() => {
                                // helpers.setLocalStorage("searchedquery","",30);
                            this.props.history.push("/home"); }, 300);
                        } else {
                            //console.log("inside tenant change")
                            // window.location.reload();
                        }
                    } else {
                        this.setState({ notificationMessage: "Tenant DB is Disconnected, Please Connect to Continue.", showNotificationModal: true });
                    }
                } else {
                    this.setState({ notificationMessage: "Failed To Connect Tenant DB. ", showNotificationModal: true });
                }

                this.showLoading("hide");
                this.setState({menuSearchString:""})
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            this.setState({menuSearchString:""})
            this.setState({ selectedMenu: "home" });
            // setTimeout(() => { 
                // helpers.setLocalStorage("searchedquery","",30);
                this.props.history.push("/home");
            //  }, 300);
            // if (window.location.href.includes("/CasePanel") || window.location.href.includes("/cases") || window.location.href.includes("/dashboard")) {
            //     setTimeout(() => { this.props.history.push("/home"); }, 300);
            // } else {
            //     //console.log("inside tenant change")
            //     // window.location.reload();
            // }
             this.updateTenantData(tenantInfo)/*tojo changes:  Task screen is showing in master tenant when we switch the tenant to master from task screen  15/06/2023*/
            }
        }
     }

    redirectpage(url) {
        console.log("url", url)
    }
    redirectHome = (url, data) => {
        if (data == "default") {
            helpers.setLocalStorage("selecteddashboard", "Cases");
            helpers.setCookie('currentDashboardSelected', "default", 1);
        } else {
            helpers.setLocalStorage("selecteddashboard", data.widgetname);
            data = JSON.stringify(data);
            helpers.setCookie('currentDashboardSelected', "custom", 1);
            helpers.setLocalStorage('customDashboardData', data);
        }
        this.setState({ selectedMenu: "home" });
        helpers.setCookie('selectedMenu', "home", 1);
        if (window.location.href.includes("/home")) {
            window.location.reload();
        } else {
            this.props.history.push("/" + url);
        }

    }

    redirect(url) {
        if (url === "home") {
            this.setState({ selectedMenu: "home" });
            helpers.setCookie('selectedMenu', "home", 1);
        } else if (url === "tasks" || url === "playbook") {
            this.setState({ selectedMenu: "automation" });
            helpers.setCookie('selectedMenu', "automation", 1);
        } else if (url === "Apps" || url === "Platform") {
            this.setState({ selectedMenu: "configuration" });
            helpers.setCookie('selectedMenu', "configuration", 1);
        } else if (url === "tisearch") {
            if (this.state.tiSearchString === "") {
                return
            } else {
                this.props.loadTISearchData(this.state.tiSearchString);
            }
        } else if (url === "tip" || url === "import-indicator" || url === "IndicatorsHome" || url === "feeds" || url === "ThreatActor") {
            //this.props.indicatorByTagsAction(null);
            this.setState({ selectedMenu: "ThreatIntelligence" });
            helpers.setCookie('selectedMenu', "ThreatIntelligence", 1);
        } else if (url === "CSAMExplorer" || url === "UserDetail" || url === "IndicatorOverview" || url === "Cloudmonitoring" || url === "CSAMdashboard" || url === "CSAMOverviewHome" || url === "MostVulnerableAssets" || url === "PotentialExploitAvailability" || url === "AssetUpdateInfo") {
            this.setState({ selectedMenu: "explorer" });
            helpers.setCookie('selectedMenu', "explorer", 1);
        } else if (url === "reports") {
            helpers.setCookie('currentReportTab', "regular", 1);
            this.setState({ selectedMenu: "reports" });
            helpers.setCookie('selectedMenu', "reports", 1);
        } else if (url === "marketplace") {
            this.setState({ selectedMenu: "marketplace" });
            helpers.setCookie('selectedMenu', "marketplace", 1);
        }else if(url === "tiparticle"){
            this.setState({ selectedMenu: "ThreatIntelligence" });
            helpers.setCookie('selectedMenu', "ThreatIntelligence", 1);
        }

        if (window.location.href.includes("/tisearch") && url === "tisearch") {
        } else {
            if(url === "logout"){
                // helpers.setLocalStorage("searchedquery","");
                if(this.state.tenantsData !== null && this.state.tenantsData !== undefined && this.state.tenantsData.length > 0){
                    for(let i of this.state.tenantsData){
                        window.localStorage.removeItem("searchedquery"+i.tenantcode);
                    }
                }
                
            }
            this.props.history.push("/" + url);
        }
    }
    // useEffect = (() => {
    //     window.addEventListener('storage', () => {
    //         console.log("inside Useeffect");
    //         console.log("tenantcode ", helpers.getLocalStorage("tenantcode"));

    //     })
    // }, [])

    tenantSearchChange = (valueTenant) => {
        //console.log("tenantlistArr:",this.state.tenantlistArr)
        var tenantlist = this.state.allTenantData
        let tenantArray=[]
        Object.entries(tenantlist).map(([key, value]) => (
           value.tenantname.toLowerCase().includes(valueTenant.toLowerCase())?tenantArray.push(value):[]
                      
        ))   
        if(tenantArray.length === 0){
            this.setState({menuSearchString:valueTenant,tenantsData:[]})
        }else{
            this.setState({menuSearchString:valueTenant,tenantsData:tenantArray})
        }
      
    }

    handleKeyPress=(target)=> {
        if(target.charCode === 32 && target.target.selectionStart === 0){
          target.preventDefault();
        }
      }

    render() {
        let popOverCSS = { color: '' };
        var timestamp = new Date().getTime();
        if (typeof this.props.theme !== 'undefined') {
            popOverCSS = this.props.theme.popOver;
        }
        const fontColor = this.props.theme ? this.props.theme.color : "#2A3139";

        let tenantDropDown = "";
         if (typeof this.state.tenantsData != "undefined" && this.state.tenantsData != null && this.state.tenantsData.length > 0) {
            tenantDropDown = this.state.tenantsData.map(tenant => (
                <DropdownItem toggle={true} key={tenant.tenantcode} id="TenantDropdownList" onClick={() => { this.getTenatDBStatus(tenant) }} className="dropdown-list-menu tenantnameoverflow" style={{ color: fontColor }} title={tenant.tenantname}>{tenant.tenantname}</DropdownItem>/*tojo changes : Dashboard - Dropdown of the tabs is not getting disable after clicking on the option 21/06/2023*/
            ));
        }else{
            tenantDropDown =  <DropdownItem className="" style={{ color: fontColor }}>No Tenant found</DropdownItem>
        }

        let modules = helpers.getCookie('modulesArray');
        if (modules !== "" && modules !== null && typeof modules !== 'undefined') {
            modules = JSON.parse(modules);
        } else {
            modules = [];
        }

        //Kavya Changes : handling license type intead of build_type for enterprise - Start - 09/06/2023
		 let licensedetails = helpers.getSessionStorage("licenseResponse");
		 let licenseType = "";
		 if(licensedetails !== undefined && licensedetails !== null){
			 licensedetails = JSON.parse(licensedetails);
			 licenseType = licensedetails.details.type !== null && licensedetails.details.type !== undefined ? licensedetails.details.type : "";
		 }
		 //Kavya Changes : handling license type intead of build_type for enterprise - End - 09/06/2023
        return (
            // this.state.loader===true?

            <div>
                <ReactTooltip />
                <LoadingSpinner showSpinner={this.state.loading} />
                <Navbar color="dark" expand="md" style={{ fontSize: "14px", backgroundColor: "rgb(24, 24, 24) !important" }} className="cursor-point darkBackground fixed-nav-bar menu-shadow">
                    <NavbarBrand onClick={() => { return false }}>
                    <img src={url+ "?t=" + this.state.timestamp} onClick={() => this.redirectHome("home", "default")} maxheight="45" width="95" style={{ marginTop: "7px", marginLeft: "0px", maxWidth: "100px" }} />
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="pull-left" navbar>

                            {modules.indexOf('dashboard') > -1 || modules.indexOf('case panel') > -1?
                                // <UncontrolledDropdown nav inNavbar className={`${this.state.selectedMenu === "home" ? `nav-active` : ``} securaa-ti-icon dashboard-menu-item`}>

                                //     <DropdownToggle nav caret className="nav-menu-link" onClick={() => this.redirectHome("home", "default")}>
                                //         Cases
                                //     </DropdownToggle>
                                //     {this.state.customDashboards.length > 0 ?
                                //     <DropdownMenu left style={popOverCSS}>
                                //         {this.state.customDashboards.map((item) =>
                                //             <DropdownItem   toggle={false} className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirectHome("home", item)}> {item.widgetname} </DropdownItem>
                                //         )
                                //         }

                                //     </DropdownMenu>
                                //     :
                                //    <React.Fragment/>}
                                // </UncontrolledDropdown>
                                    <NavItem className={`${this.state.selectedMenu === `home` ? `nav-active` : ``}  securaa-ti-icon dashboard-menu-item`}>
                                        <NavLink onClick={() => this.redirectHome("home", "default")} className="nav-menu-link cursor-point">
                                            Cases
                                        </NavLink>
                                    </NavItem>
                                :
                                <React.Fragment />}

                            {/* <NavItem className={`${this.state.selectedMenu === `ThreatIntelligence` ? `nav-active` : ``} securaa-ti-icon dashboard-menu-item`}>
                                <NavLink onClick={() => this.redirect("tip")} className="nav-menu-link cursor-point">
                                    <span><GiChewedSkull /></span> TIP
                                    </NavLink>
                            </NavItem> */}
                             {helpers.getCookie("soar_support") === "yes" && helpers.getCookie("tenantcode") !== "master" ?
                                    <NavItem className={`${this.state.selectedMenu === `reports` ? `nav-active` : ``}  securaa-ti-icon dashboard-menu-item`}>
                                        <NavLink onClick={() => this.redirect("reports")} className="nav-menu-link cursor-point">
                                            <span><BsBarChart /></span> Dashboard and Reports
                                    </NavLink>
                                    </NavItem>
                                :
                                modules.indexOf('reports') > -1 || modules.indexOf('dashboard') > -1 ?
                                <NavItem className={`${this.state.selectedMenu === `reports` ? `nav-active` : ``}  securaa-ti-icon dashboard-menu-item`}>
                                    <NavLink onClick={() => this.redirect("reports")} className="nav-menu-link cursor-point">
                                        <span><BsBarChart /></span> Dashboard and Reports
                                </NavLink>
                                </NavItem>
                                : <React.Fragment />
                                }
                            
                            {/* {(helpers.getCookie("build_type") === "enterprise" || helpers.getCookie("build_type") === "mssp") && helpers.getCookie("tenantcode") != "master" ? */}
                            {(licenseType.toLowerCase().includes("enterprise") || helpers.getCookie("build_type") === "mssp") && helpers.getCookie("tenantcode") != "master" ?
                                 helpers.getCookie("tip_support") === "yes"  ?
                                <UncontrolledDropdown nav inNavbar className={`${this.state.selectedMenu === "ThreatIntelligence" ? `nav-active` : ``} securaa-ti-icon dashboard-menu-item`}>

                                    <DropdownToggle nav caret className="nav-menu-link" onClick={() => this.redirect("TIPDashboard")}>
                                       TIP
                                    </DropdownToggle>
                                    <DropdownMenu style={popOverCSS}>
                                        <DropdownItem className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirect("tip")}> Indicator </DropdownItem>
                                        {/* <DropdownItem className="dropdown-list-menu" toggle={false} style={{ color: fontColor }}
                                            onClick={() => this.setState({ tipObjectMenuOpen: !this.state.tipObjectMenuOpen })}>
                                            Object <i className={`fa fa-caret-${this.state.tipObjectMenuOpen === true ? `down` : `right`}`} />
                                        </DropdownItem>

                                        <Collapse isOpen={this.state.tipObjectMenuOpen}> */}
                                            <DropdownItem className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirect("tip?type=malware")}> Malware </DropdownItem>
                                            <DropdownItem className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirect("tip?type=campaign")}> Campaign </DropdownItem>
                                            <DropdownItem className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirect("ThreatActor")}> Threat Actors </DropdownItem>
                                            <DropdownItem className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirect("feeds")}><i style={{ color: fontColor }} className="fa fa-rss"></i> Feeds  </DropdownItem>

                                        {/* </Collapse> */}

                                        {/* <DropdownItem className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirect("import-indicator")}> Import </DropdownItem> */}

                                        {/* {modules.indexOf('indicators') > -1 ? */}
                                        {/* < DropdownItem className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirect("IndicatorsHome")}>SOAR Indicators </DropdownItem> */}
                                        {/* : ""} */}
                                        <DropdownItem className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirect("tiparticle")}><i style={{ color: fontColor }} className="fa fa-newspaper-o"></i> Articles </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                : <React.Fragment />
                                : <React.Fragment />}
                            {/*Kavya Changes : Hidden Assets in both MSSP and Enterprise - 09/06/2023*/}
                            {/* currently this tab is disabled for mssp version */}
                            {helpers.getCookie("tenantcode") != "master" ?
                                    helpers.getCookie("avm_support") === "yes" ?
                                        // modules.indexOf('asset explorer') > -1 ?
                                            <UncontrolledDropdown nav inNavbar className={`${this.state.selectedMenu === `explorer` ? `nav-active` : ``} securaa-ti-icon dashboard-menu-item`}>
                                                <DropdownToggle nav caret className="nav-menu-link">
                                                    <span><BsServer /></span> CSAM
                                            </DropdownToggle>
                                                <DropdownMenu style={popOverCSS}>
                                                    <DropdownItem  toggle={true} className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirect("CSAMdashboard")}> Dashboard </DropdownItem>
                                                    <DropdownItem  toggle={true} className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirect("CSAMExplorer")}> Devices </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        : <React.Fragment />
                                    : <React.Fragment />}
                                
                            {helpers.getCookie("tenantcode") != "master" ?
                                modules.indexOf('tasks') > -1 || modules.indexOf('playbooks') > -1 || modules.indexOf('utils') > -1  ?
                                    <UncontrolledDropdown nav inNavbar className={`${this.state.selectedMenu === `automation` ? `nav-active` : ``} securaa-ti-icon dashboard-menu-item`}>
                                        <DropdownToggle id="menuAutomationTab" nav caret className="nav-menu-link">
                                            <span><GiAutoRepair /></span> Automation
                                </DropdownToggle>
                                    <DropdownMenu style={popOverCSS}>
                                        {modules.indexOf('tasks') > -1 ?
                                            <DropdownItem  toggle={true} className="dropdown-list-menu" id="MenuTasks" style={{ color: fontColor }} onClick={() => this.redirect("tasks")}> Tasks </DropdownItem>/*tojo changes : Dashboard - Dropdown of the tabs is not getting disable after clicking on the option 21/06/2023*/
                                            : <React.Fragment />}
                                        {modules.indexOf('playbooks') > -1 ?
                                            <DropdownItem  toggle={true} className="dropdown-list-menu" id="MenuPlaybooks" style={{ color: fontColor }} onClick={() => this.redirect("playbook")}> Playbooks </DropdownItem>/*tojo changes : Dashboard - Dropdown of the tabs is not getting disable after clicking on the option 21/06/2023*/
                                            : <React.Fragment />}
                                            {modules.indexOf('utils') > -1 ?
                                        <DropdownItem  toggle={true} className="dropdown-list-menu" id="MenuUtils" style={{ color: fontColor }} onClick={() => this.redirect("utils")}> Utils </DropdownItem>/*tojo changes : Dashboard - Dropdown of the tabs is not getting disable after clicking on the option 21/06/2023*/
                                        :<React.Fragment />}
                                        </DropdownMenu>
                                </UncontrolledDropdown>
                                    : <React.Fragment />
                                : <React.Fragment />}

                            

                            {helpers.getCookie("tenantcode") != "master" ?
                                modules.indexOf('platform management') > -1 || modules.indexOf('apps') > -1 ?
                                    <UncontrolledDropdown nav inNavbar className={`${this.state.selectedMenu === `configuration` ? `nav-active` : ``} securaa-ti-icon dashboard-menu-item`}>
                                        <DropdownToggle id="menuConfigurationTab" nav caret className="nav-menu-link">
                                            <span><AiOutlineSetting className="fa-spin" /></span> Configuration
                                </DropdownToggle>
                                    <DropdownMenu style={popOverCSS}>
                                        {modules.indexOf('apps') > -1 ?
                                            <DropdownItem  id="menuConfigurationApps" toggle={true} className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirect("Apps")}> Applications </DropdownItem>/*tojo changes : Dashboard - Dropdown of the tabs is not getting disable after clicking on the option 21/06/2023*/
                                            : <React.Fragment />}
                                        {modules.indexOf('platform management') > -1 ?
                                            <DropdownItem  id="menuConfigurationPlatforms" toggle={true} className="dropdown-list-menu" style={{ color: fontColor }} onClick={() => this.redirect("Platform")}> Platform </DropdownItem>/*tojo changes : Dashboard - Dropdown of the tabs is not getting disable after clicking on the option 21/06/2023*/
                                            : <React.Fragment />}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    : <React.Fragment />
                                : <React.Fragment />}
                            {/* <NavItem className={`${this.state.selectedMenu === `marketplace` ? `nav-active` : ``}  securaa-ti-icon dashboard-menu-item`}>
                                <NavLink onClick={() => this.redirect("marketplace")} className="nav-menu-link cursor-point">
                                    <span><GiShoppingCart /></span> MarketPlace
                                    </NavLink>
                            </NavItem> */}
                        </Nav>

                        <Nav className="ml-auto dashboard-menu-item" navbar>
                            {/* {helpers.getCookie("build_type") === "enterprise" && modules.indexOf('threat intelligence') > -1 ? */}
                            {licenseType.toLowerCase().includes("enterprise") && modules.indexOf('threat intelligence') > -1 ?
                                <NavItem style={{ marginRight: "5px" }}>
                                    <form className="SearchBox" onSubmit={(e) => { e.preventDefault(); this.redirect("tisearch") }}>
                                        <input type="text" className="SearchBox-input" placeholder="Enter search input" value={this.state.tiSearchString} onChange={(e) => this.setState({ tiSearchString: e.target.value })} />
                                        <button className="SearchBox-button">
                                            <i data-tip="Search" data-place="bottom" data-effect="solid" style={{ color: "#0fd9f3" }} className="SearchBox-icon  fa fa-search"></i>
                                        </button>
                                    </form>
                                </NavItem>
                                : <React.Fragment />}

                            <NavItem className="nav-menu-link">
                                <Theme />
                            </NavItem>


                            {helpers.getCookie("tenantcode") != "master" ?
                                modules.indexOf('case panel') > -1 ?
                                    <React.Fragment>
                                        {helpers.getCookie("_casepanelPermission") === "true" && (helpers.getCookie("soar_support") === "yes") ?
                                            <UncontrolledDropdown nav inNavbar className="dashboard-menu-item">
                                                <DropdownToggle nav caret className="nav-menu-link">
                                                    <i className="fa fa-flash" />&nbsp;Quick Actions</DropdownToggle>
                                                <DropdownMenu right style={popOverCSS}>
                                                    <DropdownItem toggle={false} onClick={() => this.toggleIncidentModal(true)} className="dropdown-list-menu" id="AddNewCase" style={{ color: fontColor }}>
                                                        Add new Case
                                                </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            : <React.Fragment />}
                                    </React.Fragment>
                                    : <React.Fragment />
                                : <React.Fragment />}
                            {/* <React.Fragment> */}
                            
                            {helpers.getCookie("soar_support") === "yes" ?
                                <UncontrolledDropdown nav inNavbar className="dashboard-menu-item">
                                    {/* Author Gayathri - Added Condition for Disabling Arrow and Dropdown when there is a Single Tenant -Start  july 27 */}
                                    {this.state.allTenantData !== null && this.state.allTenantData !== undefined && this.state.allTenantData.length >1?
                                    <DropdownToggle id="menuTenantTab" nav caret className="nav-menu-link" onClick={() => this.setState({menuSearchString:"",tenantsData:this.state.allTenantData})}>
                                        <i className="fa fa-university" aria-hidden="true"></i>&nbsp;<span data-place="bottom" data-effect="solid" data-tip={this.state.selectedTenantHoverMessage} style={{ color: this.state.selectedTenantFontColor }}>{this.state.selectedTenant}</span>
                                    </DropdownToggle>
                                    :
                                    /* Kavya changes removed nav class to remove hover color - 04/08/2023*/
                                     <DropdownToggle id="menuTenantTab" className="nohoversingleTenant" >
                                     <i className="fa fa-university" aria-hidden="true"></i>&nbsp;<span data-place="bottom" data-effect="solid" data-tip={this.state.selectedTenantHoverMessage} style={{ color: this.state.selectedTenantFontColor }}>{this.state.selectedTenant}</span>
                                    </DropdownToggle>
                                 }
                                  
                                 
                                 {this.state.allTenantData !== null && this.state.allTenantData !== undefined && this.state.allTenantData.length >1?
                                    <DropdownMenu id ="menuTenantTab" right className="tenant-drop-down" style={popOverCSS}>
                                         
                                        {/*Kavya Changes : added condition to show master tenant, if tenant is greater than one - 09/06/2023*/}
                                        {/* <DropdownItem toggle={true} key="master" onClick={() => { this.getTenatDBStatus({ "id": 2, "tenantcode": "master", "tenantname": "MASTER", "expirytime": "2550987603" }) }} className="dropdown-list-menu" style={{ color: fontColor }}>MASTER</DropdownItem> */}
                                        {/* tojo changes : Dashboard - Dropdown of the tabs is not getting disable after clicking on the option 21/06/2023 */}
                                        <input type="text" id="SearchTenant" style={{ color: fontColor }} onKeyPress={this.handleKeyPress} className="MenuSearchBox-input" placeholder="Search Tenant" value={this.state.menuSearchString} onChange={(e) => this.tenantSearchChange(e.target.value)} />
                                        <div style={{"max-height":"200px",overflow:"auto"}} id="TenantDropdown" >{tenantDropDown}</div>
                                    </DropdownMenu>
                                    : <React.Fragment/>}
                                    {/* Author Gayathri - Added Condition for Disabling Arrow and Dropdown when there is a Single Tenant End - july 27 */}
                                </UncontrolledDropdown>
                                : <React.Fragment />}

                            <UncontrolledDropdown nav inNavbar className="dashboard-menu-item">
                                <DropdownToggle nav caret className="nav-menu-link">
                                    <i className="fa fa-user-circle-o" /> Account
                                </DropdownToggle>
                                <DropdownMenu right style={popOverCSS} className="accountDetails">
                                    <ul style={{ listStyleType: "none", padding: "10px", marginTop: "10px" }}>
                                        <li className="user-header">
                                            <h5 style={{ color: fontColor }}><i className="fa fa-user-circle-o" style={{ display: "inline-block", color: fontColor }} />&emsp;{this.state.userData.firstname}&nbsp;{this.state.userData.lastname}</h5>
                                        </li>
                                        <DropdownItem   divider />
                                        <li className="user-body">
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <h6>
                                                        <p style={{ maxWidth: "100%", wordWrap: "break-word", color: fontColor }}>
                                                            Roles: <span style={{ fontSize: "12px", color: fontColor }} >{this.state.userRoles.join(', ')}</span>
                                                        </p>
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <DropdownItem divider />
                                        <li className="user-footer">
                                            <Button size="sm" color="info" id="ChangePassword" style={{ display: "inline-block" }} onClick={() => this.redirect("settings")}><i className="fa fa-key"></i> Change Password</Button>
                                            <Button size="sm" color="info" id="Lougout" style={{ display: "inline-block", float: "right" }} onClick={() => this.redirect("logout")}><i className="fa fa-sign-out"></i> Logout</Button>
                                        </li>
                                    </ul>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>

                <QuickActionCase showIncidentModal={this.state.showIncidentModal}
                    toggleIncidentModal={this.toggleIncidentModal}
                    showLoading={this.showLoading} />

                <NotificationComponent showNotificationModal={this.state.showNotificationModal}
                    notificationMessage={this.state.notificationMessage} toggleNotificationModal={this.toggleNotificationModal} />

            </div >
            // :<Loader.dashboardloader />
            )
    }
}
window.onstorage = () => {
    // When local storage changes, dump the list to
    // the console.
};

function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme,
        tisearchdata: state.reducer.tisearchdata.tisearchdata,
        updatedashboardlist: state.reducer.updatedashboardlist,
        currentTenant: state.reducer.currentTenant,
        logoupdatedata:state.reducer.logoupdatedata
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        reloadComponentsForTenant: reloadComponentsForTenant,
        loadTISearchData: loadTISearchData,
        indicatorByTagsAction: indicatorByTagsAction,
        
    }, dispatch)
}

const exportMenu = withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
export default exportMenu;