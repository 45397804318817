import helpers from "../helpers";
import axios from "axios";

export function getCaseCategories(tenantcode) {
    let url;
    if(helpers.getTenantCookie() === "master"){
        url =helpers.restURL + "?module=siem&rest=categories&q=" + tenantcode + "/" + helpers.getCookie('companycode') + "/";
    }else{
        url =helpers.restURL + "?module=siem&rest=categories&q=" + helpers.getTenantCookie() + "/" + helpers.getCookie('companycode') + "/";
    }
    return function (dispatch) {
        axios({
            method: "GET",
            url: url,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
           
              let  payloadvalue=res.data.data
         
            if (!res.data.sessionexpired) {
                dispatch({ type: "getCaseCategories", payload: payloadvalue });
            }
        }).catch(function (error) {
            dispatch({ type: "getCaseCategories", payload: [] });
        });
    };
}

export function getKillChainStage() {
    return function (dispatch) {
        axios({
            method: "GET",
            url: helpers.restURL + "?module=siem&rest=killchainstages&q=" + helpers.getTenantCookie() + "/" + helpers.getCookie('companycode') + "/",
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (!res.data.sessionexpired) {
                dispatch({ type: "getKillChainStage", payload: res.data.data });
            }
        }).catch(function (error) {
            dispatch({ type: "getKillChainStage", payload: [] });
        });
    };
}

export function getUsersList() {
    return function (dispatch) {
        let tenantIDs = JSON.parse(helpers.getCookie('tenantIDS'));
        let tenantcode =  helpers.getCookie("tenantcode");

        var data = { companycode: helpers.getCookie('companycode'), tenantcode: tenantcode, tenantids: tenantIDs, rest: 'usersfortenant', module: 'users', userid: helpers.getCookie('userid')};
        axios({
            method: "POST",
            url: helpers.restURL,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json",
            data: JSON.stringify(data),
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (!res.data.sessionexpired) {
                dispatch({ type: "getUsersList", payload: res.data.data });
            }
        }).catch(function (error) {
            dispatch({ type: "getUsersList", payload: [] });
        });
    };
}

export function getUsersGroup() {
    return function (dispatch) {
        let url = helpers.restURL + "?module=users&rest=getallgroups";
        axios({
            method: "GET",
            url: url,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json",
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (!res.data.sessionexpired) {
                let data = res.data.data;
                if (data === null) {
                    data = [];
                }
                dispatch({ type: "getUsersGroup", payload: data });
            }
        }).catch(function (error) {
            dispatch({ type: "getUsersGroup", payload: [] });
        });
    };
}