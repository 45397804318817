import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDefaultTheme, lightTheme, darkTheme } from "../actions/themeAction"
import { Button } from "reactstrap";
import helpers from "../helpers";

class Theme extends Component {
    constructor(props) {
        super(props);
        this.setTheme = this.setTheme.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.state = {
            currentTheme: null,
            showThemeChange: "block"
        }
    }

    componentDidMount() {
        let currentTheme = helpers.getSessionStorage('theme');
        if (currentTheme === "" || currentTheme === null || typeof currentTheme === 'undefined') {
            // this.props.getDefaultTheme();
            this.props.darkTheme();
            this.setState({ currentTheme: "dark" });
        } else if (currentTheme === "light") {
            this.props.lightTheme();
            this.setState({ currentTheme: currentTheme });
        } else if (currentTheme === "dark") {
            this.props.darkTheme();
            this.setState({ currentTheme: currentTheme });
        }
        setTimeout(() => { this.setTheme(); }, 500);
        if (typeof this.props.showThemeButton !== 'undefined') {
            this.setState({ showThemeChange: "none" });
        }
    }

    setTheme() {
        if (typeof this.props.theme !== 'undefined') {
            let theme = this.props.theme;
            Object.keys(theme).forEach((key) => {
                let cssKey = `${key}`;
                if (cssKey === "backgroundColor") {
                    cssKey = "background-color";
                }
                let cssValue = theme[key];
                document.body.style.setProperty(cssKey, cssValue);
            });
            helpers.setSessionStorage("theme", this.state.currentTheme);
        } else {
            helpers.setSessionStorage("theme", "dark");
        }
    }

    changeTheme() {
        if (this.state.currentTheme === "dark") {
            this.setState({ currentTheme: "light" });
            this.props.lightTheme();
            setTimeout(() => { this.setTheme(); }, 100);
        } else {
            this.setState({ currentTheme: "dark" });
            this.props.darkTheme();
            setTimeout(() => { this.setTheme(); }, 100);
        }
    }

    render() {
        let btnStyle = { 'color': 'white' };
        if (typeof this.props.theme !== 'undefined') {
            btnStyle = this.props.theme.button;
        }
        const fontColor = this.props.theme ? this.props.theme.color : "#2A3139";
        return (
            <div>
                {this.state.showThemeChange === "block" ?
                    <button onClick={this.changeTheme} style={btnStyle} className="SearchBox-button">
                        <i className="SearchBox-icon fa fa-lightbulb-o fa-1x" style={{ marginLeft: "9px", color: fontColor }}></i>
                    </button>
                    :
                    ""
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getDefaultTheme: getDefaultTheme, lightTheme: lightTheme, darkTheme: darkTheme }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Theme);
