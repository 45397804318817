import React from 'react'

import  ContentLoader  from "react-content-loader";
import "../sass/style.css";
import { ClipLoader } from 'react-spinners';

const Loader = {
    bubbleChartLoader: function () {
        return (<ContentLoader
            height={250}
            width={400}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <circle cx="200" cy="120" r="10" />
            <circle cx="150" cy="180" r="18" />
            <circle cx="80" cy="90" r="18" />
            <circle cx="280" cy="100" r="22" />
        </ContentLoader>);
    },
    pieChartLoader: function () {
        return (<ContentLoader
            speed={2}
            height={250}
            width={400}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <circle cx="200" cy="80" r="50" />
            <rect x="70" y="220" rx="4" ry="4" width="50" height="9" />
            <rect x="130" y="220" rx="4" ry="4" width="50" height="9" />
            <rect x="190" y="220" rx="4" ry="4" width="50" height="9" />
            <rect x="250" y="220" rx="4" ry="4" width="50" height="9" />
        </ContentLoader>);
    },
    horizontalBarLoader: function () {
        return (<ContentLoader
            height={250}
            width={400}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >

            <rect x="70" y="30" rx="4" ry="4" width="220" height="18" />
            <rect x="70" y="80" rx="4" ry="4" width="120" height="18" />
            <rect x="70" y="130" rx="4" ry="4" width="60" height="18" />
            <rect x="70" y="180" rx="4" ry="4" width="250" height="18" />
            <rect x="70" y="230" rx="4" ry="4" width="170" height="18" />

        </ContentLoader>);
    },
    verticalBarLoader: function () {
        return (<ContentLoader
            height={250}
            width={600}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="70" y="20" rx="4" ry="4" width="20" height="200" />
            <rect x="130" y="70" rx="4" ry="4" width="20" height="150" />
            <rect x="190" y="140" rx="4" ry="4" width="20" height="80" />
            <rect x="250" y="100" rx="4" ry="4" width="20" height="120" />
            <rect x="310" y="20" rx="4" ry="4" width="20" height="200" />
            <rect x="380" y="70" rx="4" ry="4" width="20" height="150" />
            <rect x="450" y="60" rx="4" ry="4" width="20" height="160" />
        </ContentLoader>);
    }, 
    ingestionSummaryLoader: function () {
        return (<ContentLoader
            height={60}
            width={600}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="10" y="10" rx="0" ry="0" width="20" height="90" />
            <rect x="70" y="20" rx="0" ry="0" width="20" height="45" />
            <rect x="130" y="50" rx="0" ry="0" width="20" height="10" />
            <rect x="190" y="25" rx="0" ry="0" width="20" height="40" />
            <rect x="250" y="25" rx="0" ry="0" width="20" height="35" />
            <rect x="310" y="20" rx="0" ry="0" width="20" height="45" />
            <rect x="380" y="40" rx="0" ry="0" width="20" height="25" />
            <rect x="450" y="35" rx="0" ry="0" width="20" height="30" />
            <rect x="520" y="45" rx="0" ry="0" width="20" height="15" />
            <rect x="580" y="35" rx="0" ry="0" width="20" height="30" />
        </ContentLoader>);
    },
    customdashboardloader: function () {
        return (
            <div style={{ marginTop: '300px' }}>

            <ClipLoader size={50} color="#36D7B7" loading={true} /></div>)
    },
    dashboardloader: function () {
        return (
        <div className='pageDashboardLoader'>
            <ClipLoader size={50} color="#36D7B7" loading={true} />
        </div>
        )
    },
    mainpageloader: function () {
        return (
            <div className ="mainPageLoader">
            <ClipLoader size={50} color="#36D7B7" loading={true} />
        </div>
        )
    },
    platformpageloader: function () {
        return (
            <div className = "platformPageLoader">
            <ClipLoader size={50} color="#36D7B7" loading={true} />
        </div>
        )
    },
    tableLoaderN: function (props) {
        const { casesloaderwidth } = props;
        const random = Math.random() * (1 - 0.7) + 0.7
        return (
            <ContentLoader
                height={70}
                width={casesloaderwidth ? 2150:1150}
                speed={2}
                primaryColor="rgba(42, 49, 57, 0.54)"
                secondaryColor="#ecebeb"
            >
                <rect x="0" y="0" rx="6" ry="6" width="100" height="6" />
                <rect x="130" y="0" rx="6" ry="6" width="100" height="6" />
                <rect x="263" y="0" rx="6" ry="6" width="100" height="6" />
                <rect x="403" y="0" rx="6" ry="6" width="200" height="6" />
                <rect x="650" y="0" rx="6" ry="6" width="100" height="6" />
                <rect x="800" y="0" rx="6" ry="6" width="150" height="6" />
                <rect x="1000" y="0" rx="6" ry="6" width="100" height="6" />

                <rect x="0" y="23" rx="6" ry="6" width="100" height="6" />
                <rect x="130" y="23" rx="6" ry="6" width="100" height="6" />
                <rect x="263" y="23" rx="6" ry="6" width="100" height="6" />
                <rect x="403" y="23" rx="6" ry="6" width="200" height="6" />
                <rect x="650" y="23" rx="6" ry="6" width="100" height="6" />
                <rect x="800" y="23" rx="6" ry="6" width="150" height="6" />
                <rect x="1000" y="23" rx="6" ry="6" width="100" height="6" />

                <rect x="0" y="43" rx="6" ry="6" width="100" height="6" />
                <rect x="130" y="43" rx="6" ry="6" width="100" height="6" />
                <rect x="263" y="43" rx="6" ry="6" width="100" height="6" />
                <rect x="403" y="43" rx="6" ry="6" width="200" height="6" />
                <rect x="650" y="43" rx="6" ry="6" width="100" height="6" />
                <rect x="800" y="43" rx="6" ry="6" width="150" height="6" />
                <rect x="1000" y="43" rx="6" ry="6" width="100" height="6" />

                <rect x="0" y="63" rx="6" ry="6" width="100" height="6" />
                <rect x="130" y="63" rx="6" ry="6" width="100" height="6" />
                <rect x="263" y="63" rx="6" ry="6" width="100" height="6" />
                <rect x="403" y="63" rx="6" ry="6" width="200" height="6" />
                <rect x="650" y="63" rx="6" ry="6" width="100" height="6" />
                <rect x="800" y="63" rx="6" ry="6" width="150" height="6" />
                <rect x="1000" y="63" rx="6" ry="6" width="100" height="6" />
            </ContentLoader>
        )
    

    },
    tableLoaderIngestion: function () {
    return (
        <ContentLoader
            height={150}
            width={1150}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="0" y="0" rx="8" ry="8" width="4" height="3.4" />
            <rect x="34" y="0" rx="6" ry="6" width="200" height="6" />
            <rect x="263" y="0" rx="6" ry="6" width="100" height="6" />
            <rect x="403" y="0" rx="6" ry="6" width="200" height="6" />
            <rect x="650" y="0" rx="6" ry="6" width="100" height="6" />
            <rect x="800" y="0" rx="6" ry="6" width="150" height="6" />
            <rect x="1000" y="0" rx="6" ry="6" width="100" height="6" />

            <rect x="0" y="43" rx="8" ry="8" width="4" height="3.4" />
            <rect x="34" y="43" rx="6" ry="6" width="200" height="6" />
            <rect x="263" y="43" rx="6" ry="6" width="100" height="6" />
            <rect x="403" y="43" rx="6" ry="6" width="200" height="6" />
            <rect x="650" y="43" rx="6" ry="6" width="100" height="6" />
            <rect x="800" y="43" rx="6" ry="6" width="150" height="6" />
            <rect x="1000" y="43" rx="6" ry="6" width="100" height="6" />

       

            <rect x="0" y="83" rx="8" ry="8" width="4" height="3.4" />
            <rect x="34" y="83" rx="6" ry="6" width="200" height="6" />
            <rect x="263" y="83" rx="6" ry="6" width="100" height="6" />
            <rect x="403" y="83" rx="6" ry="6" width="200" height="6" />
            <rect x="650" y="83" rx="6" ry="6" width="100" height="6" />
            <rect x="800" y="83" rx="6" ry="6" width="150" height="6" />
            <rect x="1000" y="83" rx="6" ry="6" width="100" height="6" />

            <rect x="0" y="123" rx="8" ry="8" width="4" height="3.4" />
            <rect x="34" y="123" rx="6" ry="6" width="200" height="6" />
            <rect x="263" y="123" rx="6" ry="6" width="100" height="6" />
            <rect x="403" y="123" rx="6" ry="6" width="200" height="6" />
            <rect x="650" y="123" rx="6" ry="6" width="100" height="6" />
            <rect x="800" y="123" rx="6" ry="6" width="150" height="6" />
            <rect x="1000" y="123" rx="6" ry="6" width="100" height="6" />
        </ContentLoader>
    )

    },
    tableLoader: function () {
        const TableRow = props => {
            const random = Math.random() * (1 - 0.7) + 0.7
            return (
                <ContentLoader
                    height={40}
                    width={1150}
                    speed={2}
                    primaryColor="rgba(42, 49, 57, 0.54)"
                    secondaryColor="#ecebeb"
                    {...props}
                >
                    <rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
                    <rect x="34" y="13" rx="6" ry="6" width={500 * random} height="12" />
                    <rect x="633" y="13" rx="6" ry="6" width={20 * random} height="12" />
                    <rect x="653" y="13" rx="6" ry="6" width={78 * random} height="12" />
                    <rect x="755" y="13" rx="6" ry="6" width={117 * random} height="12" />
                    <rect x="938" y="13" rx="6" ry="6" width={83 * random} height="12" />
                    <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
                </ContentLoader>
            )
        }

        return (<React.Fragment>
            {Array(10)
                .fill('')
                .map((e, i) => (
                    <TableRow key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
                ))}
        </React.Fragment>);
    },

    tileLoader: function () {
        return (<ContentLoader
            height={200}
            width={400}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="50" y="20" rx="0" ry="0" width="450" height="35" />
        </ContentLoader>);
    },
    platformLoader: function () {
        return (
        <ContentLoader
            height={100}
            width={400}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="10" y="0" rx="0" ry="0" width="450" height="20" />
            <rect x="10" y="30" rx="0" ry="0" width="450" height="20" />

        </ContentLoader>);
    },SingleLineLoader: function () {
        return (
        <ContentLoader
            height={100}
            width={400}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="10" y="30" rx="0" ry="0" width="450" height="30" />

        </ContentLoader>);
    },
    lineLoader: function () {
        return (<ContentLoader
            height={250}
            width={400}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="40" y="160" rx="3" ry="3" width="150" height="6" transform="rotate(337, 7, 160)" />
            <rect x="125" y="168" rx="3" ry="3" width="115" height="6" transform="rotate(205, 197, 140)" />
            <rect x="260" y="246" rx="3" ry="3" width="120" height="6" transform="rotate(337, 7, 160)" />
        </ContentLoader>);
    },
    liLoader: function () {
        return (<ContentLoader
            height={800}
            width={300}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="10" y="70" rx="0" ry="0" width="600" height="15" />
            <rect x="10" y="100" rx="0" ry="0" width="600" height="15" />
            <rect x="10" y="140" rx="0" ry="0" width="600" height="2" />
            <rect x="10" y="170" rx="0" ry="0" width="600" height="15" />
            <rect x="10" y="200" rx="0" ry="0" width="600" height="15" />
            <rect x="10" y="240" rx="0" ry="0" width="600" height="2" />
            <rect x="10" y="270" rx="0" ry="0" width="600" height="15" />
            <rect x="10" y="300" rx="0" ry="0" width="600" height="15" />
            <rect x="10" y="340" rx="0" ry="0" width="600" height="2" />
            <rect x="10" y="370" rx="0" ry="0" width="600" height="15" />
            <rect x="10" y="400" rx="0" ry="0" width="600" height="15" />
            <rect x="10" y="440" rx="0" ry="0" width="600" height="2" />
        </ContentLoader>);
    },
    cardLoader: function () {
        return (<ContentLoader
            height={1500}
            width={300}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="10" y="70" rx="25" ry="25" width="280" height="200" />
            <rect x="10" y="285" rx="25" ry="25" width="280" height="200" />
            <rect x="10" y="500" rx="25" ry="25" width="280" height="200" />
            <rect x="10" y="715" rx="25" ry="25" width="280" height="200" />
            <rect x="10" y="930" rx="25" ry="25" width="280" height="200" />
            <rect x="10" y="1145" rx="25" ry="25" width="280" height="200" />
        </ContentLoader>);
    },
    funnelLoader: function () {
        return (<ContentLoader
            height={800}
            width={300}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="10" y="10" rx="25" ry="25" width="280" height="50" />
            <rect x="50" y="50" rx="25" ry="25" width="200" height="50" />
            <rect x="80" y="90" rx="25" ry="25" width="150" height="50" />
            <rect x="105" y="130" rx="25" ry="25" width="100" height="50" />
        </ContentLoader>);
    }, assetLoader: function () {
        return (<ContentLoader
            height={500}
            width={500}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="100" y="0" rx="5" ry="5" width="300" height="30" />
            <rect x="10" y="80" rx="5" ry="5" width="480" height="80" />
            <rect x="10" y="200" rx="5" ry="5" width="480" height="15" />
            <rect x="10" y="230" rx="5" ry="5" width="480" height="15" />
            <rect x="10" y="300" rx="5" ry="5" width="480" height="15" />
            <rect x="10" y="330" rx="5" ry="5" width="480" height="15" />
            <rect x="10" y="400" rx="5" ry="5" width="480" height="15" />
            <rect x="10" y="430" rx="5" ry="5" width="480" height="15" />
        </ContentLoader>);
    },
     feedsLoaderdashboard : () => {
        return (
            <ContentLoader
                height={100}
                width={500}  // Set width to "100%" for full screen width
                speed={2}
                primaryColor="rgba(42, 49, 57, 0.54)"
                secondaryColor="#ecebeb"
            >
                <rect x="20" y="10" rx="1" ry="1" width="20" height="13" />
                <rect x="50" y="10" rx="1" ry="1" width="150" height="2" />
                {/* Add more rectangle elements */}
            </ContentLoader>
        );
    },
    feedsLoader: () => {
        return (<ContentLoader
            height={100}
            width={300}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            {/* <circle cx="30" cy="18" r="10" /> */}
            <rect x="20" y="10" rx="1" ry="1" width="20" height="13" />
            <rect x="50" y="10" rx="1" ry="1" width="150" height="2" />
            <rect x="50" y="15" rx="1" ry="1" width="220" height="2" />
            <rect x="50" y="20" rx="1" ry="1" width="80" height="2" />
            <rect x="50" y="25" rx="1" ry="1" width="120" height="2" />
            <rect x="10" y="30" rx="1" ry="1" width="280" height="2" />

            {/* <circle cx="30" cy="60" r="10" /> */}
            <rect x="20" y="55" rx="1" ry="1" width="20" height="13" />
            <rect x="50" y="55" rx="1" ry="1" width="150" height="2" />
            <rect x="50" y="60" rx="1" ry="1" width="220" height="2" />
            <rect x="50" y="65" rx="1" ry="1" width="80" height="2" />
            <rect x="50" y="70" rx="1" ry="1" width="120" height="2" />
            <rect x="10" y="75" rx="1" ry="1" width="280" height="2" />

        </ContentLoader>);
    }, widgetloader: function () {
        return (<ContentLoader
            height={500}
            width={500}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="10" y="0" rx="5" ry="5" width="480" height="200" />
        </ContentLoader>);
    },
    timeLineLoader: () => {
        return (<ContentLoader
            height={100}
            width={500}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="179" y="339" rx="3" ry="3" width="300" height="2" transform="rotate(270, 168, 258)" />

            <rect x="0" y="12" rx="3" ry="5" width="240" height="10" />
            <circle cx="250" cy="17" r="5" />
            <rect x="260" y="12" rx="3" ry="5" width="50" height="10" />

            <rect x="190" y="36" rx="3" ry="5" width="50" height="10" />
            <circle cx="250" cy="40" r="5" />
            <rect x="260" y="36" rx="3" ry="5" width="240" height="10" />

            <rect x="0" y="60" rx="3" ry="5" width="240" height="10" />
            <circle cx="250" cy="65" r="5" />
            <rect x="260" y="60" rx="3" ry="5" width="50" height="10" />

            <rect x="190" y="84" rx="3" ry="5" width="50" height="10" />
            <circle cx="250" cy="90" r="5" />
            <rect x="260" y="84" rx="3" ry="5" width="240" height="10" />
        </ContentLoader>);
    }, ResponseLoader: function () {
        return (
        <ContentLoader
            height={100}
            width={400}
            speed={2}
            primaryColor="rgba(42, 49, 57, 0.54)"
            secondaryColor="#ecebeb"
        >
            <rect x="10" y="5" rx="0" ry="0" width="450" height="20" />
            <rect x="10" y="30" rx="0" ry="0" width="450" height="20" />
            <rect x="10" y="60" rx="0" ry="0" width="450" height="20" />
            <rect x="10" y="90" rx="0" ry="0" width="450" height="20" />
            <rect x="10" y="120" rx="0" ry="0" width="450" height="20" />

        </ContentLoader>);
    },
};

export default Loader;
