export default function providerstagsReducer(state = {
    type: "",
    provider: [],
    tags:[],
}, action) {
    switch (action.type) {
        case "providerstags":
            return action.payload
    }
    return state
}
