import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";

class PageNotFound extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.handleRedirect = this.handleRedirect.bind(this);
    }

    componentWillMount() {
        document.body.style.setProperty("background-color", "#00bfa5");
    }

    componentWillUnmount() {
        document.body.style.setProperty("background-color", this.props.theme.backgroundcolor);
    }

    handleRedirect(url) {
        this.props.history.push(url);
    }

    render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <div className="container-fluid">
                    <div className="content-wrapper d-flex align-items-center text-center error-page">
                        <Row>
                            <Col lg="12" style={{ marginTop: "150px" }}>
                                <Col lg="5">
                                    <h1 className="text-white" style={{ fontSize: "12rem" }}>404</h1>
                                </Col>
                                <Col lg="6" style={{ paddingTop: "40px" }}>
                                    <Col lg="12" className="error-page-divider text-lg-left pl-lg-4">
                                        <h2 className="text-white">SORRY!</h2>
                                        <h3 className="text-white">The page you’re looking for was not found.</h3>
                                    </Col>
                                </Col>
                                <Col lg="1"></Col>
                            </Col>
                            <Col lg="5"></Col>
                            <Col md="6" lg="6" className="text-left mt-xl-2" style={{ marginLeft: "20px" }}>
                                <h6 className="text-white font-weight-medium" style={{ cursor: "pointer" }} onClick={() => this.handleRedirect("/home")}>Back to home</h6>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

const exportPageNotFound = withRouter(connect(mapStateToProps, mapDispatchToProps)(PageNotFound));
export default exportPageNotFound;