import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { Container, Col, Button, Modal, ModalHeader, ModalBody, } from "reactstrap";
import { getDefaultTheme, lightTheme, darkTheme } from "../actions/themeAction";
import Theme from "./theme";
import Tenants from './Tenants';
import helpers from "../helpers";

class AddFirstTenant extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showNotificationModal: false,
            isSuperAdmin: false,
            firstLogin: false
        };
        this.toggleNotificationModal = this.toggleNotificationModal.bind(this);
    }

    componentWillMount() {
        let userData = helpers.getSessionStorage("userData");
        if (userData === "" || userData === null || typeof userData === 'undefined') {
            helpers.checkSessionTimeout(true);
        } else {
            let data = JSON.parse(userData);
            if (!data.hassecuraalicence) {
                this.props.history.push("/license");
            } else if (data.tenantsinfo.length === 0 && data.issuperadmin === "y") {
                this.setState({ isSuperAdmin: true });
            } else if (data.tenantsinfo.length === 0 && data.issuperadmin === "n") {
                this.setState({ showNotificationModal: true });
            } else {
                this.props.history.push("/home");
            }
        }
    }

    toggleNotificationModal() {
        this.setState({ showNotificationModal: false });
        this.props.history.push("/logout");
    }

    componentDidMount() {
        if (helpers.getSessionStorage("theme") === "dark") {
            this.props.darkTheme();
        } else {
            this.props.lightTheme();
        }
    }

    render() {

        let divStyle = { color: "" };
        let modalStyle = { color: "" };
        if (typeof this.props.theme !== 'undefined') {
            divStyle = this.props.theme.div;
            modalStyle = this.props.theme.modal;
        }
        const fontColor = this.props.theme ? this.props.theme.color : "#2A3139";

        return (
            <div>
                <Theme showThemeButton="hide" />
                <Container>
                    {this.state.isSuperAdmin ?
                        <Col sm="12">
                            <Col sm="3">
                            </Col>
                            <Col sm="6" className="add-tenant-form" style={divStyle}>
                                <h4 style={{ color: "#09897e" }}><i className="fa fa-user-o"></i> &nbsp;We don't find any tenants ! Please add tenant to continue</h4>
                                <div style={{ padding: "20px" }}>
                                    <Tenants />
                                </div>
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Col>
                        : ""}
                    <Modal fade={false} isOpen={this.state.showNotificationModal} toggle={this.toggleNotificationModal} backdrop="static">
                        <ModalHeader toggle={this.toggleNotificationModal} style={modalStyle}><span style={{ color: fontColor, fontSize: "20px" }}>Message</span></ModalHeader>
                        <ModalBody style={modalStyle}>
                            <h6 style={{ color: fontColor }}>No Tenants Found! Contact Your Admin</h6>
                            <div className="pull-right" style={{ marginTop: "20px" }}>
                                <Button size="sm" color="secondary" onClick={this.toggleNotificationModal}>Close</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getDefaultTheme: getDefaultTheme, lightTheme: lightTheme, darkTheme: darkTheme }, dispatch)
}

const exportLicense = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFirstTenant))
export default exportLicense;
