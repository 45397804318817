import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDefaultTheme, lightTheme, darkTheme } from "../actions/themeAction";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import { Button, Input, Label, Col, FormGroup, Row } from 'reactstrap';
import helpers from "../helpers";
import LoadingSpinner from './LoadingSpinner';
import SweetAlert from 'react-bootstrap-sweetalert';
// import { ToastContainer, toast } from 'react-toastify';
const restURL = helpers.restURL;

class AssetBusinessHierarchySetting extends Component {
    constructor() {
        super();
        this.state = {
            showAddForm: "hide",
            saveDisabled: true,
            hierarchyText: "",
            mappingData: [],
            removeItem: "",
            errorMessage: "No Data Found",
            hierarchyMap: {},
            showDeleteModal: false,
            loading: "hide",hierarchyvalue:"",hierarchyerror:"hide",hierarchyerrortext:""
        }

        this.assetTimeChange = this.assetTimeChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleChangeFormValues = this.handleChangeFormValues.bind(this);
        this.closeUpdateForm = this.closeUpdateForm.bind(this);
        this.setMappingData = this.setMappingData.bind(this);
        this.updateMappingData = this.updateMappingData.bind(this);
        this.handleChangeUpdateFormValues = this.handleChangeUpdateFormValues.bind(this);
        this.saveCategoryMapping = this.saveCategoryMapping.bind(this);
        this.deleteMappingData = this.deleteMappingData.bind(this);
    }

    componentDidMount() {
        this.getAvmList();
    }

    getAvmList = () => {
        var url = helpers.restURL + "?module=assetExplorer&rest=getavmsettingfortenant&q=" + helpers.getCookie("tenantcode") + "/";

        axios({
            method: "GET",
            url: url,
            headers: {
                'access_token': helpers.getCookie("accesstoken"),
                "Content-Type": "application/json; charset=utf-8",
                jwt_token: helpers.getCookie('jwt_token'),
            },
            dataType: "json"
        }).then(res => {

            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                if (res.data.data !== null && res.data.data.businesshierarchy !== null) {
                    let data = res.data.data.businesshierarchy;
                    if (typeof data !== 'undefined' && data !== null & data.length > 0) {
                        let mappingData = [];
                        for (let i = 0; i < data.length; i++) {
                            let obj = {
                                id: "businessObj" + i,
                                application: data[i]
                            };
                            mappingData = mappingData.concat(obj);
                        }

                        this.setMappingData(mappingData, "", "");
                    }
                }

            }else{
              if(res.data.displaymessage.toLowerCase() == "securaa server is not reachable"){
                this.setState({ errorMessage: <h6 className="text-center">AVM Server is Not Reachable</h6> });
            }else{
              this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6> });
            }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    componentWillReceiveProps(newProps) {
        if (typeof newProps.hierarchies !== 'undefined' && newProps.hierarchies !== null && newProps.hierarchies.length > 0 && this.state.caseHierarchies !== newProps.hierarchies) {
            let sortArray = helpers.sortArray(newProps.hierarchies, "name");
            let hierarchyMap = {};
            for (let i = 0; i < sortArray.length; i++) {
                hierarchyMap[sortArray[i].id] = sortArray[i].name;
            }
            this.setState({ caseHierarchies: sortArray, hierarchyMap });
        }
    }

    redirectMessage(text) {
        helpers.securaaNotification(text,"success",10);
        // toast.success(text, {
        //     position: "top-right",
        //     autoClose: 2000,
        //     closeOnClick: true,
        //     hideProgressBar: true,
        //     pauseOnHover: false,
        //     draggable: false
        //     // transition: "flip"
        // });
    }

    resetForm() {
        this.setState({
            //hierarchyText_Error: "", selectedHierarchy: "", selectedHierarchy_Error: "",
            hierarchyText: "", showAddForm: "hide", saveDisabled: true,hierarchyerror:"hide",
            hierarchyerrortext:""
        });
    }

    setMappingData(data, action, updateID) {
        this.setState({ mappingData: data, errorMessage: "" });
        for (let i = 0; i < data.length; i++) {
            if (action === "update") {
                if (data[i].id === updateID) {
                    this.setState({
                        [data[i].id + "application"]: data[i].hierarchyData,
                        [data[i].id + "_application_Error"]: "",
                        // [data[i].id + "_selectedHierarchy"]: data[i].hierarchy_id,
                        // [data[i].id + "_selectedHierarchy_Error"]: ""
                    });
                }
            } else {
                this.setState({
                    [data[i].id + "application"]: data[i].hierarchyData,
                    [data[i].id + "_application_Error"]: "",
                    // [data[i].id + "_selectedHierarchy"]: data[i].hierarchy_id,
                    // [data[i].id + "_selectedHierarchy_Error"]: ""
                });
            }
        }
    }

    closeUpdateForm(id) {
        this.setState({ [id + "_show"]: "hide", [id + "_saveDisabled"]: true });
        this.setMappingData(this.state.mappingData, "update", id);
    }

    handleChangeFormValues(e) {
        if (e.target.value === "" || e.target.value.length === 0) {
            this.setState({ [e.target.name + "_Error"]: "This field is required" });
        } else {
            this.setState({ [e.target.name + "_Error"]: "" });
        }
        this.setState({ [e.target.name]: e.target.value, hierarchyvalue:e.target.value });


        setTimeout(() => {
            if (this.state.hierarchyText !== "") {
                this.setState({ saveDisabled: false });
            } else {
                this.setState({ saveDisabled: true });
            }
        }, 50);
    }

    handleChangeUpdateFormValues(id, e) {
        if (e.target.value === "" || e.target.value.length === 0) {
            this.setState({ [e.target.name + "_Error"]: "This field is required" });
        } else {
            this.setState({ [e.target.name + "_Error"]: "" });
        }
        this.setState({ [e.target.name]: e.target.value });

        setTimeout(() => {
            if (typeof this.state[id + "_selectedHierarchy"] !== 'undefined' && this.state[id + "_selectedHierarchy"] !== "" && typeof this.state[id + "_hierarchyText"] !== 'undefined' && this.state[id + "_hierarchyText"] !== "") {
                this.setState({ [id + "_saveDisabled"]: false });
            } else {
                this.setState({ [id + "_saveDisabled"]: true });
            }
        }, 50);
    }

    assetTimeChange(event) {
        let value = event.target.value;
        if (value === "") {
            this.setState({ disableAssetUpdateTimeBtn: true, assetBatchRunTimeError: "show", assetBatchRunTimeErrorMessage: "Time is required" });
        } else {
            if (value.match(/^[0-9]*$/)) {
                this.setState({ disableAssetUpdateTimeBtn: false, assetBatchRunTimeError: "hide", assetBatchRunTimeErrorMessage: "" });
            } else {
                this.setState({ disableAssetUpdateTimeBtn: true, assetBatchRunTimeError: "show", assetBatchRunTimeErrorMessage: "Only numbers" });
            }
        }
        this.setState({ assetRunTime: value });
    }

    successMessage(text) {
        helpers.securaaNotification(text,"success",10);
        // toast.success(text, {
        //     position: "top-right",
        //     autoClose: 2000,
        //     closeOnClick: true,
        //     hideProgressBar: true,
        //     pauseOnHover: false,
        //     draggable: false
        // });
    }

    errorMessage(text) {
        helpers.securaaNotification(text,"error",10);
        // toast.error(text, {
        //     position: "top-right",
        //     autoClose: 2000,
        //     closeOnClick: true,
        //     hideProgressBar: true,
        //     pauseOnHover: false,
        //     draggable: false
        // });
    }

    saveCategoryMapping(type) {

        this.setState({ loading: "show" });
        let { hierarchyText, mappingData } = this.state;
        let hierarchyData = [];
        let hierarchyDataNew = [];
        if (mappingData.length > 0) {
            for (let i = 0; i < mappingData.length; i++) {
                hierarchyData = hierarchyData.concat(mappingData[i].application);
                hierarchyDataNew = hierarchyDataNew.concat((mappingData[i].application).toLowerCase());
            }
        }

        if (hierarchyText !== "") {
          if(hierarchyDataNew.includes(hierarchyText.toLowerCase())){
        }else{
            hierarchyData = hierarchyData.concat(hierarchyText);
          }
        }
        let data = {
            businesshierarchy: hierarchyData,
            tenantcode: helpers.getCookie('tenantcode'),
            rest: "setavmsettingfortenant",
            module: "assetExplorer"
        };

        axios({
            method: "POST",
            url: helpers.restURL,
            headers: {
                'access_token': helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token')
            },
            dataType: "json",
            data: data
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
              if(hierarchyDataNew.includes(hierarchyText.toLowerCase())){
                this.setState({hierarchyerror:"show",hierarchyerrortext:hierarchyText+" already exists",hierarchyvalue:""})
                setTimeout(() => {this.setState({ hierarchyerror:"hide",hierarchyerrortext:"",hierarchyvalue:"" });}, 2000);
            }else{
                this.successMessage("Business hierarchy updated successfully.");
                this.setState({hierarchyvalue:""})

                let data = res.data.data.businesshierarchy;
                if (data.length > 0) {
                    let mappingData = [];

                    for (let i = 0; i < data.length; i++) {
                        let obj = {
                            id: i,
                            application: data[i]
                        };
                        mappingData = mappingData.concat(obj);
                    }
                    this.setMappingData(mappingData, "", "");
                    this.resetForm();
                } else {
                    this.setState({ mappingData: [], errorMessage: <h6 className="text-center">No data Found</h6> });
                }
                }
            } else {
                this.errorMessage("Failed to save.");
            }
            this.setState({ loading: "hide" });
        }).catch((error) => {
            console.log(error);
        });

    }


    showDeleteModal(id) {
        this.setState({ selectedMappingID: id, showDeleteModal: true });
    }

    deleteMappingData() {

        var toRemove = this.state.selectedMappingID;
        var array = this.state.mappingData.filter(function (item) {

            return item.application !== toRemove
        });

        this.setState({
            mappingData: array
        }, function () {
            this.saveCategoryMapping("delete");
            this.setState({ selectedMappingID: "", showDeleteModal: false });
        });

    }
    updateMappingData(id) {

        this.setState({ loading: "show", [id + "_show"]: "hide" });

        //let selectedHierarchy = this.state[id + "_selectedHierarchy"];

        let hierarchyText = this.state[id + "_hierarchyText"];
        let data = {
            id: parseInt(id),
            //hierarchy_id: parseInt(selectedHierarchy),
            hierarchy_text: hierarchyText.trim(),
            tenantcode: helpers.getCookie("tenantcode"),
            rest: "heierarchymapping",
            module: "users"
        };

        axios({
            method: "PUT",
            url: helpers.restURL,
            headers: {
                'access_token': helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token')
            },
            dataType: "json",
            data: data
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                this.successMessage("Updated successfully.");
                let data = res.data.data;
                if (data.length > 0) {
                    this.setMappingData(data, "", "");
                } else {
                    this.setState({ mappingData: [], errorMessage: <h6 className="text-center">No data Found</h6> });
                }
            } else {
                this.errorMessage("Failed to update.");
            }
            this.setState({ loading: "hide" });
        }).catch((error) => {
            console.log(error);
        });
    }



    render() {
        let inputStyle = { color: "" };
        let divStyle = { color: '' };
        let cardStyle = { color: '' };
        let modalStyle = { color: "" };
        if (typeof this.props.theme !== 'undefined') {
            inputStyle = this.props.theme.input;
            divStyle = this.props.theme.div;
            cardStyle = this.props.theme.divCard2;
            modalStyle = this.props.theme.modal;
        }

        const fontColor = this.props.theme ? this.props.theme.color : "#2A3139";

        return (
            <div>
                <LoadingSpinner showSpinner={this.state.loading} />
                {/* <ToastContainer /> */}
                <div style={{ textAlign: "left" }}>
                    {helpers.getCookie("_platformWritePersmission") === "true" ?
                        <Col sm="12" lg="12" md="12" xs="12">
                            {this.state.showAddForm === "hide" ?
                                <Button size="sm" color="success" style={{ marginBottom: "8px" }}
                                    onClick={() => this.setState({ showAddForm: "show" })}>
                                     Add New Asset Business Hierarchy</Button>
                                : ""}
                        </Col>
                        : ""}

                    {this.state.showAddForm === "show" ?
                        <Col sm="12" lg="12" md="12" xs="12" style={{ padding: "0px" }}>
                            <Row>

                                <Col sm="8" lg="8" md="8" xs="8">
                                    <FormGroup>
                                        <Label style={{ color: fontColor }} >Business Application</Label>
                                        <Input style={inputStyle} type="text" name="hierarchyText" value = {this.state.hierarchyvalue} onChange={(e) => this.handleChangeFormValues(e)} placeholder="Business Application" />
                                        <div className={"help-block with-errors "+this.state.hierarchyerror}>{this.state.hierarchyerrortext}</div>
                                    </FormGroup>
                                </Col>

                                <Col sm="4" lg="4" md="4" xs="4">
                                    <Button color="success" disabled={this.state.saveDisabled}
                                        style={{ marginTop: "30px" }} size="sm" onClick={() => this.saveCategoryMapping("save")}>Save</Button>


                                    <Button size="sm" style={{ marginTop: "30px" }} color="danger"
                                        onClick={() => this.resetForm()}>
                                        <i className="fa fa-close"></i> Cancel</Button>
                                </Col>
                            </Row>
                        </Col>
                        : ""}
                </div>

                <Col sm="12" lg="12" md="12" xs="12">
                    {this.state.mappingData.length > 0 ?
                        this.state.mappingData.map((data, index) => (
                            <React.Fragment key={index}>
                                <div style={{ padding: "0px" }} className={this.state[data.id + "_show"] === "hide" ? "show" : typeof this.state[data.id + "_show"] === 'undefined' ? "show" : "hide"}>
                                    <Col sm="12" lg="12" md="12" xs="12" style={cardStyle} className="category-mapping-panel">
                                        <Row>
                                            <Col sm="10" lg="10" md="10" xs="10">
                                                <p style={{ color: fontColor, marginTop: "5px" }} > {data.application}</p>
                                            </Col>

                                            <Col sm="2" lg="2" md="2" xs="2" style={{ fontSize: "13px", textAlign: "right", paddingRight: "20px", marginTop: '5px' }}>
                                                <span title="Delete" className="cursor-point"
                                                    onClick={() => this.showDeleteModal(data.application)}>
                                                    <i className="fa fa-trash"></i></span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                                <div className={this.state[data.id + "_show"] === "show" ? "show" : "hide"}>
                                    <Col key={index} style={cardStyle} sm="12" lg="12" md="12" xs="12" className="category-mapping-panel">

                                        <Col sm="8" lg="8" md="8" xs="8">
                                            <FormGroup>
                                                <Label style={{ color: fontColor }} >Business Application</Label>
                                                <Input style={inputStyle} type="textarea" value={this.state[data.id + "application"]} name={data.id + "application"} onChange={(e) => this.handleChangeUpdateFormValues(data.id, e)}
                                                    placeholder="Business Application" />
                                                <div className="errorMessage">{this.state[data.id + "_application_Error"]}</div>
                                            </FormGroup>
                                        </Col>

                                        <Col sm="4" lg="4" md="4" xs="4">
                                            <Button color="success" disabled={this.state[data.id + "_saveDisabled"]}
                                                style={{ marginTop: "30px" }} size="sm" onClick={() => this.updateMappingData(data.id)}>Update</Button>

                                            <Button size="sm" style={{ marginTop: "30px" }} color="danger"
                                                onClick={() => this.closeUpdateForm(data.id)}>
                                                <i className="fa fa-close"></i> Cancel</Button>
                                        </Col>

                                    </Col>
                                </div>
                            </React.Fragment>
                        ))
                        : this.state.errorMessage}
                </Col>

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure want to delete ?"
                    btnSize="sm"
                    onConfirm={() => this.deleteMappingData()}
                    onCancel={() => this.setState({ selectedMappingID: "", showDeleteModal: false })}
                    cancelBtnBsStyle="success"
                    closeOnClickOutside={false}
                    style={{
                        backgroundColor: modalStyle.background,
                        color: fontColor, width: "max-content",
                        fontSize: "14px",
                        border: "1px solid grey"
                    }}
                    show={this.state.showDeleteModal}
                >
                    <span style={{ color: "#dc3545" }}>You will not be able to recover this !</span>
                </SweetAlert>

            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getDefaultTheme: getDefaultTheme, lightTheme: lightTheme, darkTheme: darkTheme }, dispatch)
}

const exportAssetBusinessHierarchySetting = withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetBusinessHierarchySetting));
export default exportAssetBusinessHierarchySetting;
