import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import helpers from "../helpers";

export class ToastMessage extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        let { showMessage, toastMessage, toastTimeout, messageType } = this.props;
        this.setState({ showMessage });
        if (showMessage === true) {
            if (messageType === "success") {
                this.showSuccesMessage(toastMessage, toastTimeout);
            } else if (messageType === "error") {
                this.showErrorMessage(toastMessage, toastTimeout);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        let { showMessage, toastMessage, toastTimeout, messageType } = nextProps;
        this.setState({ showMessage });
        if (showMessage === true) {
            if (messageType === "success") {
                this.showSuccesMessage(toastMessage, toastTimeout);
            } else if (messageType === "error") {
                this.showErrorMessage(toastMessage, toastTimeout);
            }
        }
    }

    showSuccesMessage = (message, timeout) => {
        timeout = typeof timeout === 'undefined' ? 2000 : timeout;
        helpers.securaaNotification(message,"success",10)
        // toast.success(message, {
        //     position: "top-right",
        //     autoClose: timeout,
        //     closeOnClick: true,
        //     hideProgressBar: true,
        //     pauseOnHover: false,
        //     draggable: false
        // });
    }

    showErrorMessage = (message, timeout) => {
        timeout = typeof timeout === 'undefined' ? 2000 : timeout;
        helpers.securaaNotification(message,"error",10)
        // toast.error(message, {
        //     position: "top-right",
        //     autoClose: timeout,
        //     closeOnClick: true,
        //     hideProgressBar: true,
        //     pauseOnHover: false,
        //     draggable: false
        // });
    }

    render() {
        return (
            <ToastContainer />
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessage)
