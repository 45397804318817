import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import helpers from '../helpers';

const mapStyles = {
    width: '100%',
    height: '275px',
};

class GoogleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            latitude: 0,
            longitude: 0
        };
    };

    componentDidMount() {
        if (typeof this.props.mapProps !== 'undefined') {
            let mapProps = this.props.mapProps;
            this.setState({ latitude: parseInt(mapProps.lat), longitude: parseInt(mapProps.long) })
        }
    }

    render() {
        return (
            <div>
                {this.state.latitude !== 0 && this.state.longitude !== 0 ?
                    <Map
                        google={this.props.google}
                        zoom={8}
                        style={mapStyles}
                        initialCenter={{ lat: this.state.latitude, lng: this.state.longitude }}
                    >
                        <Marker position={{ lat: this.state.latitude, lng: this.state.longitude }} />
                    </Map> : ""}
            </div>
        );
    }
}

export default GoogleApiWrapper({ apiKey: helpers.googleApiKey })(GoogleMap);
