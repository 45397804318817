export default function generalReducer(state = {}, action) {
    switch (action.type) {
        case "getTenantIndustryData":
            return { tindustrydata: action.payload }

        case "getTenantData":
            return { tenantdata: action.payload }

        case "getConfigurationVariables":
            return { configdata: action.payload }

        case "getTenantListData":
            return { tenantlistdata: action.payload }

        case "getLoadIndustryData":
            return { loadindustrydata: action.payload }

        case "getShiftsData":
            return { shiftsdata: action.payload }

        case "getConVarData":
            return { convardata: action.payload }

        case "getAuditTrailData":
            return { auditdata: action.payload }

        case "getSmtpSettingsData":
            return { smtpdata: action.payload }

        case "getSessionSettingsData":
            return { sessiondata: action.payload }

        case "getSnoozeSettingsData":
            return { snoozedata: action.payload }

        case "getRiskScoreOptions":
            return { riskscoredata: action.payload }

        case "getAutoAssignSettingsData":
            return { autoassigndata: action.payload }

        case "getAutoAssignShiftData":
            return { autoshiftdata: action.payload }

        case "getJiraBatchSettingsData":
            return { jiradata: action.payload }

        case "getEmailListenerConfig":
            return { emaildata: action.payload }

        case "getInsightsTimingData":
            return { insightsdata: action.payload }

        case "getSystemConfigData":
            return { systemdata: action.payload }

        case "getBatchSettingsData":
            return { batchdata: action.payload }
            
        case "getTeamsData":
            return { teamsdata: action.payload }
            
        case "getLogsData":
                return { logsdata: action.payload }
       /*tojo changes: Data archival and auto purge need to be added -start 01/07/2023*/         
        case "getSaveAutoPurge":
            return { saveautopurgedata: action.payload }
        /*tojo changes: Data archival and auto purge need to be added -end  01/07/2023*/
        case "getAddIndustryData":
            return { addindustrydata: action.payload }

        case "getUpdateIndustryData":
            return { updateindustrydata: action.payload }

        case "getDeleteIndustryData":
            return { deleteindustrydata: action.payload }

        case "getAddCredentialData":
            return { addcredentialdata: action.payload }

        case "getUpdateCredentialData":
            return { updatecredentialdata: action.payload }

        case "getDeleteCredentialData":
            return { deletecredentialdata: action.payload }

        case "getLoadCredentialData":
            return { loadcredentialdata: action.payload }
            
        case "getAddShiftData":
            return { addshiftdata: action.payload }

        case "getUpdateShiftData":
            return { updateshiftdata: action.payload }

        case "getDeleteShiftData":
            return { deleteshiftdata: action.payload }

        case "getAddTenantData":
            return { addtenantdata: action.payload }

        case "getUpdateSessionData":
            return { updatesessiondata: action.payload }

        case "getUpdateSnoozeData":
            return { updatesnoozedata: action.payload }

        case "getUpdateRiskScoreData":
            return { updateriskscoredata: action.payload }

        case "getUpdateAutoAssignData":
            return { updateautoassigndata: action.payload }

        case "getUpdateBatchData":
            return { updatebatchdata: action.payload }

        case "getUpdateJiraBatchData":
            return { updatejirabatchdata: action.payload }

        case "getUpdateInsightData":
            return { updateinsightdata: action.payload }

        case "getUpdateConfigurationData":
            return { updateconfigdata: action.payload }

        case "getSaveLogsData":
            return { savelogsdata: action.payload }

        case "gettipLogsData":
            return { tiplogsdata: action.payload }   

        case "getSavetipLogsData":
            return { savetiplogsdata: action.payload }
        /*tojo changes: Data archival and auto purge need to be added -start 01/07/2023*/
        case "getAutoPurgeData":
            return { autopurgedata: action.payload }
        /*tojo changes: Data archival and auto purge need to be added -end 01/07/2023*/
        case "getcsamLogsData":
            return { csamlogsdata: action.payload }   
        case "getSavecsamLogsData":
            return { savecsamlogsdata: action.payload }
       }
    return state
}