import React, { Component } from 'react';
import { connect } from "react-redux";
import helpers from "../helpers";
import { withRouter } from 'react-router-dom';
import { Container, FormGroup, Col, Button,Input,Label } from "reactstrap";
import axios from "axios";
import LoadingSpinner from './LoadingSpinner';
import parse from 'html-react-parser';
const restURL = helpers.restURL;
let url = window.location.origin;
url = url === "http://localhost:3000" ? "http://localhost" : url;
url = url + "/downloads/logo/logo.png";
url = url.toString();
class Approval extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: "none",
            showError: "none",
            disableApproveBtn: false,
            token: "",
            approvalMail:"",
            showSuccess: "hide",
            errorMessage: "",
            successMessage: "",
            successMessage1: "",
            subject: "",
            message: "",
            approvalStatus: "",
            approvaloptions:[],
            showButton:false,
            textColour:"",
            timestamp:new Date().getTime(),
            username:"",
            remark:"",
            userNameErrorMsg:"",
            userNameError:"hide",
            usernameValue:"",
            remarkValue:"",
            approvalMailValue:""
        };
        this.updateApprovalStatus = this.updateApprovalStatus.bind(this);
        this.getApprovalDetails = this.getApprovalDetails.bind(this);
        this.processApprovalDetails = this.processApprovalDetails.bind(this);
    }

    componentWillMount() {
        document.body.style.setProperty("background", `#272b35`);
    }

    componentDidMount() {
        this.getApprovalDetails();
    }

    getApprovalDetails() {
        this.setState({ loading: "show" });
        var token_value = '';
        var email_value = '';
        var url = window.location.href
        var parts = url.split('?');
        if (parts.length === 2) {
            var parameters = parts[1].split('&');
            for (var i = 0; i < parameters.length; i++) {
                if (parameters[i].indexOf('=') > 0) {
                    var parameter = parameters[i].split('=');
                    if (parameter[0] === "token") {
                        token_value = parameter[1];
                    }
                    if (parameter[0] === "email") {
                        email_value = parameter[1];
                    }
                }
            }
        }
        if (token_value === "") {
            this.setState({ disableApproveBtn: true, showSuccess: "hide", showError: "inline-block", errorMessage: "Invalid approval link" });
        } else {
            this.setState({ token: token_value });
        }

        if (email_value === "") {
            this.setState({ disableApproveBtn: true, showSuccess: "hide", showError: "inline-block", errorMessage: "Invalid approval link" });
        } else {
            this.setState({ approvalMail: email_value });
        }

        var url = helpers.restURL + '?module=user&rest=getapprovalstatus&q=' + token_value + '/';
        axios({
            method: "GET",
            url: url,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                if(res.data.data.approvaloptions !== "undefined" && res.data.data.approvaloptions !== "null" && res.data.data.approvaloptions !== "" ){
                    let approvaloptionsarray = res.data.data.approvaloptions.split(",")
                    this.setState({ approvaloptions:approvaloptionsarray})
                } else {
                    let defaultOptions = "Approve,Reject"
                    let approvaloptionsarray = defaultOptions.split(",")
                    this.setState({ approvaloptions:approvaloptionsarray})
                }
                this.setState({ subject: res.data.data.subject, message: res.data.data.message,usernameValue:res.data.data.approverName,remarkValue:res.data.data.approverRemark,approvalMailValue:res.data.data.approverMail })
                this.processApprovalDetails(res.data);
            } else {
                if (res.data.displaymessage !== "") {
                    this.setState({ disableApproveBtn: true, showError: "inline-block", errorMessage: res.data.displaymessage });
                } else {
                    this.setState({ disableApproveBtn: true, approvalStatus: "invalid", showError: "inline-block", errorMessage: "Invalid token" });
                }
            }
            this.setState({ loading: "hide" });
        }).catch(function (error) {
            console.log(error);
        });
    }

    processApprovalDetails(responseData) {
        let successMessageValue="";
        let successMessageValue1="";
        let showSuccessvalue="hide";
        let textColourValue=""
        if ("active" === responseData.data.validity || "addressed" === responseData.data.validity) {
            if (responseData.data.approvalstatus  !== "") {
                successMessageValue= "Request already addressed";
                successMessageValue1 ="Responded with - " + responseData.data.approvalstatus
                this.setState({ textColour:"rgba(38,185,154,.88)",showError: "none", showButton:true, showSuccess: "show", approvalStatus: "", successMessage: successMessageValue,successMessage1:successMessageValue1, disableApproveBtn: true, });
            }
        } else if ("stopped" == responseData.data.validity) {
                successMessageValue= "Playbook has been stopped";
                successMessageValue1= ""
                this.setState({ textColour:"rgba(231,76,60,0.88)",showError: "none", showButton:false, showSuccess: "show", approvalStatus: "", successMessage: successMessageValue,successMessage1:successMessageValue1, disableApproveBtn: true, });
        } else if ("expired" == responseData.data.validity) {
            if (responseData.data.approvalstatus  !== "") {
                successMessageValue= "Request already addressed"
                successMessageValue1 ="Responded with - " + responseData.data.approvalstatus
                showSuccessvalue = "show"
                textColourValue="rgba(38,185,154,.88)"
            } else {
                successMessageValue = "Request Expired"
                successMessageValue1=""
                showSuccessvalue = "hide"
                textColourValue="rgba(231,76,60,0.88)"
            }
            this.setState({ textColour:textColourValue,showError: "inline-block",showButton:true, showSuccess: showSuccessvalue, approvalStatus: "", errorMessage: successMessageValue,successMessage1:successMessageValue1,disableApproveBtn: true, });
        }
    }

    updateApprovalStatus(status) {
        this.setState({ loading: "show", approvalStatus: status });
        var data = {
            uuid: this.state.token, approvalstatus: status,
            rest: 'updateapprovalstatus', module: 'users',
            approverName:this.state.username,approverRemark:this.state.remark,approverMail: this.state.approvalMail
        };
        axios({
            method: "PUT",
            url: restURL,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            data: JSON.stringify(data),
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            this.setState({ loading: "none" });
            if (res.data.success) {
                 this.setState({ textColour:"rgba(38,185,154,.88)",showSuccess: "show", showError: "none", successMessage: "Responded with "+status, disableApproveBtn: true, });
                //  this.getApprovalDetails();
            } else if(res.data.error !== "undefined" && res.data.error !== "null" && res.data.error !== "" ) {
                if(res.data.error === "not found"){
                    this.getApprovalDetails();
                    this.setState({ showSuccess: "hide", showError: "inline-block", errorMessage: "Mail is already approved", disableApproveBtn: false });
                }else{
                    this.setState({ showSuccess: "hide", showError: "inline-block", errorMessage: res.data.error, disableApproveBtn: false });
                }
            } else {
                this.setState({ showSuccess: "hide", showError: "inline-block", errorMessage: "Failed to approve", disableApproveBtn: false });
            }
            this.setState({ loading: "hide" });
        }).catch(function (error) {
            console.log(error);
        });
    }

    handleKeyPress = (target) => {
        if (target.charCode === 32 && target.target.selectionStart === 0) {
            target.preventDefault();
        }
    }

    getColor=(name)=>{
        let negativeButtonsArray = ["remove","remov","reject","cancel","revoke","revok","wrong","not","no","deny","refuse","refus","ignore","ignor","dispute","disput","withdraw","avoid","dismiss","decline","declin","disapprove","disapprov","disallow","withhold","constrain","hold","restrict","obstruct","ban","delete","delet","block","blacklist"]
        let isInArray = negativeButtonsArray.filter(v => name.toLowerCase().indexOf(v) > -1);
        if (isInArray.length > 0) {
            return "danger";
        }else{
            return "info"
        }
    }

    changeUserName =(event)=>{
        let usernamevalue=event.target.value
        if(usernamevalue===""){
            this.setState({userNameErrorMsg:"Please enter approver name",userNameError:"show"})
        }else if(usernamevalue.length<3){
            this.setState({userNameErrorMsg:"Approver Name should have a minimum of three characters",userNameError:"show"})
        }else{
            this.setState({userNameErrorMsg:"",userNameError:"hide"})
        }
        this.setState({username:usernamevalue})
    }

    changeRemark =(event)=>{
        this.setState({remark:event.target.value})
    }

    render() {

        return (
            <Container>
                <LoadingSpinner showSpinner={this.state.loading} />
                <Col sm="12">
                    <Col sm="1">
                    </Col>
                    <Col sm="10" className="login_form">
                        <div className="approvalform" style={{ width: "100%" }}>
                            <img src={url+ "?t=" + this.state.timestamp} alt="logo" style={{ width: "30%", marginBottom: "15px",height:"auto" }} />
                            <div>
                              
                                    <div>
                                        <h3 style={{ color: "#cfd2da" }}>{this.state.subject}</h3>
                                        <h5 style={{ color: "#cfd2da" }}>{parse(this.state.message)}</h5>
                                    </div>
                               
                                <hr style={{ borderColor: "grey" }}></hr>
                                <div>

                              
                                {this.state.approvalStatus === "" && this.state.approvaloptions !=="" && this.state.showButton ===false ?
                                      <div style={{ paddingBottom: "10px",paddingLeft: "165px",paddingRight: "165px" }}>
                                      <FormGroup row>
                                      <Label for="email" style={{textAlign:"left"}} sm={4}>Approver Name *</Label>
                                          <Col sm={8}>
                                          <Input type="text"  value={this.state.username} className="approvalinputbox"   name="username" id="approvalusername" placeholder="Username" onKeyPress={this.handleKeyPress}  onChange={(e) => this.changeUserName(e)}/>
                                          <div id="usernameerror" className={"InputError errorMessage " + this.state.userNameError} >{this.state.userNameErrorMsg}</div>
                                          </Col>
                                      </FormGroup>
                                      <FormGroup row>
                                      <Label for="email" style={{textAlign:"left"}} sm={4}>Remark</Label>
                                          <Col sm={8}>
                                          <Input type="textarea" value={this.state.remark} className="approvalinputbox"  name="remark" id="approverRemark" placeholder="Remark" onKeyPress={this.handleKeyPress} onChange={(e) => this.changeRemark(e)} />
                                          </Col>
                                      </FormGroup>
                                    {this.state.approvaloptions.map((item, index) =>
                                        <Button color={this.getColor(item)} style={{ width: "150px" }} disabled={this.state.username.length < 3 ? true : this.state.disableApproveBtn} onClick={() => this.updateApprovalStatus(item)}>{item}</Button>
                                    )}</div>
                                     : ""}
                                </div>
                                <div className="alert alert-success"
                                    style={{ background: "transparent", border: "none", width: "100%", height: "40px", display: this.state.showError }}>
                                    <h6 style={{ color:"rgba(231,76,60,0.88)" }}>
                                        {this.state.errorMessage}
                                    </h6>
                                </div>
                                
                                {this.state.usernameValue!==""?
                                <div
                                    style={{ background: "transparent", border: "none", width: "100%", minHeight: "60px" }}>
                                    {/* <h6 style={{ color:"#e9ecef"}}>
                                        User Name:{this.state.usernameValue!==""?this.state.usernameValue:""}<br/>
                                        Remark:{this.state.remarkValue!==""?this.state.remarkValue:""}<br/>
                                    </h6> */}
                                     <table style={{ border: '1px solid grey' }}>
                                        <tbody>
                                            <tr>
                                                <th style={{ color: "#e9ecef",maxWidth:"120px",textAlign: "left",border: '1px solid #e9ecef' }}>Approver Email</th>
                                                <td style={{ textAlign: "left",border: '1px solid #e9ecef' }}>{this.state.approvalMailValue !== "" ? this.state.approvalMailValue : ""}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ width:"120px",textAlign: "left",border: '1px solid grey' }}>Approver Name</th>
                                                <td style={{ textAlign: "left",border: '1px solid grey' }}>{this.state.usernameValue !== "" ? this.state.usernameValue : ""}</td>
                                            </tr>
                                            {/* {this.state.remarkValue !== ""? */}
                                            <tr>
                                                <th style={{ width:"120px",textAlign: "left",border: '1px solid grey' }}>Remark</th>
                                                <td style={{ textAlign: "left",border: '1px solid grey' }}>{this.state.remarkValue !== "" ? this.state.remarkValue : ""}</td>
                                            </tr>
                                            {/* :""} */}
                                        </tbody>
                                    </table>
                                </div>:""}
                                

                                <div className={this.state.showSuccess + " alert alert-success"}
                                    style={{ background: "transparent", border: "none", width: "100%", height: "60px" }}>
                                    {this.state.successMessage1==="" ?
                                    <h6 style={{ color:this.state.textColour,marginTop: "9px" }}>
                                        {this.state.successMessage} <br/>
                                    </h6>
                                    :
                                    <h6 style={{ color:this.state.textColour,marginTop: "0px" }}>
                                        {this.state.successMessage} <br/>
                                        {this.state.successMessage1}
                                    </h6>
                                    }
                                </div>

                                <hr style={{ borderColor: "grey" }}></hr>

                            </div>
                        </div>
                    </Col>
                    <Col sm="1">
                    </Col>
                </Col>
            </Container>
        )
    }
}

const exportApproval = withRouter(connect()(Approval));
export default exportApproval;
