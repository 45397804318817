export default function caseManageReducer(state = {}, action) {
    switch (action.type) {
        case "getPlaybookListData":
            return { listdata: action.payload }

        case "getSLAListData":
            return { slalistdata: action.payload }

        case "getSeverityData":
            return { severitydata: action.payload }

        case "getEmailTemplate":
            return { etemplatedata: action.payload }

        case "getCaseLabels":
            return { caselabeldata: action.payload }

        case "getCategoryData":
            return { categorydata: action.payload }

        case "getCaseFieldsData":
            return { casefielddata: action.payload }

        case "getCategoryMappingData":
            return { mappingdata: action.payload }

        case "getRuleData":
            return { ruledata: action.payload }

        case "getCaseLabelsPre":
            return { labelsdata: action.payload }

        case "getPlaybookList":
            return { playlistdata: action.payload }

        case "getSaveSeverityData":
            return { savedata: action.payload }

        case "getAddNewCategoryData":
            return { newcategorydata: action.payload }

        case "getSaveMetaData":
            return { savemetadata: action.payload }  
            
        case "getSaveMailData":
            return { savemaildata: action.payload }  

        case "getNewPlaybookList":
            return { newplaydata: action.payload } 

        case "getUpdateSeverityData":
            return { updateseveritydata: action.payload } 

        case "getDeleteSeverityData":
            return { deleteseveritydata: action.payload } 

        case "getDeleteCategoryData":
            return { deletecategorydata: action.payload } 

        case "getEditCategoryData":
            return { editcategorydata: action.payload } 

        case "getUpdateMailData":
            return { updatemaildata: action.payload } 
            
        case "getDeleteMailData":
            return { deletemaildata: action.payload }
            
        case "getUpdatePlaybookList":
            return { updateplaydata: action.payload }

        case "getDeletePlaybookList":
            return { deleteplaydata: action.payload }

        case "getAddSLAList":
            return { addsladata: action.payload }

        case "getUpdateSLAList":
            return { updatesladata: action.payload }

        case "getDeleteSLAList":
            return { deletesladata: action.payload }
                    
        case "getUpdateMetaData":
            return { updatemetadata: action.payload }

        case "getDeleteMetaData":
            return { deletemetadata: action.payload }

        case "getSaveCategoryMapping":
            return { savemappingdata: action.payload }

        case "getUpdateCategoryMapping":
            return { updatemappingdata: action.payload }

        case "getDeleteCategoryMapping":
            return { deletemappingdata: action.payload }

        case "getSaveRuleData":
            return { saveruledata: action.payload }

        case "getUpdateRuleData":
            return { updateruledata: action.payload }

        case "getDeleteRuleData":
            return { deleteruledata: action.payload }

        case "getRunSaveRuleData":
            return { runsaveruledata: action.payload }
        }
        return state
    }