const divDefault = { "w": 10, "h": 10, "x": 0, "y": 0, "i": "default", "minW": 9, "minH": 8, "moved": false, "static": false, "isDraggable": true, "isResizable": true };

export default function resizeReducer(state = {
    layout: [{
        "w": 10, "h": 10, "x": 0, "y": 0, "i": "default", "minW": 9, "minH": 8, "moved": false, "static": false, "isDraggable": true, "isResizable": true
    }]
}, action) {
    switch (action.type) {
        case "getDefaultResizeData":
            return { ...state, layout: [...state.layout] }
            break;
        case "getResizeData":
            for (var i = 0; i < state.layout.length; i++) {
                if (state.layout[i].i === action.playload) {
                    return { layout: [state.layout[i]] }
                }
            }
            break;
        case "concatAndReturnResizeData":
            return { layout: [...action.playload] }
    }
    return state
}