import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import helpers from "../helpers";
import { withRouter } from 'react-router-dom';
import axios from "axios";
const restURL = helpers.restURL;

class Logout extends Component {

    constructor() {
        super();
        this.state = {
            logoPath:window.location.protocol + '//' + window.location.hostname + '/downloads/logo/logo.png',
            timestamp:Math.floor(100000000 + Math.random() * 900000000)
        };
        this.logoutUser = this.logoutUser.bind(this);
        this.redirectToLogin = this.redirectToLogin.bind(this);
    }

    componentDidMount() {
        document.body.style.setProperty("background-color", `#272b35`);
        var logoPath = window.location.protocol + '//' + window.location.hostname + '/downloads/logo/logo.png';
        this.setState({logoPath})
        this.logoutUser();
    }
    componentWillReceiveProps(newProps) {
        /*chandru changes -logo update reducer */
        if(typeof newProps.logoupdatedata !== 'undefined' && newProps.logoupdatedata !="" && newProps.logoupdatedata !== null){
           if(newProps.logoupdatedata !== this.props.logoupdatedata){
               this.setState({timestamp:new Date().getTime()})
           }
       }
   }

    logoutUser() {
        let userData = helpers.getSessionStorage("userData");

        if (userData === "" || userData === null || typeof userData === 'undefined') {
            this.redirectToLogin();
        } else {
            let data = JSON.parse(userData);
            let restData = {
                id: data.id,
                email: data.email,
                companycode: data.companycode,
                tenant_id: helpers.getCookie('tenantid'),
                tenantcode: helpers.getCookie('tenantcode'),
                useraccesstoken: helpers.getCookie('accesstoken'),
                tenantsinfo: data.tenantsinfo, //for mssp purpose
                rest: "logout",
                module: "user"
            };

            axios({
                method: "POST",
                url: restURL,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                data: JSON.stringify(restData),
                responseType: "json"
            }).then(res => {
                this.redirectToLogin();
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    redirectToLogin() {
        window.sessionStorage.removeItem("userData");
        window.sessionStorage.removeItem("casedetails");
        window.sessionStorage.removeItem("tenantcode");
        window.localStorage.removeItem("tacticsData");
        window.localStorage.removeItem("tasksData");
        window.localStorage.removeItem("selecteddashboard");
        window.localStorage.removeItem("currenttab");
        var cookies = document.cookie.split("; ");
        for (var i = 0; i < cookies.length; i++) {
            var cookieValues = cookies[i].split("=");
            helpers.deleteCookie(cookieValues[0]);
        }
        this.props.history.push("/login");
    }

    render() {
      
        return (<div>
            <img src={this.state.logoPath+"?t="+this.state.timestamp} width="55%" height="300px" style={{ padding: "13% 5% 0% 5%" }} />
            <h4 style={{ color: "#dee2e6b0" }}>Logging out. Please wait ...</h4>
        </div>)
    }
}

function mapStateToProps(state) {
    return {

        logoupdatedata:state.reducer.logoupdatedata
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

const exportLogout = withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
export default exportLogout;
