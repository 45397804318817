export function updateMycase(data) {
    if(data != false){
        data = new Date().getTime();
    }
    
    return {
        type: "updatemycase",
        payload: data
    }
}
