import React, { Component } from 'react';
import { connect } from "react-redux";
import helpers from "../helpers";
import { withRouter } from 'react-router-dom';
import { Container, FormGroup, Input, Col, Button, Form, InputGroup, InputGroupAddon } from "reactstrap";
import axios from "axios";
var md5 = require("md5");
const restURL = helpers.restURL;
let url = window.location.origin;
url = url === "http://localhost:3000" ? "http://localhost" : url;
url = url + "/downloads/logo/logo.png";
url = url.toString();

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            showLoading: "none",
            showSuccessvalidation: "none",
            showError: "none",
            btnText: "Reset",
            passwordFieldType: "password",
            confirmPasswordFieldType: "password",
            disableResetBtn: false,
            activationtoken: "",
            showSuccess: "hide",
            timestamp:new Date().getTime()
        };
        this.validateForm = this.validateForm.bind(this);
        this.checkTokenExpiry = this.checkTokenExpiry.bind(this);
    }

    componentWillMount() {
        document.body.style.setProperty("background", `#272b35`);
    }

    componentDidMount() {
        this.checkTokenExpiry();
    }

    checkTokenExpiry() {
        var token_value = '';
        var url = window.location.href;
        if (url.indexOf('=') > 0) {
            token_value = url.substring(url.indexOf('=') + 1);
        }
        this.setState({ activationtoken: token_value });

        var url = helpers.restURL + '?module=user&rest=useractivationstatus&q=' + token_value + '/';
        axios({
            method: "GET",
            url: url,
            headers: {
                access_token: "",
                jwt_token: "",
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                let createddate = parseInt(res.data.data.updateddate);
                var currentDate = new Date();
                var unix_timestamp = createddate;
                var createdDate = new Date(unix_timestamp);
                var milliseconds = currentDate.getTime() - createdDate.getTime();
                var differenceHours = Math.floor(milliseconds / (60 * 60 * 1000));
                if (differenceHours > 48) {
                    this.setState({
                        disableResetBtn: true, showError: "inline-block",
                        invalidCredentials: "Password reset token expired! Please click on forgot password to get new activation token"
                    })
                } else {
                    this.setState({ disableResetBtn: false, showError: "none", invalidCredentials: "" });
                }
            } else {
                this.setState({ disableResetBtn: true, showError: "inline-block", invalidCredentials: "Invalid token" });
                setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 5000);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    validateForm() {

        let password = this.state.password;
        let confirmPassword = this.state.confirmPassword;
        if (password === "") {
            this.setState({ showError: "inline-block", invalidCredentials: "Password is required" });
            setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 3000);
            return
        }

        var regularExpression = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
        if (!regularExpression.test(password)) {
            this.setState({ showError: "inline-block", invalidCredentials: "Password should have a minimum of eight characters and include at least one uppercase, one lowercase, one special character, and one number." });
            setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 5000);
            return
        }

        if (confirmPassword === "") {
            this.setState({ showError: "inline-block", invalidCredentials: "Confirm Password, Password is required" });
            setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 3000);
            return
        }

        if (password !== confirmPassword) {
            this.setState({ showError: "inline-block", invalidCredentials: "Please confirm password" });
            setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 3000);
            return
        }

        this.setState({ showLoading: "inline-block", showError: "none", invalidCredentials: "" });

        var data = {
            password: md5(password),
            activationtoken: this.state.activationtoken.trim(),
            rest: 'resetpassword',
            module: 'users'
        };

        axios({
            method: "POST",
            url: restURL,
            headers: {
                access_token: "",
                jwt_token: "",
                "Content-Type": "application/json; charset=utf-8"
            },
            data: data,
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            this.setState({ showLoading: "none" });
            if (res.data.success) {
                this.setState({ showError: "none", showSuccessvalidation: "inline-block", btnText: "Redirecting...", invalidCredentials: "", showSuccess: "show" });
                setTimeout(() => {
                    this.props.history.push("/login");
                }, 2000);
            } else {
                this.setState({ showError: "inline-block", invalidCredentials: "Failed to reset your password" });
                setTimeout(() => this.setState({ showError: "none", invalidCredentials: "" }), 3000);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    render() {
        return (
            <Container>
                <Col sm="12">
                    <Col sm="3">
                    </Col>
                    <Col sm="6" className="login_form">
                        <div className={this.state.showError === "none" ? "license-form" : "errorInputForm license-form"} style={{ width: "90%" }}>
                            <img src={url+ "?t=" + this.state.timestamp} alt="logo" style={{ width: "100%", marginBottom: "15px",height:"auto" }} />
                            <div>
                            {/* Author - Gayathri Changed the color of text - Sep-11 */}
                                <h5 style={{ color: "#09897e" }}><i className="fa fa-key"></i> Reset your password</h5>
                                <InputGroup>
                                    <Input style={{ background: "#343a40", height: "35px", borderColor: "#f8f9fa8c", color: "#cfd2da" }} placeholder="Password" type={this.state.passwordFieldType} name="password" id="password" value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} />
                                    <InputGroupAddon addonType="append">
                                        <Button color="secondary" className="task-input-field-button password-btn-dark"
                                            onMouseDown={() => this.setState({ passwordFieldType: "text" })}
                                            onMouseUp={() => this.setState({ passwordFieldType: "password" })}
                                        >
                                            <i className="fa fa-eye"></i>
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>

                                <InputGroup>
                                    <Input style={{ background: "#343a40", height: "35px", borderColor: "#f8f9fa8c", color: "#cfd2da" }} placeholder="Confirm Password" type={this.state.confirmPasswordFieldType} name="confirmPassword" id="confirmPassword" value={this.state.confirmPassword} onChange={(event) => this.setState({ confirmPassword: event.target.value })} />
                                    <InputGroupAddon addonType="append">
                                        <Button color="secondary" className="task-input-field-button password-btn-dark"
                                            onMouseDown={() => this.setState({ confirmPasswordFieldType: "text" })}
                                            onMouseUp={() => this.setState({ confirmPasswordFieldType: "password" })}
                                        >
                                            <i className="fa fa-eye"></i>
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>

                                <FormGroup>
                                    <Button color="success" style={{ width: "150px" }} disabled={this.state.disableResetBtn} onClick={this.validateForm}>{this.state.btnText}&nbsp;
                                        <i style={{ display: this.state.showLoading }} className="fa fa-circle-o-notch fa-spin"></i>
                                        <i style={{ display: this.state.showSuccessvalidation }} className="fa fa-check"></i>
                                    </Button>
                                </FormGroup>

                                <FormGroup>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                        <div className="alert alert-danger"
                                            style={{ background: "rgba(231,76,60,0.88)", border: "none", width: "100%", display: this.state.showError }}>
                                            <h6 style={{ color: "white" }}>
                                                {this.state.invalidCredentials}
                                            </h6>
                                        </div>
                                        <div className={this.state.showSuccess + " alert alert-success"}
                                            style={{ background: "#20c997", border: "none", width: "100%", height: "50px" }}>
                                            <h6 style={{ color: "white",marginTop : "5px"}}>
                                                Password reset successful !
                                            </h6>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </Col>
                    <Col sm="3">
                    </Col>
                </Col>
            </Container>
        )
    }
}

const exportForgotPassword = withRouter(connect()(ForgotPassword));
export default exportForgotPassword;
