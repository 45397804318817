import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

export class NotificationComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showNotificationModal: false,
            notificationMessage: ""
        }
    }

    componentDidMount() {
        let { showNotificationModal, notificationMessage } = this.props;
        this.setState({ showNotificationModal, notificationMessage });
    }

    componentWillReceiveProps(nextProps) {
        let { showNotificationModal, notificationMessage } = nextProps;
        this.setState({ showNotificationModal, notificationMessage });
    }

    render() {


        let modalStyle = { color: "" };

        if (typeof this.props.theme !== 'undefined') {
            modalStyle = this.props.theme.modal;
        }

        const fontColor = this.props.theme ? this.props.theme.color : "#2A3139";

        return (
            <Modal fade={false} isOpen={this.state.showNotificationModal} backdrop="static">
                <ModalHeader style={modalStyle}><span style={{ color: fontColor, fontSize: "20px" }}>Message</span></ModalHeader>
                <ModalBody style={modalStyle}>
                    <h6 style={{ color: fontColor }}>{this.state.notificationMessage}</h6>
                    <div className="pull-right" style={{ marginTop: "20px" }}>
                        <Button size="sm" color="secondary" id="NotificationCloseBtn" onClick={() => this.props.toggleNotificationModal(false)}>Close</Button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    theme: state.reducer.theme.theme,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationComponent)
