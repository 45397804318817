import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import LoadingSpinner from './LoadingSpinner';
// import Menu from "./Menu";
import helpers from "../helpers";
import axios from "axios";
import { getDefaultTheme, lightTheme, darkTheme } from "../actions/themeAction";
import GoogleMap from "./GoogleMap";
import { Col, Row, Collapse } from "reactstrap";
import TimeLineChart from "./TimeLineChart";
import { Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import "../sass/style.css";
import IframeComm from 'react-iframe-comm';
import GaugeChart from "./GaugeChart";

const options = {
    page: 1,
    sizePerPage: 10,
    pageStartIndex: 1,
    paginationSize: 3,
    prePage: '<<',
    nextPage: '>>',
    firstPage: 'First',
    lastPage: 'Last',
    paginationPosition: 'bottom',
    hidePageListOnlyOnePage: true,
    hideSizePerPage: true,
    searchPosition: 'left',
};

class TISearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: "hide",
            searchString: "",
            searchData: {},
            cifCollapse: false,
            showSlideBar: "hide",
            nodataresponse: ""
        };
        this.getTiSearchData = this.getTiSearchData.bind(this);
        this.buildLocationData = this.buildLocationData.bind(this);
        this.buildNVDResponse = this.buildNVDResponse.bind(this);
        this.buildCIFRiskTimeLineResponse = this.buildCIFRiskTimeLineResponse.bind(this);
        this.cifToggle = this.cifToggle.bind(this);
        this.commonFormatter = this.commonFormatter.bind(this);
        this.getGaugeChart = this.getGaugeChart.bind(this);
        this.buildDomainReportResponse = this.buildDomainReportResponse.bind(this);
        this.buildGoogleResponse = this.buildGoogleResponse.bind(this);
        this.providerFormatter = this.providerFormatter.bind(this);
        this.feedsFormatter = this.feedsFormatter.bind(this);
        this.buildSTIXData = this.buildSTIXData.bind(this);
    }

    componentDidMount() {
        let tisearchstring = this.props.tisearchdata;
        if (typeof this.props.tisearchdata !== 'undefined') {
            this.setState({ searchString: this.props.tisearchdata, loading: "show" });
            this.getTiSearchData(this.props.tisearchdata);
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.tisearchdata !== this.props.tisearchdata) {
            this.setState({ searchString: newProps.tisearchdata, loading: "show" });
            this.getTiSearchData(newProps.tisearchdata);
        }
    }

    getTiSearchData(searchString) {
        let data = {
            query: searchString,
            rest: 'tisearch',
            module: 'user'
        };

        axios({
            method: "POST",
            url: helpers.restURL,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            data: data,
            responseType: "json"
        }).then(res => {
            this.setState({ searchData: res.data.data });
            helpers.checkSessionTimeout(res.data.sessionexpired);
            this.setState({ loading: "hide" });
            if (res.data.displaymessage !== "" && res.data.displaymessage !== null && typeof res.data.displaymessage !== undefined) {
                this.setState({ nodataresponse: res.data.displaymessage });
            }
        }).catch(function (error) {
            console.log(error);
            //this.setState({ loading: "hide" });
        });
    }


    buildLocationData(locationData) {
        var city = locationData.data.city;
        var region = locationData.data.region;
        var country = locationData.data.country_name;
        var postal_code = locationData.data.postal;
        var latitude = locationData.data.latitude;
        var longitude = locationData.data.longitude;
        var time_zone = locationData.data.timezone;

        let mapProps = { lat: latitude, long: longitude };

        return (
            <Col sm="12" key="buildLocationData">
                <Col key="1" sm="12" style={{ borderBottom: "1px solid grey", marginBottom: "10px" }}>
                    <h5 style={{ color: this.props.theme ? this.props.theme.color : "#2A3139", float: "left" }}>Location Details</h5>
                </Col>
                <Col key="2" sm="12">
                    <Row>
                        <Col sm="6" key="21">
                            <table style={{ marginBottom: "15px !important", border: "1px solid #575e6538" }} className="task-input-table table-hover table-striped">
                                <tbody>
                                    <tr key="1">
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>City</td>
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>{city}</td>
                                    </tr>
                                    <tr key="2">
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>Region</td>
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>{region}</td>
                                    </tr>
                                    <tr key="3">
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>Country</td>
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>{country}</td>
                                    </tr>
                                    <tr key="4">
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>Postal Code</td>
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>{postal_code}</td>
                                    </tr>
                                    <tr key="5">
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>Latitude</td>
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>{latitude}</td>
                                    </tr>
                                    <tr key="6">
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>Longitude</td>
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>{longitude}</td>
                                    </tr>
                                    <tr key="7">
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>Time Zone</td>
                                        <td className="task-header-font" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>{time_zone}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col sm="6" key="22">
                            <GoogleMap mapProps={mapProps} />
                        </Col>
                    </Row>
                </Col>
            </Col>
        );
    }


    buildNVDResponse(searchData) {
        if (searchData.nvd_data !== null && typeof searchData.nvd_data !== 'undefined') {
            var nvd_list = searchData.nvd_data.data;

            return (
                <Col sm="6" key="parentNVDColumn">
                    <Col key="1" sm="12" style={{ borderBottom: "1px solid grey", marginBottom: "10px" }}>
                        <h5 style={{ color: this.props.theme ? this.props.theme.color : "#2A3139", float: "left" }}>NVD Data</h5>
                    </Col>
                    <Col sm="12" key="buildNVDResponse">
                        {nvd_list.map((data, i) => (
                            <div className="nvd_data_row" key={i} id={data.id} style={{ cursor: "pointer" }}>
                                <h5 style={{ fontWeight: "bold", float: "left" }}>{data.id}</h5>
                                <br />
                                <p style={{ float: "left" }}>{data.description.substring(0, 100)}....</p>
                                <br />
                            </div>
                        ))}
                    </Col>
                </Col>
            )
        } else {
            return (<div></div>)
        }
    }

    getGaugeChart(searchData) {

        var total_hits;
        var providers_count;
        if (typeof searchData.cif_avg_risk == 'undefined') {
            total_hits = 0;
        } else {
            total_hits = searchData.cif_avg_risk.data.hits.total;
        }

        if (typeof searchData.cif_data == 'undefined') {
            providers_count = 0;
        } else if (searchData.cif_data.data.aggregations.providers.buckets != null && typeof searchData.cif_data.data.aggregations.providers.buckets != 'undefined') {
            providers_count = searchData.cif_data.data.aggregations.providers.buckets.length;
        } else {
            providers_count = 0;
        }

        var confidencevalue = 0;
        if (typeof searchData.cif_avg_risk !== 'undefined') {
            confidencevalue = searchData.cif_avg_risk.data.aggregations.avg_confidence.value;
        }

        // let avgValue = (confidencevalue / 100) * 10;

        return (
            <div>
                <div style={{ width: "90%", paddingLeft: "15%", height: "160px", marginTop: "-30px" }} >
                    <GaugeChart confidencevalue={confidencevalue} />
                </div>
                {/* <GaugeChart id="searchGauge" nrOfLevels={20} percent={avgValue} textColor={this.props.theme ? this.props.theme.color : "#2A3139"} /> */}
                <span className="count_bottom" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>Out of {total_hits} Hits </span>
                <div className="count_bottom" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>{providers_count} / 21 Providers </div>
            </div>
        );
    }

    cifToggle() {
        this.setState(state => ({ cifCollapse: !state.cifCollapse }));
    }

    commonFormatter(cell, row) {
        return (<div style={{ width: "300px", color: this.props.theme ? this.props.theme.color : "#2A3139" }} className="custom_td" title={cell}>{cell}</div>);
    }

    buildCIFRiskTimeLineResponse(searchData) {

        let tableData = [];
        let cif_es_data = searchData.cif_data.data.hits.hits;

        for (var i = 0; i < cif_es_data.length; i++) {
            var display_time = '';
            var date_array = cif_es_data[i]._source.reporttime.split("T");
            var time_array = date_array[1].split(".");
            display_time = date_array[0] + ' ' + time_array[0];

            let datObj = {
                indicator: cif_es_data[i]._source.indicator,
                itype: cif_es_data[i]._source.itype,
                provider: cif_es_data[i]._source.provider,
                tags: cif_es_data[i]._source.tags,
                confidence: cif_es_data[i]._source.confidence,
                tlp: cif_es_data[i]._source.tlp,
                reporttime: display_time
            }
            tableData.push(datObj);
        }

        return (
            <Col sm="12" key="buildCIFRiskTimeLineResponse" >
                <Row>
                    <Col key="1" sm="12" style={{ borderBottom: "1px solid grey", marginBottom: "10px" }}>
                        <h5 style={{ color: this.props.theme ? this.props.theme.color : "#2A3139", float: "left" }}>Risk Score History</h5>
                        <Button style={{ float: "right" }} size="sm" onClick={this.cifToggle}>More</Button>
                    </Col>
                    <Col key="2" sm="9" style={{ height: "250px", width: "100%" }}>
                        <TimeLineChart chartData={searchData.cif_data.data.hits.hits} />
                    </Col>
                    <Col key="3" sm="3" className="gauge-chart2">
                        <h6 style={{ marginBottom: "10px", color: this.props.theme ? this.props.theme.color : "#2A3139" }}>Average Risk</h6>
                        {this.getGaugeChart(searchData)}
                    </Col>
                    <Col key="4" sm="12">
                        <Collapse isOpen={this.state.cifCollapse} style={{ borderTop: "1px solid grey" }}>
                            <br></br>
                            <BootstrapTable data={tableData} trStyle={{ fontSize: "13px" }} bodyStyle={{ overflow: 'auto' }} striped={false} hover={true} trClassName='table-format' pagination={true} options={options} search>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="indicator" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} isKey={true} dataSort={true} dataFormat={this.commonFormatter}>Indicator</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="itype" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataFormat={this.commonFormatter} dataSort={true} >Itype</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="provider" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataFormat={this.commonFormatter} dataSort={true} >Provider</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="tags" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataFormat={this.commonFormatter} dataSort={true} >Tags</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="confidence" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataFormat={this.commonFormatter} dataSort={true} >Confidence</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="tlp" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataFormat={this.commonFormatter} dataSort={true} >Tlp</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="reporttime" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataFormat={this.commonFormatter} dataSort={true} >Report Time</TableHeaderColumn>
                            </BootstrapTable>
                        </Collapse>
                    </Col>
                </Row>
            </Col>
        );
    }

    buildDomainReportResponse(searchData) {

        var vt_scan_report = searchData.domain_report.data.ScanResult;
        var positives = searchData.vt_url_report.data.positives;
        var total = searchData.vt_url_report.data.total;
        var scan_result = positives + '/' + total;
        var vt_scan_date = searchData.domain_report.data.ScanDate;
        var domain_age = searchData.domain_report.data.DomainAge;
        var registrar = searchData.domain_report.data.Registrar;
        var ip_address = searchData.domain_report.data.IPAddress;
        var country = searchData.domain_report.data.Country.toUpperCase();

        if (domain_age === -1 || domain_age === 0) {
            domain_age = "Unknown";
        } else {
            domain_age = domain_age + ' Days';
        }

        let tableData = [];
        if (searchData.vt_url_report !== null && typeof searchData.vt_url_report !== 'undefined') {
            Object.entries(searchData.vt_url_report.data.scans).map(([key, value]) => {
                let datObj = {
                    provider: key,
                    result: value.result
                };
                tableData.push(datObj);
            });
        }

        let divStyle = { color: "" };
        if (typeof this.props.theme !== 'undefined') {
            divStyle = this.props.theme.div;
        }
        return (
            <Col sm="12" key="buildDomainReportResponse">
                <Row className="tile_count">
                    <Col key="1" sm="3" md="3" lg="3" xs="3" className="tile_stats_count">
                        <span className="count_top">
                            <i className="fa fa-clock-o" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}> Domain Age</i><br />
                        </span>
                        <span className="count">
                            <i style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}> {domain_age}</i><br />
                        </span>
                        <span className="count_bottom">
                            <i style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}> Registrar Name : {registrar}</i><br />
                        </span>
                    </Col>
                    <Col key="2" sm="3" md="3" lg="3" xs="3" className="tile_stats_count">
                        <span className="count_top">
                            <i className="fa fa-laptop" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}> Serving IP Address</i><br />
                        </span>
                        <span className="count">
                            <i style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}> {ip_address}</i><br />
                        </span>
                        <span className="count_bottom">
                            <i style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}> Country : {country}</i><br />
                        </span>
                    </Col>
                    {searchData.vt_url_report.success ?
                        <Col key="3" sm="3" md="3" lg="3" xs="3" className="tile_stats_count">
                            <span className="count_top" >
                                <i className="fa fa-bug" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}> Virustotal URL scan report</i><br />
                            </span>
                            <div className="count" id="vt_click" style={{ cursor: "pointer" }} onClick={() => this.setState({ showSlideBar: "show" })}>
                                {positives > 0 ?
                                    <span className="count" id="positives" style={{ color: "#e74c3c" }}>
                                        {positives}
                                    </span>
                                    : <span className="count" id="positives" >
                                        {positives}
                                    </span>}
                                <span className="count" id="total" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>/
                                    {total}
                                </span>
                            </div>
                            <span className="count_bottom" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>
                                Last Scan {vt_scan_date}
                            </span>
                        </Col>
                        : ""}
                    <Col key="4" sm="3" md="3" lg="3" xs="3">
                        <div id="vt_report_sidebar" className={this.state.showSlideBar} >
                            <div id="dashboard_sidebar_contents" className="zsidenav" style={divStyle} >
                                <h3 style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>{this.state.sliderHeader}</h3>
                                <div className="closebtn" style={{ cursor: "pointer" }} title="Close" onClick={() => this.setState({ showSlideBar: "hide" })}><i className="fa fa-remove"></i></div>

                                <h5 style={{ color: this.props.theme ? this.props.theme.color : "#2A3139", textAlign: "left" }}>Provider Wise</h5>
                                <hr style={{ background: this.props.theme ? this.props.theme.color : "#2A3139" }}></hr>
                                <BootstrapTable data={tableData} trStyle={{ fontSize: "13px" }} bodyStyle={{ overflow: 'auto' }} striped={false} hover={true} trClassName='table-format' pagination={true} options={options} search>
                                    <TableHeaderColumn dataAlign='left' width='180' dataField="provider" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} isKey={true} dataSort={true} dataFormat={this.providerFormatter}>Provider</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign='left' width="100" dataField="result" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataFormat={this.providerFormatter} dataSort={false} >Result</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div >
                    </Col>
                </Row>
            </Col>
        )
    }

    buildGoogleResponse(searchData) {
        var aggr_data = searchData.google_search_data.data.items;
        return (
            <Col key="1" sm="6">
                <Row>
                    <Col key="2" sm="12" className="x_title">
                        <h5 style={{ color: this.props.theme ? this.props.theme.color : "#2A3139", float: "left" }}>Google Results</h5>
                    </Col>
                </Row>
                <ul className="list-unstyled custom_scroll_view">
                    {aggr_data.map(data => (
                        <li key={data.link} className="media event" style={{ textAlign: "left", padding: "10px 0px" }}>
                            <div id="un_div">
                                <a className="title" href={data.link} target="_blank" style={{ color: "blue" }}>{data.htmlTitle}</a><br />
                                <a className="title" href={data.link} target="_blank" style={{ color: "green" }}>{data.link}</a><br />
                                <a className="title" href="#" target="_blank" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>{data.snippet}</a><br />
                            </div>
                            <br />
                            <br />
                            <br />
                        </li>
                    ))}
                </ul>
            </Col>
        )
    }


    buildTwitterResponse(searchData) {
        var aggr_data = searchData.TwitterData.data;
        return (
            <Col sm="6">
                <Col key="1" sm="12" style={{ borderBottom: "1px solid grey", marginBottom: "10px" }}>
                    <h5 style={{ color: this.props.theme ? this.props.theme.color : "#2A3139", float: "left" }}>Twitter Results</h5>
                </Col>
                <Col key="2" sm="12" style={{ float: "left", textAlign: "left" }}>
                    <ul className="list-unstyled custom_scroll_view">
                        {aggr_data.map((data, i) => (
                            <li key={i} className="media event">
                                <div id="img_div" style={{ height: "40px", width: "40px", marginRight: "10px" }}>
                                    <img alt="" className="media-object img-rounded" src={data.tweeteduserprofileimg} style={{ height: "40px", width: "40px" }}>
                                    </img>
                                </div>
                                <div id="un_div">
                                    <a className="media-heading" href="#" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}><strong>{data.tweetedusername}</strong></a><br />
                                    <small>{data.created_at}</small><br />
                                    <div id="data">
                                        <p className="hash-tags" style={{ marginLeft: "0px", color: this.props.theme ? this.props.theme.color : "#2A3139" }}>
                                            <strong>{data.hashtags}</strong><br />
                                        </p>
                                        <p className="media-body-custom color" style={{ marginLeft: "0px", color: this.props.theme ? this.props.theme.color : "#2A3139" }}>{data.full_text}
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <br />
                            </li>
                        ))}
                    </ul>
                </Col>
            </Col>
        )
    }

    providerFormatter(cell, row) {
        return (<div className="custom_td" style={{ width: "200px", color: this.props.theme ? this.props.theme.color : "#2A3139" }} title={cell}>{cell}</div>);
    }

    feedsFormatter(cell, row) {
        return (<div className="custom_td" style={{ width: "200px", color: this.props.theme ? this.props.theme.color : "#2A3139" }} title={cell.value}>{cell.value}</div>);
    }

    buildSTIXData(searchData) {

        // for local
        //const url = "http://localhost:3000/stix.html?stixData=" + JSON.stringify(searchData.stix_data.data[0]);
        // for dev
        const url = "stix.html?stixData=" + JSON.stringify(searchData.stix_data.data[0]) + "&theme=" + this.props.theme.theme;
        const attributes = {
            src: url,
            width: "1200px",
            height: "500px",
            frameBorder: 0,
        };
        return (
            <IframeComm attributes={attributes} handleReady={this.onReady} />
        )
    }

    render() {
        let tiSearchDataHTML = [];
        if (typeof this.state.searchData != 'undefined' && this.state.searchData != null && this.state.searchData != "") {
            if (Object.keys(this.state.searchData).length > 0) {

                if (this.state.searchData.alexa_rank.success) {
                   
                }
                if (this.state.searchData.cif_avg_risk.success) {
                    
                }
                if (this.state.searchData.cif_data.success) {
                    
                }
                if (this.state.searchData.domain_report.success) {
                    tiSearchDataHTML = tiSearchDataHTML.concat(this.buildDomainReportResponse(this.state.searchData));
                }

                if (this.state.searchData.cif_risk_time_line.success) {
                    tiSearchDataHTML = tiSearchDataHTML.concat(this.buildCIFRiskTimeLineResponse(this.state.searchData));
                }

                if (this.state.searchData.location_data.success) {
                    tiSearchDataHTML = tiSearchDataHTML.concat(this.buildLocationData(this.state.searchData.location_data));
                }

                if (this.state.searchData.google_search_data.success) {
                    tiSearchDataHTML = tiSearchDataHTML.concat(this.buildGoogleResponse(this.state.searchData))
                }

                if (this.state.searchData.TwitterData.success) {
                    tiSearchDataHTML = tiSearchDataHTML.concat(this.buildTwitterResponse(this.state.searchData))
                }

                if (this.state.searchData.nvd_data.success) {
                    tiSearchDataHTML = tiSearchDataHTML.concat(this.buildNVDResponse(this.state.searchData));
                }
                if (this.state.searchData.stix_data.success) {
                    tiSearchDataHTML = tiSearchDataHTML.concat(this.buildSTIXData(this.state.searchData));
                }
                if (this.state.searchData.vt_file_report.success) {

                }
                if (this.state.searchData.vt_ip_report.success) {

                }
                if (this.state.searchData.vt_url_report.success) {

                }
            }
        } else {
            var messageToDisplay = "";
            if (this.state.nodataresponse !== "" && this.state.nodataresponse !== null && typeof this.state.nodataresponse !== 'undefined') {
                messageToDisplay = this.state.nodataresponse
            } else {
                messageToDisplay = "Error in Reading Search Results"
            }
            tiSearchDataHTML = tiSearchDataHTML.concat(messageToDisplay);
        }

        let divStyle = { color: "" };
        if (typeof this.props.theme !== 'undefined') {
            divStyle = this.props.theme.div;
        }

        return (
            <div>
                <LoadingSpinner showSpinner={this.state.loading} />
                {/* <Menu /> */}
                <Row style={{ padding: "10px" }}>
                    <Col sm="12" style={divStyle} className="x_panel margin-top-50">
                        <div style={{ fontSize: "24px", fontWeight: "400", textAlign: "left", color: "#1abb9c" }}>Search results for - {this.state.searchString}</div>
                        {tiSearchDataHTML}
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme,
        tisearchdata: state.reducer.tisearchdata.tisearchdata
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getDefaultTheme: getDefaultTheme, lightTheme: lightTheme, darkTheme: darkTheme }, dispatch)
}

const exportTISerach = withRouter(connect(mapStateToProps, mapDispatchToProps)(TISearch));
export default exportTISerach;
