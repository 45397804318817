import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Moment from 'react-moment';
import dateFormat from 'dateformat';
import "../sass/style.css";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import helpers from "../helpers";
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';
import { Button, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, Col, Row } from "reactstrap";
import Loader from "./Loader";

const restURL = helpers.restURL;

class Tenants extends Component {
    constructor() {
        super();
        this.state = {
            startDate: new Date(),
            showAddTenantModal: false,
            showEditTenantModal: false,
            showDeleteCategoryModal: false,
            Data: [],
            newCategoryId: "",
            newCategoryName: "",
            newCategoryWeeks: "",
            newCategoryDays: "",
            newCategoryHours: "",
            industryArr: [],
            firstNameErr: "",
            loading: "hide",
            name: '',
            newTenantHostDetails: { name: "" },
            // newTenantAppDetails : { name: "applicationDetails.xlsx", lastModified: 1565774596481, webkitRelativePath: "", size: 5915, type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
            newTenantAppDetails: { name: "" },
            host: "No",
            //togglestate: false,
            showAddNewTenantButton: true,
            showTenantBackupModal: false,
            showTenantRestoreModal: false,
            backupValid: false,
            restoreValid: false,
            selectedBackupOption: "",
            selectedRestoreOption: "",
            currentTenantCode: "",
            dbRestoreTenantCode: "",
            sameNameFound: false,
            newEditTenantName: "",
            selectedTenantHostType: "",
            disabledupdate:false,
            tableLoader: "",
            tenantCreation:false
        }

        this.commonFormatter = this.commonFormatter.bind(this);
        this.statusFormatter = this.statusFormatter.bind(this);
        this.getTenantData = this.getTenantData.bind(this);
        this.checkForErrors = this.checkForErrors.bind(this);
        this.getindustry = this.getindustry.bind(this);
        this.saveIndustry = this.saveIndustry.bind(this);
        this.showAddTenantModal = this.showAddTenantModal.bind(this);
        this.closeAddTenantModal = this.closeAddTenantModal.bind(this);
        this.addNewTenant = this.addNewTenant.bind(this);
        this.setTenantData = this.setTenantData.bind(this);
        // this.handleTenantName = this.handleTenantName.bind(this);
        this.handleTenantDescription = this.handleTenantDescription.bind(this);
        this.handleTenantIndustry = this.handleTenantIndustry.bind(this);
        this.handleTenantHostDetails = this.handleTenantHostDetails.bind(this);
        this.handleTenantAppDetails = this.handleTenantAppDetails.bind(this);
        this.handleTenantExpiryDate = this.handleTenantExpiryDate.bind(this);
        this.showEditTenantModal = this.showEditTenantModal.bind(this);
        this.handleEditTenantName = this.handleEditTenantName.bind(this);
        this.handleEditTenantDescription = this.handleEditTenantDescription.bind(this);
        this.handleEditTenantIndustry = this.handleEditTenantIndustry.bind(this);
        this.handleEditTenantHostDetails = this.handleEditTenantHostDetails.bind(this);
        this.handleEditTenantAppDetails = this.handleEditTenantAppDetails.bind(this);
        this.handleEditTenantExpiryDate = this.handleEditTenantExpiryDate.bind(this);
        this.updateTenant = this.updateTenant.bind(this);
        this.downloadHostFile = this.downloadHostFile.bind(this);
        this.downloadAppFile = this.downloadAppFile.bind(this);
        this.closeEditTenantModal = this.closeEditTenantModal.bind(this);
        this.showDeleteCategoryModal = this.showDeleteCategoryModal.bind(this);
        this.closeDeleteTenantModal = this.closeDeleteTenantModal.bind(this);
        this.deleteTenant = this.deleteTenant.bind(this);
        this.buttonFormatter = this.buttonFormatter.bind(this);
        this.expiryTimeFormatter = this.expiryTimeFormatter.bind(this);
        this.notificationMessage = this.notificationMessage.bind(this);
        this.downloadtenant = this.downloadtenant.bind(this);
        this.redirectErrorMessage = this.redirectErrorMessage.bind(this);
        this.validateform = this.validateform.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //this.changeToggle = this.changeToggle.bind(this);
        // this.addNewTenant1 = this.addNewTenant1.bind(this);
        this.showTenantBackupModal = this.showTenantBackupModal.bind(this);
        this.showTenantRestoreModal = this.showTenantRestoreModal.bind(this);
        this.handleBackupChangeSelect = this.handleBackupChangeSelect.bind(this);
        this.handleRestoreChangeSelect = this.handleRestoreChangeSelect.bind(this);
        this.closeTenantBackupModal = this.closeTenantBackupModal.bind(this);
        this.closeTenantRestoreModal = this.closeTenantRestoreModal.bind(this);
        this.doMSSPTenantBackup = this.doMSSPTenantBackup.bind(this);
        this.getBackup = this.getBackup.bind(this);
        this.doRestore = this.doRestore.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.handleTenantHostType = this.handleTenantHostType.bind(this);
    }

    componentDidMount() {
        this.getTenantData();
        this.getindustry();
        this.setState({tableLoader: <div className="loaderListContents"><Loader.tableLoaderN /></div>})
    }

    commonFormatter(cell, row) {
        return (<div style={{ width: "300px", color: this.props.theme ? this.props.theme.color : "#2A3139" }} className="custom_td" title={cell}>{cell}</div>);
    }

    statusFormatter(cell, row) {
        if (typeof cell !== 'undefined') {
            return (<div style={{ width: "300px", color: this.props.theme ? this.props.theme.color : "#2A3139" }} className="custom_td" title={cell.charAt(0).toUpperCase() + cell.slice(1)}>{cell.charAt(0).toUpperCase() + cell.slice(1)}</div>);
        } else {
            return ("");
        }
    }

    notificationMessage(text) {
        helpers.securaaNotification(text,"success",10)
        // toast.success(text, {
        //     position: "top-right",
        //     autoClose: 2000,
        //     closeOnClick: true,
        //     hideProgressBar: true,
        //     pauseOnHover: false,
        //     draggable: false,
        //     id : "TenantsNotificationMsg"
        //     // transition: "flip"
        // });
    }

    redirectErrorMessage(text) {
        helpers.securaaNotification(text,"error",10)
        // toast.error(text, {
        //     position: "top-right",
        //     autoClose: 2000,
        //     closeOnClick: true,
        //     hideProgressBar: true,
        //     pauseOnHover: false,
        //     draggable: false,
        //     id : "TenantsErrorMsg"
        //     // transition: "flip"
        // });
    }

    downloadtenant(row) {

        let hostName = window.location.host;
        let url = restURL + "?module=manager&rest=downloadtenant&q=" + row.tenantcode + "/" + hostName + "/";
        // let url = restURL + "?module=users&rest=downloadtenant&q=" + row.tenantcode + "/" + hostName + "/";
        // var url = "service/service.php?module=users&rest=downloadtenant&q=" + getCookie('tenantcode') + "/" + hostName +"/";
        axios({
            method: "GET",
            url: url,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = helpers._tenantDownload + res.data.data;
                a.download = res.data.data;
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                this.redirectErrorMessage("Failed to download.");
                this.setState({
                    errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>,
                    deleteRISModal: false,
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    // Load Tenant Data
    getTenantData() {
        //Kavya Changes : handling license type intead of build_type for enterprise - Start - 16/06/2023
        let licensedetails = helpers.getSessionStorage("licenseResponse");
        let licenseType = "";
        if(licensedetails !== undefined && licensedetails !== null){
            licensedetails = JSON.parse(licensedetails);
            licenseType = licensedetails.details.type !== null && licensedetails.details.type !== undefined ? licensedetails.details.type : "";
        }
        var data = {
            companycode: helpers.getCookie('companycode'),
            tenantids: JSON.parse(helpers.getCookie('tenantIDS')),
            userid: parseInt(helpers.getCookie('userid')),
            rest: 'gettenants',
            module: 'users'
        };
        var url = restURL;
        axios({
            method: "POST",
            url: url,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json",
            data: data
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            this.setState({ tableLoader: "" });
            if (res.data.success) {
                this.checkForErrors(res.data);
                if (res.data.data.length > 0) {
                    if (licenseType.toLowerCase().includes("enterprise")) {
                        this.setState({ showAddNewTenantButton: false });
                    } else if (!licenseType.toLowerCase().includes("enterprise")) {
                        this.setState({ showAddNewTenantButton: true });
                    } else {
                        this.setState({ showAddNewTenantButton: false });
                    }
                }
                
            } else {
                this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6> });
            }
            this.setState({ loading: "hide" });
        }).catch(function (error) {
            console.log(error);
        });
    }

    //Check for Errors
    checkForErrors(responsedata) {
        if (responsedata.data === null || typeof responsedata.data === 'undefined') {
            this.setState({ errorMessage: <h5>No Data</h5> });
        } else if (responsedata.displaymessage != 'undefined' && responsedata.displaymessage != "") {
            let displaymessage = "";
            if (responsedata.displaymessage != "Configuration Error") {
                displaymessage += <h5 class="text-center">{responsedata.displaymessage}</h5>;
            }
            if (responsedata.displaymessage == "Configuration Error") {
                displaymessage += <h5 class="configureTenant orange" style="text-align: center;cursor: pointer;"> Click here to Configure</h5>;
            }
            this.setState({ errorMessage: displaymessage });
        } else {
            let tenantData= responsedata.data.filter(item => item.connection_status.toLowerCase() === "creating")
            if(tenantData !== null && tenantData !== undefined && tenantData.length >0){
                this.setState({ tenantCreation:true })
            }
            this.setState({ Data: responsedata.data },
                this.updateTenantData(responsedata.data));
        }
    }
    updateTenantData =(tenantsData) =>{
        let userData = helpers.getSessionStorage("userData");
        let tenantsInfo = tenantsData;
        let _tenantIDS=[];
        if (typeof userData !== 'undefined' && userData !== null && userData !== "") {
            userData = JSON.parse(userData);
            

            for (let i = 0; i < tenantsInfo.length; i++) {
                tenantsInfo[i].id = parseInt(tenantsInfo[i].id);
                tenantsInfo[i]["tenantname"] = tenantsInfo[i].name;
                tenantsInfo[i]["expirytime"] = tenantsInfo[i].expirydate;
            }
            userData.tenantsinfo = tenantsInfo;
            helpers.setSessionStorage("userData", JSON.stringify(userData));
            helpers.setLocalStorage("tenantsData",JSON.stringify(userData.tenantsinfo))
           // helpers.setCookie('tenantIDS', JSON.stringify(_tenantIDS), 10);
        }
    }
   

    expiryTimeFormatter(row) {
        return (<div style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>
            <Moment format="YYYY-MM-DD" unix>{row}</Moment>
        </div>);
    }

    // changeToggle(event) {
    //     console.log("event", event.target.checked);
    //     console.log("build_type", helpers.getCookie("build_type"));
    //     this.setState({
    //         togglestate: event.target.checked
    //     }, function () {
    //         console.log("togglechecked", this.state.togglestate);
    //     })
    // }

    buttonFormatter(cell, row) {
        let userData = helpers.getSessionStorage("userData");
        let data1 = JSON.parse(userData);
        var responseHtml = '';
        if (data1.build_type == "mssp" && row.status === "active") {
            return (
                <div className="Actionicons">
                    <i className="fa fa-edit EditIcon cursor-point" title="Edit" id="TenantEditBtn_1" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showEditTenantModal(row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;
                    {data1.issuperadmin === "y" ?
                        <span><i className="fa fa-trash EditIcon cursor-point" title="Delete" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showDeleteCategoryModal(row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        : ""}
                    {row.host_type !== "local" ?
                        <span><i className="fa fa-download cursor-point" title="Download" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.downloadtenant(row)}></i> &nbsp;&nbsp;&nbsp;&nbsp;</span>
                        : ""}
                    <i className="fa fa-cloud cursor-point" title="Backup" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showTenantBackupModal(row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;
                    <i className="fa fa-undo cursor-point" title="Restore" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showTenantRestoreModal(row)}></i>
                </div>
            )
        } else if(row.status === "pending" || row.status === "failed") {
            return (
                <div className="Actionicons">
                    {data1.issuperadmin === "y" ?
                        <span><i className="fa fa-trash EditIcon cursor-point" title="Delete" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showDeleteCategoryModal(row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        : ""}
                </div>)
        }else{
            return (
                <div className="Actionicons">
                    <i className="fa fa-edit EditIcon cursor-point" title="Edit" id="TenantEditBtn_2" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showEditTenantModal(row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;
                    {data1.issuperadmin === "y" ?
                        <span><i className="fa fa-trash EditIcon cursor-point" title="Delete" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showDeleteCategoryModal(row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        : ""}
                </div>)
        }
    }

    getindustry() {
        var url = restURL + "?module=users&rest=getindustries&q=" + helpers.getCookie('companycode') + "/";
        axios({
            method: "GET",
            url: url,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                this.saveIndustry(res.data);
            } else {
                this.setState({
                    errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    saveIndustry(responsedata) {
        if (responsedata.success === true && responsedata.data !== null) {
            // let industry = [];
            // for (var i = 0; i < responsedata.data.length; i++) {
            // var tempIndustry = { label: responsedata.data[i].name, value: responsedata.data[i].id };
            // industry.push(tempIndustry);
            // }
            this.setState({ industryArr: responsedata.data });
        } else {
            this.setState({ errorMessage: <h5>No Data</h5> });
        }
    }

    //Add New Category Modal
    showAddTenantModal() {
        this.setState({
            showAddTenantModal: true,
            newTenantHostDetails: { name: "" },
            newTenantAppDetails: { name: "" },
            host: "No",
            NameErr: "",
            industryError: "",
            DescriptionErr: "",
            newTenantName: "",
            newTenantDescription: "",
            newTenantIndustry: "",
            newTenantExpiryDate: "",
            newEditTenantName: "",
            tenantHostTypeerror: "",
            selectedTenantHostType: "",
            // fileError : true
        });
    }

    handleTenantDescription(e) {
        let tempDescription = e.target.value;
        if (tempDescription.length === 0) {
            this.setState({ DescriptionErr: "Description is required", newTenantDescription: "" })
        } else {
            this.setState({ newTenantDescription: tempDescription, DescriptionErr: "" });
        }
    }

    handleTenantIndustry(e) {
        this.setState({ newTenantIndustry: e.target.value });
    }

    handleTenantHostType(e) {
        this.setState({ selectedTenantHostType: e.target.value });
    }

    handleTenantHostDetails(e) {
        this.setState({ newTenantHostDetails: e.target.files[0], }, this.validateform);
    }

    validateform() {
        if ((this.state.newTenantAppDetails.name == "" && this.state.newTenantHostDetails.name != '') || (this.state.newTenantAppDetails.name != "" && this.state.newTenantHostDetails.name == '')) {
            this.setState({ host: "No", fileError: true });
            // this.redirectErrorMessage("Include both files");
        } else if ((this.state.newTenantAppDetails.name == "" && this.state.newTenantHostDetails.name == '') || (this.state.newTenantAppDetails.name != "" && this.state.newTenantHostDetails.name != '')) {
            this.setState({ host: "Yes", fileError: false });
        }
    }
    handleTenantAppDetails(e) {
        //     if(this.state.newTenantHostDetails.name == ""){
        //         this.setState({tenantAppError : "show"});
        //   }else{
        //       if(this.state.newTenantHostDetails.name == ""){

        //       }
        //      console.log("inside else validateform");
        //   }
        this.setState({
            newTenantAppDetails: e.target.files[0],
        }, this.validateform);
        // setTimeout(() => {console.log("inside handleTenantHostDetails")} , 5000));
        //    this.validateform();
        // setTimeout(this.validateform , 5000) );
    }
    handleChange(e) {
        this.setState({
            startDate: e,
        });
    };
    handleTenantExpiryDate(e) {
        let expirytime = new Date(e.target.value).getTime() / 1000;
        let currenttime = new Date().getTime() / 1000;
        let difference = expirytime - currenttime;
        let tempExpiryDate = e.target.value;
        if (difference > 0) {
            if (tempExpiryDate.length == "0") {
                this.setState({ ExpiryDateErr: "Expiry Date required", newTenantExpiryDate: "" });
            } else {
                this.setState({ newTenantExpiryDate: tempExpiryDate, ExpiryDateErr: "" });
            }
        } else {
            this.setState({ ExpiryDateErr: "Please select date greater than the current date", newTenantExpiryDate: "" });
        }
    }

    // addNewTenant(e){
    //     console.log("this.state.newTenantAppDetails", this.state.newTenantAppDetails);
    //     console.log("this.state.newTenantHostDetails", this.state.newTenantHostDetails);
    //     if((this.state.newTenantAppDetails.name == "" && this.state.newTenantHostDetails.name != '')||  (this.state.newTenantAppDetails.name != "" && this.state.newTenantHostDetails.name == '')){
    //         this.setState({
    //             fileError : true
    //         })
    //     }else{
    //         this.setState({
    //             fileError : false
    //         })
    //     //     this.notificationMessage("Included Files");
    //     this.addNewTenant1(e);
    // }
    // }
    addNewTenant(e) {
        this.setState({ loading: "show", showAddTenantModal: false });
        e.preventDefault();
        let tenentIDs = helpers.getCookie('tenantIDS');
        let tenant_name = (this.state.newEditTenantName).trim();
        let tenant_description = this.state.newTenantDescription;
        let app_details_file = this.state.newTenantAppDetails;
        let host_details_file = this.state.newTenantHostDetails;
        let industyID = this.state.newTenantIndustry;
        let tenantcodestring = tenant_name.toLowerCase();
        let expiryDate = this.state.newTenantExpiryDate;
        let expiryDate10Digits = new Date(expiryDate).getTime() / 1000;
        expiryDate10Digits = Math.round(expiryDate10Digits);
        // REMOVES WHITE SPACE AND MAKES A SINGLE WORD
        let tenantCode = tenantcodestring.replace(/ +/g, "");


        let formdata = new FormData();
        formdata.append("name", tenant_name);
        formdata.append("description", tenant_description);
        formdata.append("tenantcode", tenantCode);
        formdata.append("app_details", app_details_file);
        formdata.append("host_details", host_details_file);
        formdata.append("companycode", helpers.getCookie('companycode'));
        formdata.append("industryid", industyID);
        formdata.append("expirydate", parseInt(expiryDate10Digits));
        formdata.append("tenantidstring", tenentIDs);
        formdata.append("user_id", helpers.getCookie('userid'));
        formdata.append("host", "No");

        if (helpers.getCookie('build_type') === "mssp") {
            formdata.append("host_type", this.state.selectedTenantHostType);
        }

        if (helpers.getCookie('build_type') === "mssp" && this.state.selectedTenantHostType == "local") {
            formdata.append("rest", "createmssplocaltenant");
            formdata.append("module", "manager");
        } else {
            formdata.append("rest", "tenants");
            formdata.append("module", "users");
        }

        axios({
            method: "POST",
            url: restURL,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json",
            dataType: "json",
            processData: false,
            contentType: false,
            data: formdata,
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                if (typeof res.data.data !== 'undefined' && res.data.data !== null && res.data.data.length > 0) {
                    let tenantsInfo = res.data.data;
                    let userData = helpers.getSessionStorage("userData");
                    let _tenantIDS = [];
                    if (tenantsInfo.length === 1) {
                        helpers.setCookie("tenantcode", tenantsInfo[0].tenantcode, 10);
                    }
                    if (typeof userData !== 'undefined' && userData !== null && userData !== "") {
                        userData = JSON.parse(userData);
                        for (let i = 0; i < tenantsInfo.length; i++) {
                            tenantsInfo[i].id = parseInt(tenantsInfo[i].id);
                            tenantsInfo[i]["tenantname"] = tenantsInfo[i].name;
                            tenantsInfo[i]["expirytime"] = tenantsInfo[i].expirydate;
                            _tenantIDS.push(tenantsInfo[i].id.toString());
                        }
                        userData.tenantsinfo = tenantsInfo;
                        helpers.setSessionStorage("userData", JSON.stringify(userData));
                    }
                    helpers.setCookie('tenantIDS', JSON.stringify(_tenantIDS), 10);
                }

                if (window.location.href.includes("/configure")) {
                    this.props.history.push("/home");
                } else {
                    setTimeout(() => { window.location.reload(true); }, 300);
                    this.notificationMessage("Tenant added successfully.");
                    this.checkForErrors(res.data);
                }
            } else {
                //Kavya Changes : Added condition for error values - 07/07/2023
                if(res.data.displaymessage !== null && res.data.displaymessage !== undefined && res.data.displaymessage !== ""){
                    this.redirectErrorMessage(res.data.displaymessage)
                }else{
                    this.redirectErrorMessage("Error in response.")
                }
                // this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6> });
            }
            this.setState({ loading: "hide" });
        }).catch(function (error) {
            console.log(error);
        });
    }

    closeAddTenantModal() {
        this.setState({ showAddTenantModal: false });
    }

    //Edit Category Modal
    showEditTenantModal(row) {
        this.setState({disabledupdate:true})
        this.setState({ NameErr: "", DescriptionErr: "" });
        // var protocol = window.location.protocol;
        // var hostname = window.location.hostname;
        let expDate = row.expirydate;

        let expiryDate = new Date(expDate * 1000);
        let formattedDate = dateFormat(expiryDate, "yyyy-mm-dd");
        let industry = this.state.industryArr;
        let industryID = "";
        for (let i = 0; i < industry.length; i++) {
            if (row.industry === industry[i].name) {
                industryID = industry[i].id;
                break;
            }
        }

        this.setState({
            newEditTenantName: row.name,
            newEditTenantDescription: row.description,
            newEditTenantExpiryDate: formattedDate,
            newEditTenantIndustry: industryID,
            originalname: row.name
        });

        // var url = restURL + "?module=users&rest=gettenantdata&q=" + helpers.getCookie('companycode') + "/" + row.id + "/" + protocol + "/" + hostname + "/";
        // axios({
        //     method: "GET",
        //     url: url,
        //     headers: {
        //         access_token: helpers.getCookie('accesstoken'),
        //         jwt_token: helpers.getCookie('jwt_token'),
        //         "Content-Type": "application/json; charset=utf-8"
        //     },
        //     responseType: "json"
        // }).then(res => {
        //     helpers.checkSessionTimeout(res.data.sessionexpired);
        //     if (res.data.success) {
        //         this.setTenantData(res.data.data, expDate);
        //     } else {
        //         this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>, });
        //     }
        // }).catch(function (error) {
        //     console.log(error);
        // });

        this.setState({ newEditTenantId: row.id, showEditTenantModal: true });
    }

    setTenantData(responsedata, expDate) {
        var expiryDate = new Date(expDate * 1000);
        var formattedDate = dateFormat(expiryDate, "yyyy-mm-dd");
        this.setState({
            newEditTenantName: responsedata.name,
            newEditTenantDescription: responsedata.description,
            newEditTenantHostDetails: responsedata.hostfilename,
            newEditTenantAppDetails: responsedata.appfilename,
            newEditTenantExpiryDate: formattedDate,
            newEditTenantIndustry: responsedata.industryid,
            originalname: responsedata.name
        });
    }

    handleEditTenantName(e) {
        this.setState({disabledupdate:false})
        if (e.target.value.length != 0) {
            if (e.target.value.match(/^[a-zA-Z ]*$/i) == null) {
                this.setState({ NameErr: "Only text allowed", newEditTenantName: e.target.value, isValidTenantName: false });
            } else {
                let value = e.target.value;
                value = value.toLowerCase();
                let tenantData = this.state.Data;
                let sameNameFound = false;
                for (let i = 0; i < tenantData.length; i++) {
                    if (tenantData[i].name.trim().toLowerCase() === value.trim()) {
                        if (!this.state.showAddTenantModal) {
                            if (this.state.originalname.trim().toLowerCase() !== value.trim()) {
                                sameNameFound = true;
                                break;
                            }
                        } else {
                            sameNameFound = true;
                            break;
                        }
                    }
                }

                if (sameNameFound) {
                    this.setState({ NameErr: "Tenant name is already exists. Please choose different name.", isValidTenantName: false });

                }else if(e.target.value.toLowerCase().trim() === "master"){//Handle this condition to avoid Tenant name as "Master"

                    this.setState({ NameErr: "Tenant name cannot be created as Master. Please choose different name.", isValidTenantName: false });
                }else {
                    this.setState({ NameErr: "", isValidTenantName: true });
                }
                this.setState({ newEditTenantName: e.target.value, sameNameFound });
            }
        } else {
            this.setState({ NameErr: "Tenant name is required", newEditTenantName: "", isValidTenantName: false });
        }
        if(e.target.value.length > 40){
            this.setState({ NameErr: "Tenant name should not be more than 40 characters", isValidTenantName: false });
        }
       
    }

    handleEditTenantDescription(e) {
        this.setState({disabledupdate:false})
        var tempDescription = e.target.value;
        if (tempDescription.length == "0") {
            this.setState({ DescriptionErr: "Description is required", newEditTenantDescription: "" })
        } else {
            this.setState({ newEditTenantDescription: tempDescription, DescriptionErr: "" });
        }
    }

    handleEditTenantIndustry(e) {
        this.setState({disabledupdate:false})
        this.setState({ newEditTenantIndustry: e.target.value });
    }

    handleEditTenantHostDetails(e) {
        this.setState({ newHostFile: e.target.files[0] });
    }

    handleEditTenantAppDetails(e) {
        this.setState({ newAppFile: e.target.files[0] });
    }

    handleEditTenantExpiryDate(e) {
        this.setState({disabledupdate:false})
        let expirytime = new Date(e.target.value).getTime() / 1000;
        let currenttime = new Date().getTime() / 1000;
        let difference = expirytime - currenttime;

        let tempExpiryDate = e.target.value;
        if (difference > 0) {
            if (tempExpiryDate.length == "0") {
                this.setState({ ExpiryDateErr: "Expiry date is required", newEditTenantExpiryDate: tempExpiryDate });
            } else {
                this.setState({ newEditTenantExpiryDate: tempExpiryDate, ExpiryDateErr: "" });
            }
        } else {
            this.setState({ ExpiryDateErr: "Can't choose previous date", newEditTenantExpiryDate: "" });
        }
    }

    updateTenant(e) {

        this.setState({ loading: "show", showEditTenantModal: false });
        e.preventDefault();
        if (!this.state.newHostFile || !this.state.newAppFile) {
            var expiryDate = this.state.newEditTenantExpiryDate;
            var expiryDate10Digits = new Date(expiryDate).getTime() / 1000;
            expiryDate10Digits = Math.round(expiryDate10Digits);

            var data = {
                id: parseInt(this.state.newEditTenantId),
                name: this.state.newEditTenantName,
                description: this.state.newEditTenantDescription,
                industryid: parseInt(this.state.newEditTenantIndustry),
                expirytime: expiryDate10Digits,
                tenantidstring: JSON.parse(helpers.getCookie('tenantIDS')),
                user_id: parseInt(helpers.getCookie('userid')),
                companycode: helpers.getCookie('companycode'),
                rest: "updatetenantdetails",
                module: "users"
            };

            axios({
                method: "POST",
                url: restURL,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                responseType: "json",
                processData: false,
                contentType: false,
                data: JSON.stringify(data),
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (typeof res.data.data !== 'undefined' && res.data.data !== null && res.data.data.length > 0) {
                    let tenantsInfo = res.data.data;
                    let userData = helpers.getSessionStorage("userData");
                    if (typeof userData !== 'undefined' && userData !== null && userData !== "") {
                        userData = JSON.parse(userData);
                        for (let i = 0; i < tenantsInfo.length; i++) {
                            for (let j = 0; j < userData.tenantsinfo.length; j++) {
                                if (parseInt(tenantsInfo[i].id) === userData.tenantsinfo[j].id) {
                                    userData.tenantsinfo[j].tenantname = tenantsInfo[i].name;
                                    userData.tenantsinfo[j].expirytime = tenantsInfo[i].expirydate;
                                    break;
                                }
                            }
                        }
                        helpers.setSessionStorage("userData", JSON.stringify(userData));
                        setTimeout(() => { window.location.reload(true); }, 200);
                    }
                }
                if (res.data.success) {
                    this.notificationMessage("Tenant updated successfully.");
                    this.checkForErrors(res.data);
                } else {
                    //Kavya Changes : Added condition for error values - 07/07/2023
                    if(res.data.displaymessage !== null && res.data.displaymessage !== undefined && res.data.displaymessage !== ""){
                        this.redirectErrorMessage(res.data.displaymessage)
                    }else{
                        this.redirectErrorMessage("Error in response.")
                    }
                    // this.setState({
                    //     errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>,
                    // });
                }
                this.setState({ loading: "hide" });
            }).catch(function (error) {
                console.log(error);
            });

        } else {

            let tenentIDs = helpers.getCookie('tenantIDS');
            let tenant_name = this.state.newEditTenantName;
            let tenant_description = this.state.newEditTenantDescription;
            let app_details_file = this.state.newAppFile;
            let host_details_file = this.state.newHostFile;
            let industyID = this.state.newEditTenantIndustry;
            let tenantcodestring = tenant_name.toLowerCase();
            let expiryDate = this.state.newEditTenantExpiryDate;
            let expiryDate10Digits = new Date(expiryDate).getTime() / 1000;
            expiryDate10Digits = Math.round(expiryDate10Digits);

            // REMOVES WHITE SPACE AND MAKES A SINGLE WORD
            let tenantCode = tenantcodestring.replace(/ +/g, "");
            let formdata = new FormData();
            formdata.append("id", parseInt(this.state.newEditTenantId));
            formdata.append("name", tenant_name);
            formdata.append("description", tenant_description);
            formdata.append("tenantcode", tenantCode);
            formdata.append("app_details_update", app_details_file);
            formdata.append("host_details_update", host_details_file);
            formdata.append("companycode", helpers.getCookie('companycode'));
            formdata.append("industryid", industyID);
            formdata.append("expirydate", parseInt(expiryDate10Digits));
            formdata.append("tenantidstring", tenentIDs);
            formdata.append("user_id", helpers.getCookie('userid'));
            formdata.append("rest", "updatetenant");
            formdata.append("module", "users");

            axios({
                method: "POST",
                url: restURL,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                responseType: "json",
                processData: false,
                contentType: false,
                data: formdata,
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (res.data.success) {

                } else {
                    this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>, });
                }
                this.setState({ loading: "hide" });
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    downloadHostFile() {
        const fileurl = "/downloads/logs_downloads/" + [this.state.newEditTenantHostDetails];
        const url = window.URL.createObjectURL(new Blob([fileurl]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.state.newEditTenantHostDetails);
        document.body.appendChild(link);
        link.style.display = 'none';
        link.click();
    }

    downloadAppFile() {
        const fileurl = "/downloads/logs_downloads/" + [this.state.newEditTenantAppDetails];
        const url = window.URL.createObjectURL(new Blob([fileurl]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.state.newEditTenantAppDetails);
        document.body.appendChild(link);
        link.style.display = 'none';
        link.click();
    }

    downloadSampleHostFile() {
        const fileurl = "/downloads/sample/hostDetails.xlsx";
        const url = window.URL.createObjectURL(new Blob([fileurl]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'hostDetails.xlsx');
        document.body.appendChild(link);
        link.style.display = 'none';
        link.click();
    }

    downloadSampleAppFile() {
        const fileurl = "/downloads/sample/applicationDetails.xlsx";
        const url = window.URL.createObjectURL(new Blob([fileurl]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'applicationDetails.xlxs');
        document.body.appendChild(link);
        link.style.display = 'none';
        link.click();
    }

    closeEditTenantModal() {
        this.setState({ showEditTenantModal: false });
    }

    //Delete Category Model
    showDeleteCategoryModal(row) {
        this.setState({
            newTenantCode: row.tenantcode,
            newTenantId: row.id,
            name: row.name,
            showDeleteCategoryModal: true,
        })
    }

    closeDeleteTenantModal() {
        this.setState({ showDeleteCategoryModal: false });
    }

    deleteTenant() {
        this.setState({ loading: "show", showDeleteCategoryModal: false });
        let data = {
            id: parseInt(this.state.newTenantId),
            // user_id :this.state.user_id,
            user_id: parseInt(helpers.getCookie('userid')),
            companycode: helpers.getCookie('companycode'),
            tenantidstring: JSON.parse(helpers.getCookie('tenantIDS')),
            tenantcode: this.state.newTenantCode,
            rest: 'deletetenant',
            module: 'users'
        };

        axios({
            method: "DELETE",
            url: restURL,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json",
            data: JSON.stringify(data)
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                let _tenantIDS = [];
                if (typeof res.data.data !== 'undefined' && res.data.data !== null && res.data.data.length > 0) {
                    let tenantsInfo = res.data.data;
                    if (tenantsInfo.length === 1) {
                        helpers.setCookie("tenantcode", tenantsInfo[0].tenantcode, 10);
                    }
                    let userData = helpers.getSessionStorage("userData");
                    if (typeof userData !== 'undefined' && userData !== null && userData !== "") {
                        userData = JSON.parse(userData);
                        for (let i = 0; i < tenantsInfo.length; i++) {
                            tenantsInfo[i].id = parseInt(tenantsInfo[i].id);
                            tenantsInfo[i]["tenantname"] = tenantsInfo[i].name;
                            tenantsInfo[i]["expirytime"] = tenantsInfo[i].expirydate;
                            _tenantIDS.push(tenantsInfo[i].id.toString());
                        }
                        userData.tenantsinfo = tenantsInfo;
                        helpers.setSessionStorage("userData", JSON.stringify(userData));
                        setTimeout(() => { window.location.reload(true); }, 300);
                    }
                    helpers.setCookie('tenantIDS', JSON.stringify(_tenantIDS), 10);
                } else {
                    let userData = helpers.getSessionStorage("userData");
                    if (typeof userData !== 'undefined' && userData !== null && userData !== "") {
                        userData = JSON.parse(userData);
                        let tenantsInfo = [];
                        userData.tenantsinfo = tenantsInfo;
                        helpers.setSessionStorage("userData", JSON.stringify(userData));
                    }
                    helpers.setCookie("tenantcode", "", 10);
                    helpers.setCookie('tenantIDS', JSON.stringify(_tenantIDS), 10);
                    setTimeout(() => {
                        this.props.history.push("/configure");
                    }, 300);
                }

                this.notificationMessage("Tenant deleted successfully.");
                this.checkForErrors(res.data);
            } else {
                this.setState({
                    errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>
                });
            }
            this.setState({ loading: "hide" });
        }).catch(function (error) {
            console.log(error);
        });
    }

    showTenantBackupModal(row) {
        this.setState({ showTenantBackupModal: true, currentTenantCode: row.tenantcode });
    }

    showTenantRestoreModal(row) {
        this.setState({ showTenantRestoreModal: true, dbRestoreTenantCode: row.tenantcode });
    }

    handleBackupChangeSelect(e) {
        this.setState({ selectedBackupOption: e.target.value, backupValid: true });
    }

    handleRestoreChangeSelect(e) {
        this.setState({ selectedRestoreOption: e.target.value, restoreValid: true });
    }

    closeTenantBackupModal() {
        this.setState({ showTenantBackupModal: false });
    }

    closeTenantRestoreModal() {
        this.setState({ showTenantRestoreModal: false });
    }

    doMSSPTenantBackup() {
        if (this.state.selectedBackupOption === "All")
            this.getBackup("all");
        else if (this.state.selectedBackupOption === "Configuration")
            this.getBackup("config");
        else if (this.state.selectedBackupOption === "Data")
            this.getBackup("data");
    }

    getBackup(dumpType) {
        this.setState({ loading: "show" });
        let url = restURL + "?module=manager&rest=msspdatabackup&q=" + this.state.currentTenantCode + "/" + dumpType + "/";
        axios({
            method: "GET",
            url: url,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = helpers._backup + res.data.data;
                let fileName = res.data.data.split("/");
                a.download = fileName[1];
                a.click();
                setTimeout(() => { this.notificationMessage("Backup done successfully.") }, 1000);
            } else {
                setTimeout(() => { this.redirectErrorMessage("Failed") }, 1000);
                this.setState({
                    errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>
                });
            }
            this.setState({ loading: "hide" });
            this.setState({ showTenantBackupModal: false });
        }).catch(function (error) {
            console.log(error);
        });
    }

    handleRestore(e) {
        this.setState({ restoreAllZip: e.target.files[0] });
    }

    doRestore() {

        this.setState({ loading: "show" });
        let formdata = new FormData();
        formdata.append("database_data", this.state.restoreAllZip);
        formdata.append("rest", "msspdatarestore");
        formdata.append("tenantcode", this.state.dbRestoreTenantCode)
        formdata.append("company_code", helpers.getCookie('companycode'));
        formdata.append("restore_type", this.state.selectedRestoreOption.toLowerCase());
        formdata.append("module", "manager");

        axios({
            method: "POST",
            url: restURL,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
            },
            responseType: "json",
            processData: false,
            contentType: false,
            data: formdata,
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                setTimeout(() => {
                    this.props.history.push("/logout");
                }, 1500);
            } else {
                this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6> });
                this.redirectErrorMessage("Failed");
            }
            this.setState({ showTenantRestoreModal: false, });
            this.setState({ loading: "hide" });
        }).catch(function (error) {
            console.log(error);
        });
    }

    handleKeyPress(target) {
        if(target.charCode === 32 && target.target.selectionStart === 0){
          target.preventDefault();
        }
      }

    render() {

        let modalStyle = { color: "" };
        let inputStyle = { color: "" };
        if (typeof this.props.theme !== 'undefined') {
            modalStyle = this.props.theme.modal;
            inputStyle = this.props.theme.input;
        }

        const options = {
            page: 1,
            sizePerPage: 10,
            pageStartIndex: 1,
            paginationSize: 3,
            prePage: 'Prev',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            paginationPosition: 'bottom',
            hidePageListOnlyOnePage: true,
            hideSizePerPage: true,
            searchPosition: 'left',
            noDataText: this.state.tableLoader
        };

        const fontColor = this.props.theme ? this.props.theme.color : "#2A3139";
        let userData = helpers.getSessionStorage("userData");
        let data1 = JSON.parse(userData);
        // sriraksha changes: added host type column
        //Kavya Changes : handling license type intead of build_type for enterprise - Start - 09/06/2023
		let licensedetails = helpers.getSessionStorage("licenseResponse");
        let licenseType = "";
        if(licensedetails !== undefined && licensedetails !== null){
            licensedetails = JSON.parse(licensedetails);
            licenseType = licensedetails.details.type !== null && licensedetails.details.type !== undefined ? licensedetails.details.type : "";
        }
        //Kavya Changes : handling license type intead of build_type for enterprise - End - 09/06/2023
        return (
            <div >
                <LoadingSpinner showSpinner={this.state.loading} />
                {window.location.href.includes("/configure") ? "" :
                    <div className="width100">
                        {window.location.href.includes("/Platform") ?
                            <BootstrapTable id="TenantsSearch" pagination={true} data={this.state.Data} trStyle={{ fontSize: "13px" }} bodyStyle={{ overflow: 'auto' }} striped={false} hover={true} trClassName='table-format' options={options} search>
                                <TableHeaderColumn dataAlign='left' width='80' dataField="name" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} isKey={true} dataSort={true} dataFormat={this.commonFormatter}>Name</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='150' dataField="description" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter} >Description</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="industry" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Industry</TableHeaderColumn>
                                {/* <TableHeaderColumn dataAlign='left' width='70' dataField="host_count" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Hosts</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='120' dataField="bu_count" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter} >Business Units</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="dc_count" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Data Centers</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='90' dataField="region_count" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter} >Regions</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="apps_count" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Applications</TableHeaderColumn> */}
                                <TableHeaderColumn dataAlign='left' width='100' dataField="expirydate" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.expiryTimeFormatter}>Expiry Date</TableHeaderColumn>
                                {helpers.getCookie('build_type') === "mssp" ?
                                <TableHeaderColumn dataAlign='left' width='100' dataField="host_type" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Host Type</TableHeaderColumn>
                                :
                                /*Kavya Changes: condition handled to avoid screen blank if field is not present - 02/06/2023*/
                                <TableHeaderColumn dataAlign='left' width='100' dataField="industry" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} hidden>Industry</TableHeaderColumn>
                                }
                                <TableHeaderColumn dataAlign='left' width='70' dataField="connection_status" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.statusFormatter}>Status</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="uid" hidden={helpers.getCookie("_platformWritePersmission") === "true" ? false : true} thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataFormat={this.buttonFormatter} visible={this.state.showDelete}>Action</TableHeaderColumn>
                            </BootstrapTable>
                            : <BootstrapTable pagination={true} data={this.state.Data} trStyle={{ fontSize: "13px" }} bodyStyle={{ overflow: 'auto' }} striped={false} hover={true} trClassName='table-format' options={options}>
                                <TableHeaderColumn dataAlign='left' width='80' dataField="name" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} isKey={true} dataSort={true} dataFormat={this.commonFormatter}>Name</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='160' dataField="description" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter} >Description</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='110' dataField="industry" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Industry</TableHeaderColumn>
                                {/* <TableHeaderColumn dataAlign='left' width='60' dataField="host_count" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Hosts</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='90' dataField="bu_count" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter} >Business Units</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='80' dataField="dc_count" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Data Centers</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='70' dataField="region_count" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter} >Regions</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='80' dataField="apps_count" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Applications</TableHeaderColumn> */}
                                <TableHeaderColumn dataAlign='left' width='70' dataField="connection_status" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.statusFormatter}>Status</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="expirydate" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.expiryTimeFormatter}>Expiry Date</TableHeaderColumn>
                                {helpers.getCookie('build_type') === "mssp" ?
                                <TableHeaderColumn dataAlign='left' width='100' dataField="host_type" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Host Type</TableHeaderColumn>
                                :
                                /*Kavya Changes: condition handled to avoid screen blank if field is not present - 02/06/2023*/
                                <TableHeaderColumn dataAlign='left' width='100' dataField="industry" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} hidden>Industry</TableHeaderColumn>
                                }
                            </BootstrapTable>
                        }
                    </div>
                }
                {data1.issuperadmin === "y" && this.state.showAddNewTenantButton && helpers.getCookie("_platformWritePersmission") === "true" && !window.location.href.includes("/AssetExplorer") ?
                    <div style={{ marginTop: "20px", textAlign: "left" }}>
                        <Button id ="platformTenantsAddNewButton" className={"cancelButton"} size="sm" color="success" onClick={this.showAddTenantModal} disabled={this.state.tenantCreation}>Add New Tenant</Button>
                    </div> : ""}
                {/* <ToastContainer /> */}

                <Modal size="lg" fade={false} isOpen={this.state.showAddTenantModal} toggle={this.closeAddTenantModal} backdrop="static">
                    <ModalHeader id="TenantsAddNewTenant" toggle={this.closeAddTenantModal} style={modalStyle}><span style={{ color: fontColor, fontSize: "20px" }}>Add New Tenant</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Name*</Label>
                                    <Input  id ="platformTenantsName" onChange={this.handleEditTenantName}  onKeyPress={this.handleKeyPress} value={this.state.newEditTenantName} style={inputStyle} type="text" placeholder="Tenant Name" maxLength="41" />
                                    <div className="errorMessage">{this.state.NameErr}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Description*</Label>
                                    <Input id ="platformTenantsDescription" style={inputStyle}  onKeyPress={this.handleKeyPress} onChange={this.handleTenantDescription} type="text" placeholder="Tenant Description" />
                                    <div className="errorMessage">{this.state.DescriptionErr}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Industry*</Label>
                                    <Input id ="platformTenantsIndustry"  onKeyPress={this.handleKeyPress} onChange={this.handleTenantIndustry} style={inputStyle} type="select" defaultValue="">
                                        <option value="" disabled>Select</option>
                                        {this.state.industryArr.map((data, i) => (
                                            <option value={data.id} key={i}>{data.name}</option>
                                        ))}
                                    </Input>
                                    <div className="errorMessage">{this.state.industryError}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Expiry Date*</Label>
                                    <Input id ="platformTenantsExpiryDate" style={inputStyle}  onKeyPress={this.handleKeyPress} onChange={this.handleTenantExpiryDate} max="9999-12-31" type="date" />
                                    <div className="errorMessage">{this.state.ExpiryDateErr}</div>
                                </FormGroup>
                            </Col>

                            {helpers.getCookie('build_type') === "mssp" ?
                                <Col sm="12">
                                    <FormGroup>
                                        <Label style={{ color: fontColor }}>Host Type*</Label>
                                        <Input id ="platformTenantsHostType" value={this.state.selectedTenantHostType}  onKeyPress={this.handleKeyPress} type="select" style={inputStyle} onChange={this.handleTenantHostType}>
                                            <option disabled value="">---Select---</option>
                                            <option value="local">Local</option>
                                            <option value="remote">Remote</option> 
                                            {/* Kavya changes tenant - enabled "Remote" option - 21/06/2023 */}
                                        </Input>
                                        <div className="errorMessage">{this.state.tenantHostTypeerror}</div>
                                    </FormGroup>
                                </Col>
                                : ""}
                        </Row>
                        <div className="pull-right" style={{ marginTop: "20px" }}>
                            <Button id ="generalTenantsAddSaveBtn" size="sm" color="success" style={{ align: "right" }} onClick={this.addNewTenant} disabled={this.state.isValidTenantName === false || !this.state.newEditTenantName || !this.state.newTenantDescription || !this.state.newTenantIndustry || !this.state.newTenantExpiryDate || (helpers.getCookie('build_type') !== "enterprise" && !this.state.selectedTenantHostType)}>Save</Button>
                            <Button id ="generalTenantsAddCloseBtn" className={"cancelButton "} size="sm" onClick={this.closeAddTenantModal}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal size="lg" fade={false} isOpen={this.state.showEditTenantModal} toggle={this.closeEditTenantModal} backdrop="static">
                    <ModalHeader toggle={this.closeEditTenantModal} style={modalStyle}><span style={{ color: fontColor, fontSize: "20px" }}>Manage Tenant</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Name*</Label>
                                    <Input id="TenantsTenantName" value={this.state.newEditTenantName} onChange={this.handleEditTenantName}  onKeyPress={this.handleKeyPress} style={inputStyle} type="text" placeholder="Tenant Name" maxLength="41"/>
                                    <div className="errorMessage">{this.state.NameErr}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Description*</Label>
                                    <Input id="TenantsTenantDescription" style={inputStyle} value={this.state.newEditTenantDescription}  onKeyPress={this.handleKeyPress} onChange={this.handleEditTenantDescription} type="text" placeholder="Tenant Description" />
                                    <div className="errorMessage">{this.state.DescriptionErr}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Industry*</Label>
                                    <Input id="TenantsIndustry" type="select" value={this.state.newEditTenantIndustry}  onKeyPress={this.handleKeyPress} onChange={(e) => this.handleEditTenantIndustry(e)} style={inputStyle} >
                                        <option value="" disabled>Select</option>
                                        {this.state.industryArr.map((data, i) => (
                                            <option key={i} value={data.id}>{data.name}</option>
                                        ))}
                                    </Input>
                                    <div className="errorMessage">{this.state.industryError}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Expiry Date*</Label>
                                    <Input id="TenantsDate" style={inputStyle} value={this.state.newEditTenantExpiryDate}  onKeyPress={this.handleKeyPress} onChange={this.handleEditTenantExpiryDate} max="9999-12-31" type="date" />
                                    <div className="errorMessage">{this.state.ExpiryDateErr}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="pull-right" style={{ marginTop: "20px" }}>
                            <Button id="generalTenantsEditSaveBtn" type="submit" onClick={this.updateTenant} size="sm" color="success" style={{ align: "right" }} disabled={this.state.isValidTenantName == false || !this.state.newEditTenantDescription || !this.state.newEditTenantIndustry || !this.state.newEditTenantExpiryDate || this.state.disabledupdate}>Update</Button>
                            <Button id="generalTenantsEditCloseBtn" className={"cancelButton "} size="sm" onClick={this.closeEditTenantModal}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal size="md" fade={false} isOpen={this.state.showDeleteCategoryModal} toggle={this.closeDeleteTenantModal} backdrop="static">
                    <ModalHeader toggle={this.closeDeleteTenantModal} style={modalStyle}>
                        <span style={{ color: fontColor, fontSize: "20px" }}>Message</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <h5 style={{ color: fontColor, fontSize: "20px" }}>Delete Tenant {this.state.name} ?</h5>
                        <div className="pull-right" style={{ marginTop: "20px" }}>
                            <Button id="TenantsDelete" className={"buttonColor"} size="sm" color="danger" onClick={this.deleteTenant}>Delete</Button>
                            <Button id="TenantsClose" className={"cancelButton"} size="sm" onClick={this.closeDeleteTenantModal}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>

                {/* Show Tenant Backup Modal */}
                <Modal size="md" fade={false} isOpen={this.state.showTenantBackupModal} toggle={this.closeTenantBackupModal} backdrop="static">
                    <ModalHeader toggle={this.closeTenantBackupModal} style={modalStyle}>
                        <span style={{ color: fontColor, fontSize: "20px" }}>Backup</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <FormGroup style={{ color: "black" }}>
                            <Input value={this.state.selectedBackupOption}  onKeyPress={this.handleKeyPress} type="select" style={inputStyle} onChange={this.handleBackupChangeSelect}>
                                <option disabled value="">---Select---</option>
                                <option value="All">All</option>
                                <option value="Configuration">Configuration</option>
                                <option value="Data">Data</option>
                            </Input>
                        </FormGroup>
                        <Button size="sm" id="TenantBackupBtn" onClick={this.doMSSPTenantBackup} color="success" type="submit" style={{ float: "left", color: "white" }} disabled={!this.state.backupValid} >Backup Now </Button>
                        <Button id="TenantsCloseBtn" className={"cancelButton "} size="sm" onClick={this.closeTenantBackupModal}>Close</Button>
                    </ModalBody>
                </Modal>

                {/* Show Tenant Restore Modal*/}
                <Modal size="md" fade={false} isOpen={this.state.showTenantRestoreModal} toggle={this.closeTenantRestoreModal} backdrop="static">
                    <ModalHeader toggle={this.closeTenantRestoreModal} style={modalStyle}>
                        <span style={{ color: fontColor, fontSize: "20px" }}>Restore</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <Input value={this.state.selectedRestoreOption} type="select" id="TenantsRestoreoption" style={inputStyle}  onKeyPress={this.handleKeyPress} onChange={this.handleRestoreChangeSelect}>
                            <option disabled value="">---Select---</option>
                            <option value="All">All</option>
                            <option value="Configuration">Configuration</option>
                            <option value="Data">Data</option>
                        </Input>
                        <br />
                        <h6 style={{ color: fontColor }}>{this.state.notificationMessage}</h6>
                        <Input style={{ border: "1px solid gray" }} type="file"  onKeyPress={this.handleKeyPress} onChange={this.handleRestore} accept=".zip" />
                        <br />
                        <Button size="sm" id="TenantRestoreBtn" onClick={this.doRestore} color="success" type="submit" style={{ float: "left", color: "white" }} disabled={!this.state.restoreValid} >Restore Now </Button>
                        <Button id="TenantsCloseBtn1" className={"cancelButton "} size="sm" onClick={this.closeTenantRestoreModal}>Close</Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

const exportDashboard = withRouter(connect(mapStateToProps, mapDispatchToProps)(Tenants));
export default exportDashboard;
