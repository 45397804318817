import helpers from "../../helpers";
import axios from "axios";
export function getSoarWidgetData(widegtName,type,paramsArray) {
    var url = "";
    let userID = "";
    var postData = [];
    var typeChange = "";


    let groupid =""
     
    let  userGroups = JSON.parse(helpers.getCookie('userGroups'));
    for (var i=0;i<userGroups.length;i++){
        groupid=groupid+userGroups[i]+","
    }
    let grouidsString =groupid.replace(/.$/, '')
    grouidsString= grouidsString === ""?"-":grouidsString

    if (helpers.getCookie('displayallusersdata') === "y") {
        userID = "-1";
    } else {
        userID = helpers.getCookie('userid');
    }

    if(widegtName === "getAnalystWorkloadData"){
        url = helpers.restURL + "?module=siem&rest=analystworkload&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/"
    }
    else if(widegtName === "getAssignedCasesData"){
       url = helpers.restURL + "?module=siem&rest=dashboardinvestigationincidents&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + userID + "/"
    }
    else if(widegtName === "getCaseVelocityData"){
        url = helpers.restURL + "?module=siem&rest=monthlycases&q=" + helpers.getCookie('tenantcode') + "/created/" + helpers.getCookie('companycode') + "/" + helpers.getCookie('userid') + "/"
    }
    else if(widegtName === "getCasesByTagsData"){
        url = helpers.restURL + "?module=siem&rest=getincidenttagsbycases&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/-1/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getCaseDeduplicationData"){
        url = helpers.restURL + "?module=siem&rest=getcasebygroupsdata&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/default/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getKillChainData"){
        url = helpers.restURL + "?module=siem&rest=cyberkillchain&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + helpers.getCookie('companycode') + "/" + userID + "/"
    }
    else if(widegtName === "getTrendsPerWeekData"){
        url = helpers.restURL + "?module=siem&rest=weeklyincidents&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/" + userID + "/"
    }
    else if(widegtName === "getCasesBySLAStatusData"){
        url = helpers.restURL + "?module=siem&rest=incidentsbyslastatusontimerange&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getCasesByStatusData"){
        url = helpers.restURL + "?module=siem&rest=incidentsbystatusontimerange&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + helpers.getCookie('companycode') + "/" + userID + "/"
    }
    else if(widegtName === "getCasesByCategoryData"){
        url = helpers.restURL + "?module=siem&rest=incidentsbycategoryontimerange&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + helpers.getCookie('companycode') + "/" + userID + "/"
    }
    else if(widegtName === "getCasesByFunctionalImpactData"){
        url =  helpers.restURL + "?module=siem&rest=incidentsbyfunctionalimpact&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + userID + "/"
    }
    else if(widegtName === "getCasesByInformationImpactData"){
        url = helpers.restURL + "?module=siem&rest=incidentsbyinformationimpact&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + userID + "/"
    }
    else if(widegtName === "getCasesByMitreTacticsData"){
        url = helpers.restURL + "?module=siem&rest=incidentsbymitretactics&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + userID + "/"
    }
    else if(widegtName === "getCasesByMitreTechniquesData"){
        url = helpers.restURL + "?module=siem&rest=incidentsbymitretechniques&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + userID + "/"
    }
    else if(widegtName === "getCasesByRecoverabilityEffortData"){
        url =helpers.restURL + "?module=siem&rest=incidentsbyrecoverabilityeffort&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + userID + "/"
    }
    else if(widegtName === "getCasesByTargetData"){
        url =helpers.restURL + "?module=siem&rest=incidentsbytarget&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + userID + "/"
    }
    else if(widegtName === "getCasesByThreatActorData"){
        url =helpers.restURL + "?module=siem&rest=incidentsbythreatactor&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + userID + "/"
    }
    else if(widegtName === "getCasesByThreatVectorData"){
        url =helpers.restURL + "?module=siem&rest=incidentsbythreatvector&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + userID + "/"
    }
    else if(widegtName === "getCasesByPrioritiesData"){
        url =helpers.restURL + "?module=siem&rest=incidentsbypriorityontimerange&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/" + helpers.getCookie('companycode') + "/" + userID + "/"
    }
    else if(widegtName === "getClosedCasesData"){
        url =helpers.restURL + "?module=siem&rest=zonaincidentslist&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/thisweek/" + userID + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getClosedCasesThisMonthData"){
        url =helpers.restURL + "?module=siem&rest=monthlycases&q=" + helpers.getCookie('tenantcode') + "/closed/" + helpers.getCookie('companycode') + "/" + userID + "/"
    }
    else if(widegtName === "getInProgressCasesData"){
        url =helpers.restURL + "?module=siem&rest=zonainprogresslist&q=" + helpers.getCookie('tenantcode') + "/" + userID + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getMTTRByAnalystData"){
        url =helpers.restURL + "?module=siem&rest=getmttrbyanalyst&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/"
    }
    else if(widegtName === "getMTTRByCategoryData"){
        url = helpers.restURL + "?module=siem&rest=getmttrbycategory&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/"
    }
    else if(widegtName === "getMTTAAcknowledgeData"){
        url = helpers.restURL + "?module=siem&rest=getmeantimetoacknowledge&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/" +  helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getMTTRData"){
        url =helpers.restURL + "?module=siem&rest=getmeantimetorepair&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/" + userID + "/" +  helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getMTTTData"){
        url =helpers.restURL + "?module=siem&rest=getmeantimetotriage&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/" + userID + "/" +  helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getResolvedCasesData"){
        url =helpers.restURL + "?module=siem&rest=getresolvedalertscount&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/" + userID + "/" +  helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getResponseSlaData"){
        url =helpers.restURL + "?module=siem&rest=getresponsesla&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/" + userID + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getUnassignedCasesData"){
        url =helpers.restURL + "?module=siem&rest=dashboardunconfirmedincidents&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/-1/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getTopPlaybookUsedData"){
        url =helpers.restURL + "?module=user&rest=mostusedplaybook&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/" + userID + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getTopTasksUsedData"){
        url =helpers.restURL + "?module=user&rest=usertasks&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/" + userID + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getMyCasesData"){
        url =helpers.restURL + "?module=siem&rest=mycasescount&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('userid') + "/"+grouidsString+"/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }
    else if(widegtName === "getFeeds"){
        url = helpers.restURL + "?module=users&rest=getfeeds&q=" + helpers.getCookie('tenantcode') + "/data/"/* chandru changes - aaded extra parmeters for pdf data and response data */
    }
    else if(widegtName === "getOnCallUsersData"){
        url = helpers.restURL + "?module=users&rest=oncallusersdata&q=" + helpers.getCookie('tenantid') + "/"
    }
    else if(widegtName === "getOnCallUsersCount"){
        url = helpers.restURL + "?module=users&rest=oncallusersdata&q=" + helpers.getCookie('tenantid') + "/"
    }
    else if(widegtName === "getUnassignedCasesTileData"){
        url = helpers.restURL + "?module=siem&rest=getunassignedalertscount&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/"
    } 
    else if(widegtName ==="getCasesCountData"){
        url = helpers.restURL + "?module=siem&rest=getcasescount&q=" + helpers.getCookie('tenantcode') + "/" + paramsArray.userID + "/"
    }
    else if(widegtName === "getUserGroupTrendsPerWeek"){
        postData = paramsArray
    }
    else if(widegtName === "getUserGroupKillChainData"){
        postData = paramsArray
    }
    else if(widegtName === " getUserGroupCasesByPriorities"){
        postData = paramsArray
    }
    else if(widegtName === "getUserGroupCasesByStatus"){
        postData = paramsArray
    }
    else if(widegtName === "getUserGroupCasesByCategory"){
        postData = paramsArray
    }
    else if(widegtName === "getUserGroupEventByStatus"){
        postData = paramsArray
    }
    else if(widegtName === "getUserGroupClosedCases"){
        postData = paramsArray
    }
    else if(widegtName === "getUserGroupClosedCasesThisMonth"){
        postData = paramsArray
    }
    else if(widegtName === "getUserGroupIncomingSources"){
        postData = paramsArray
    }
    else if(widegtName === "getMTTAData"){
        postData = paramsArray
    }
    else if(widegtName === "getShiftTableData"){
        url =helpers.restURL + "?module=users&rest=getshifts&q=" + helpers.getCookie('companycode') + "/" + helpers.getCookie('tenantid') + "/"
    }
    else if(widegtName === "getPlaybookMonitoringData"){
        url =helpers.restURL + "?module=playbook&rest=countplaybookExecution&q=" + helpers.getCookie('tenantcode') + "/" + paramsArray.groupByTime + "/"
    }
    else if(widegtName === "getServiceNowCasesData"){
        url =helpers.restURL + "?module=user&rest=caseswithservicenowtask&q=" + helpers.getCookie('tenantcode') + "/" +  paramsArray.time + "/"
    }
    else if(widegtName === "getEventByStatusData"){
        typeChange = paramsArray.caseType
        url = helpers.restURL + "?module=siem&rest=geteventbystatus&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/" + paramsArray.userID + "/" + paramsArray.caseType + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/"
    }

    else if(widegtName === "getZendeskInstance"){
        url = helpers.restURL + "?module=user&rest=getzendeskinstance&q=" + helpers.getCookie('tenantcode') + "/";
    }   
    
    else if(widegtName === "getZendeskInstance"){
        url = helpers.restURL + "?module=user&rest=getzendesktickets&q=" + helpers.getCookie('tenantcode') + "/" + paramsArray.time + "/" + paramsArray.instanceID + "/";
    }
    // Author:Divya changes:- URL added for severity rest
    // --------------start------------------
    else if(widegtName === "getCasePanelSeverity"){
        url = helpers.restURL + "?module=siem&rest=severities&q=" + helpers.getCookie('tenantcode') + "/"
    }

    else if(widegtName === "getChangeSeverity"){
        if(helpers.getTenantCookie() === "master"){
            url = helpers.restURL + "?module=siem&rest=severities&q=" + paramsArray.tenantcode + "/"
        }else{
            url = helpers.restURL + "?module=siem&rest=severities&q=" + helpers.getCookie('tenantcode') + "/"
        }
    }
    else if(widegtName === "getQuickActionSeverity"){
        url = helpers.restURL + "?module=siem&rest=severities&q=" + helpers.getCookie('tenantcode') + "/"
    }

    else if(widegtName === "getSeverity"){
        url = helpers.restURL + "?module=siem&rest=severities&q=" + helpers.getCookie('tenantcode') + "/"
    }

    else if(widegtName === "getTaskSeverity"){
        url = helpers.restURL + "?module=siem&rest=severities&q=" + helpers.getCookie('tenantcode') + "/"
    }
    // ----------------end-------------------
    if(type === "GET"){
    return async function (dispatch) {
      await axios({
            method: "GET",
            url: url,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (widegtName === "getEventByStatusData") {
                helpers.setCachetoDB(widegtName+typeChange,JSON.stringify(res));
            } else {
                helpers.setCachetoDB(widegtName,JSON.stringify(res));
            }
            dispatch({ type: widegtName, payload: res});
        }).catch(function (error) {
            dispatch({ type:widegtName, payload: [] });
            console.log(error);
        });
    };
    }else if(type === "POST"){
        return function (dispatch) {
            axios({
                method: "POST",
                url: helpers.restURL,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                dataType: "json",
                data: postData
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                helpers.setCachetoDB(widegtName,JSON.stringify(res));
                    dispatch({ type: widegtName, payload: res});
            }).catch(function (error) {
                dispatch({ type:widegtName, payload: [] });
                console.log(error);
            });
        };
    }
} 

// export function getServiceNowCasesData(time) {
    
//     return function (dispatch) {
//         let userID = "";
//         if (helpers.getCookie('displayallusersdata') === "y") {
//             userID = "-1";
//         } else {
//             userID = helpers.getCookie('userid');
//         }
//         // console.log("data::",time)
//         axios({
//             method: "GET",
//             url: helpers.restURL + "?module=user&rest=caseswithservicenowtask&q=" + helpers.getCookie('tenantcode') + "/" +  time + "/",
//             headers: {
//                 access_token: helpers.getCookie('accesstoken'),
//                 jwt_token: helpers.getCookie('jwt_token'),
//                 "Content-Type": "application/json; charset=utf-8"
//             },
//             responseType: "json"
//         }).then(res => {
//             helpers.checkSessionTimeout(res.data.sessionexpired);
//             if (res.data.success) {
//                 dispatch({ type: "getServiceNowCasesData", payload: res});
//                 // console.log("inside ticket data",res);
//             }
//         }).catch(function (error) {
//             dispatch({ type: "getServiceNowCasesData", payload: [] });
//             console.log(error);
//         });
//     };
// }

// export function  getEventByStatusData(caseType) {
    
//     return function (dispatch) {
//         let userID = "";
//         if (helpers.getCookie('displayallusersdata') === "y") {
//             userID = "-1";
//         } else {
//             userID = helpers.getCookie('userid');
//         }
//         axios({
//             method: "GET",
//             url: helpers.restURL + "?module=siem&rest=geteventbystatus&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/" + userID + "/" + caseType + "/" + helpers.getCookie('dataTableFromDate') + "/" + helpers.getCookie('dataTableToDate') + "/",
//             headers: {
//                 access_token: helpers.getCookie('accesstoken'),
//                 jwt_token: helpers.getCookie('jwt_token'),
//                 "Content-Type": "application/json; charset=utf-8"
//             },
//             responseType: "json"
//         }).then(res => {
//             helpers.checkSessionTimeout(res.data.sessionexpired);
//             if (res.data.success) {
//                 dispatch({ type: " getEventByStatusData", payload: res});
//                 // console.log("inside event by status data",res);
//             }
//         }).catch(function (error) {
//             dispatch({ type: "g getEventByStatusData", payload: [] });
//             console.log(error);
//         });
//     };
// }



export function getplaybookData(groupByTime) {
    
    return function (dispatch) {
        let userID = "";
        if (helpers.getCookie('displayallusersdata') === "y") {
            userID = "-1";
        } else {
            userID = helpers.getCookie('userid');
        }
        axios({
            method: "GET",
            url:helpers.restURL + "?module=playbook&rest=countplaybookExecution&q=" + helpers.getCookie('tenantcode') + "/" + groupByTime + "/",
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                dispatch({ type: "getplaybookData", payload: res});
            }
        }).catch(function (error) {
            dispatch({ type: "getplaybookData", payload: [] });
            console.log(error);
        });
    };
}