export function serializeDiagram(state = false, action) {
    switch (action.type) {
        case "serializeDiagram":
            return action.payload
    }
    return state
}

export function cloneSelected(state = false, action) {
    switch (action.type) {
        case "cloneSelected":
            return action.payload
    }
    return state
}

export function fitToScreen(state = false, action) {
    switch (action.type) {
        case "fitToScreen":
            return action.payload
    }
    return state
}

