export function updateTaskResponse(value) {
    return {
        type: "updateTaskResponse",
        payload: value
    }
}

export function updatePlaybookResponse(value) {
    return {
        type: "updatePlaybookResponse",
        payload: value
    }
}

export function taskHeaderColors(value) {
    return {
        type: "taskHeaderColors",
        payload: value
    }
}

export function updatePlaybookExecutionResponse(value) {
    return {
        type: "updatePlaybookExecutionResponse",
        payload: value
    }
}