import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Line } from "react-chartjs-2";
import helpers from "../helpers";
import { withRouter } from "react-router-dom";

class TimeLineChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labelsArray: [],
            dataArray: [],
        }
    }

    componentDidMount() {

        if (typeof this.props.chartData !== 'undefined') {
            let dataArray = [];
            let labelsArray = [];
            let maxDate;
            let minDate;
            let aggr_data = this.props.chartData;

            for (let i = 0; i < aggr_data.length; i++) {
                let obj = aggr_data[i];
                let dateObj;
                if (typeof obj._source.reporttime !== 'undefined' && obj._source.reporttime !== null) {

                    let date = obj._source.reporttime.split("-");
                    dateObj = new Date(date[0], (date[1] - 1), date[2].substring(0, 2));

                    let Year = date[0];
                    let Month = date[1] - 1;
                    let Monthdate = date[2].substring(0, 2);

                    maxDate = new Date(Year, Month + 1, Monthdate).toLocaleDateString("en-US");
                    minDate = new Date(Year, Month - 1, Monthdate).toLocaleDateString("en-US");
                    if (i == 0) {
                        labelsArray.push(minDate);
                        dataArray.push(null);
                    }

                } else if (typeof obj._source.processedts !== 'undefined' && obj._source.processedts !== null) {
                    dateObj = new Date((parseInt(obj._source.processedts)) * 1000);
                    let Month = dateObj.getMonth();
                    let Year = dateObj.getFullYear();
                    maxDate = new Date(Year, Month + 1, 0).toLocaleDateString("en-US");
                    minDate = new Date(Year, Month - 1, 0).toLocaleDateString("en-US");
                    if (i == 0) {
                        labelsArray.push(minDate);
                        dataArray.push(null);
                    }
                }

                labelsArray.push(dateObj.toLocaleDateString("en-US"));
                dataArray.push(obj._source.confidence);

                if (i + 1 == aggr_data.length) {
                    labelsArray.push(maxDate);
                    dataArray.push(null);
                }
            }
            this.setState({ dataArray, labelsArray });
        }
    }

    render() {
        const ChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scaleFontColor: "grey",
            tooltips: {
                enabled: true,
                cornerRadius: 4,
                caretSize: 4,
                xPadding: 16,
                yPadding: 10,
                titleFontStyle: 'normal',
                titleMarginBottom: 2,
                callbacks: {
                    title: function (tooltipItems, data) {
                        let idx = tooltipItems[0].xLabel;
                        let dataobject = data.datasets[idx - 1];
                        return tooltipItems[0].xLabel + " (" + tooltipItems[0].yLabel + ")"; //do something with title
                    },
                    label: function (tooltipItems, data) {
                        return '';
                    }
                }
            },
            animation: {
                duration: 2000
            },
            plugins: {
                datalabels: {
                    display: false
                }
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        color: 'rgba(171,171,171,1)',
                        lineWidth: 0.5
                    },
                    ticks: {
                        beginAtZero: true,
                        fontColor: "grey",
                    }
                }],
                yAxes: [{
                    gridLines: {
                        color: 'rgba(171,171,171,1)',
                        lineWidth: 0.5
                    },
                    ticks: {
                        beginAtZero: true,
                        fontColor: "grey",
                        fixedStepSize: 1,
                    }
                }]
            },
            legend: {
                display: false
            }
        };

        const colors = helpers.getColors();
        const chartProps = {
            data: {
                showTooltips: true,
                labels: this.state.labelsArray,
                datasets: [{
                    data: this.state.dataArray,
                    fill: false,
                    pointHoverRadius: 5,
                    backgroundColor: "#F44336",
                    borderColor: "#F44336",
                    borderWidth: 3
                }]
            }, options: ChartOptions
        };

        return (
            <Line {...chartProps} />
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

const exportTimeLineChart = withRouter(connect(mapStateToProps, mapDispatchToProps)(TimeLineChart));
export default exportTimeLineChart;
