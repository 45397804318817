import React from 'react';
import '../sass/style.css';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
class LoadingSpinnner extends React.Component {
    render() {
        return (
            <div className={"pageLoader " + this.props.showSpinner}>
                <div className="loader">
                    <BounceLoader
                        css={override}
                        sizeUnit={"px"}
                        size={60}
                        color={'#09897e'}
                        loading={true}
                    />
                </div>
                <div style={{ top: "50%", position: "fixed", textAlign: "center", width: "100%" }}>
                    <h3 style={{ color: "inherit" }}>{typeof this.props.loadingText !== 'undefined' && this.props.loadingText !== null && this.props.loadingText !== "" ? this.props.loadingText : ""}</h3>
                </div>
            </div>
        )
    }
}

export default LoadingSpinnner;
