import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateTaskResponse, updatePlaybookResponse, updatePlaybookExecutionResponse } from "../actions/taskActions";
import { updateReport } from "../actions/reportAction";
import { withRouter } from 'react-router-dom';
import helpers from "../helpers";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import axios from "axios";
import { updateSAMLResponse } from "../actions/samlAction";
const restURL = helpers.restURL;
var _ws = null;
var processedTasks = [];
var processedPlaybooks = [];
var appsRequests =  [];
var terminate = false;
class Sockets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showNotificationModal: false,
            requestData: [],
            notificationMessage: "",
            showSessionNotificationModal: false,
            sessionNotificationMessage: "",
            sessionFinalNotificationMessage: "",
            showFinalSessionNotificationModal: false,
            intervalID: null
        }
        this.initWebSockets = this.initWebSockets.bind(this);
        this.checkMultipleLogin = this.checkMultipleLogin.bind(this);
        this.sendWSRequest = this.sendWSRequest.bind(this);
        this.wsFunctions = this.wsFunctions.bind(this);
        this.updateTask = this.updateTask.bind(this);
        this.updatePlaybook = this.updatePlaybook.bind(this);
        this.toggleNotificationModal = this.toggleNotificationModal.bind(this);
        this.redirect = this.redirect.bind(this);
        this.updateReport = this.updateReport.bind(this);
        this.updatePlaybookExecutionResponse = this.updatePlaybookExecutionResponse.bind(this);
        this.checkUserSessionExpiration = this.checkUserSessionExpiration.bind();
        this.toggleSessionNotificationModal = this.toggleSessionNotificationModal.bind(this);
        this.ignoreSessionExtension = this.ignoreSessionExtension.bind(this);
        this.extendSessionTime = this.extendSessionTime.bind(this);
        this.toggleFinalSessionNotificationModal = this.toggleFinalSessionNotificationModal.bind(this);
        this.closeFinalSessionNotificationModal = this.closeFinalSessionNotificationModal.bind(this);
        this.checkAccessTokenExpiryTimeFromDB = this.checkAccessTokenExpiryTimeFromDB.bind(this);
    }

    componentWillUnmount(){
        try{
            if (_ws !== null) {
                terminate = true;
                    _ws.close();
                }
            
        }catch(e){
            console.log("inside error",e);
        }
        
    }

    componentDidMount() {
        const componentProps = this;
        this.initWebSockets(componentProps);
        let requestData = this.state.requestData;
        if (typeof this.props.requestData !== 'undefined') {
            let socketRequestData = this.props.requestData;
            if (socketRequestData.length > 0) {
                requestData = requestData.concat(socketRequestData);
                this.setState({ requestData });
            }
        }

        if (this.state.intervalID === null) {
            let intervalID = setInterval(() => {
                let userData = helpers.getSessionStorage("userData");
                let loginstatus = helpers.getCookie("newlogin")
                if (userData !== null && userData !== "" && typeof userData !== 'undefined' && loginstatus == "true") {
                    this.checkMultipleLogin();
                }
                this.sendWSRequest();
                this.checkUserSessionExpiration();
            }, 5000);
            this.setState({ intervalID });
        }
    }

    toggleNotificationModal() {
        this.setState({ showNotificationModal: true });
    }

    toggleSessionNotificationModal = () => {
        this.setState({ showSessionNotificationModal: true });
        this.setState({ sessionNotificationMessage: "Session will be expired in 5 minutes. Would you like to extend the session by " + helpers.getCookie("session_extend_time_in_mins") + " minutes?" });
    }

    toggleFinalSessionNotificationModal = () => {
        helpers.setCookie("final_session_modal_shown", "yes");
        this.setState({ showFinalSessionNotificationModal: true });
        this.setState({ sessionFinalNotificationMessage: "Session will be expired in 1 minute. Please save any unsaved changes" });
    }

    closeFinalSessionNotificationModal() {
        this.setState({ showFinalSessionNotificationModal: false });
    }

    redirect() {
        this.setState({ showNotificationModal: false });
        setTimeout(() => {
            this.props.history.push("/logout");
        }, 100);
    }

    componentWillReceiveProps(nextProps) {
        if (typeof nextProps.requestData !== 'undefined') {
            if (nextProps.requestData.length > 0) {
                let requestData = this.state.requestData;
                requestData = requestData.concat(nextProps.requestData);
                this.setState({ requestData });
            }
        }
    }

    checkMultipleLogin() {
        helpers.setCookie('newlogin', "false", 10);
        let userData = helpers.getSessionStorage("userData");
        if (userData !== null && userData !== "" && typeof userData !== 'undefined') {
            let useObj = JSON.parse(userData);
            let url = restURL + '?rest=checkusermultiplelogin&module=user&q=' + helpers.getCookie('companycode') + "/" + helpers.getCookie('userid') + "/" + useObj.sessionid + "/";
            axios({
                method: "GET",
                url: url,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                responseType: "json"
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
            }).catch(function (error) {
                console.error(JSON.stringify(error));
            });
        }
    }

    checkUserSessionExpiration = () => {
        let userData = helpers.getSessionStorage("userData");
        if (userData !== null && userData !== "" && typeof userData !== 'undefined') {

            let currentDate = new Date();
            let currentTimeInMillies = currentDate.getTime();
            let difference = helpers.getCookie('session_expire_time') - currentTimeInMillies;
            let differenceInSecs = (difference / 1000);
            if (differenceInSecs > 0) {
                console.log('remaining session time (in seconds) ', differenceInSecs);
                if (helpers.getCookie('seesion_extension_requested') === "first") {
                    if (differenceInSecs <= 300) {
                        if (helpers.getCookie('final_session_modal_shown') === "no") {
                            this.toggleSessionNotificationModal();
                        }
                    }
                }
                if (helpers.getCookie('final_session_modal_shown') === "no") {
                    if (differenceInSecs <= 60) {
                        this.setState({ showSessionNotificationModal: false });
                        this.toggleFinalSessionNotificationModal();
                    }
                }

                //TO CHECK IF THE SESSION IS EXTENDED THROUGH SLEEP TASK OR ANY OTHER WAY
                // if (differenceInSecs <= 10) {
                //     this.checkAccessTokenExpiryTimeFromDB();
                // }

            } else {
                helpers.setLocalStorage("sessionExpiredSocket","true");
                console.log("session expired");
                helpers.checkSessionTimeout(true);
            }
        }
    }

    extendSessionTime() {
        this.setState({ showSessionNotificationModal: false });
        let userData = helpers.getSessionStorage("userData");
        if (userData !== null && userData !== "" && typeof userData !== 'undefined') {
            let useObj = JSON.parse(userData);
            let url = restURL + '?rest=extendusersessiontime&module=user&q=' + helpers.getCookie('tenantcode') + "/" + useObj.email + "/" + useObj.sessionid + "/";
            axios({
                method: "GET",
                url: url,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                responseType: "json"
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (res.data.success) {
                    helpers.setCookie('jwt_token', res.data.data.jwt_token, 10);
                    helpers.setCookie("session_expire_time", res.data.data.expirytime);
                    helpers.setCookie("seesion_extension_requested", "yes");
                }
            }).catch(function (error) {
                console.error(JSON.stringify(error));
            });
        }
    }

    ignoreSessionExtension() {
        this.setState({ showSessionNotificationModal: false }, () => {
            helpers.setCookie("seesion_extension_requested", "no");
        });
    }

    initWebSockets(componentProps) {
        let _wsurl = helpers.getWSURL();
        _ws = new WebSocket(_wsurl);
        this.wsFunctions(componentProps);
    }

    sendWSRequest() {

        let socketRequest = this.state.requestData;
        if(helpers.getLocalStorage("resume") == "true"){
            if(helpers.getLocalStorage("resumeExecId") != null && typeof helpers.getLocalStorage("resumeExecId") != "undefined" && helpers.getLocalStorage("resumeExecId") != ""){           
                processedPlaybooks = processedPlaybooks.filter(item=>item != helpers.getLocalStorage("resumeExecId"));
                helpers.setLocalStorage("resume","false");
                helpers.setLocalStorage("resumeExecId","");
            }
        }
        if (socketRequest.length > 0) {
            if (_ws !== null && _ws.readyState === 1) {
                for (let i = 0; i < socketRequest.length; i++) {

                    let isInArray = false;
                    if (socketRequest[i].rest === "taskUpdates") {
                        isInArray = processedTasks.includes(socketRequest[i].task_request_id);
                    } else if (socketRequest[i].rest === "playBookUpdates") {
                        isInArray = processedPlaybooks.includes(socketRequest[i].playbook_exec_id);
                    } else if (socketRequest[i].rest === "playBookWorkPlanUpdates") {
                        isInArray = processedPlaybooks.includes(socketRequest[i].playbook_exec_id);
                    } else if (socketRequest[i].rest === "playBookWorkPlanUpdatesForSubPlaybookV2") {
                        isInArray = processedPlaybooks.includes(socketRequest[i].peid);
                    }
                    // else if (socketRequest[i].rest === "checksForInstanceStatus") {
                    //     isInArray = processedPlaybooks.includes("checksForInstanceStatus");
                    // }

                    if (!isInArray) {
                        _ws.send(JSON.stringify(socketRequest[i]));
                    }
                    // else {
                    //     socketRequest.splice(i, 1);
                    //     processedPlaybooks = 	processedPlaybooks.filter(it => it != "checksForInstanceStatus")
                    //     this.setState({ socketRequest });
                    // }
                }
            }
        }
    }

    updateTask(response) {
        this.props.updateTaskResponse(response);
    }

    updatePlaybook(response) {
        this.props.updatePlaybookResponse(response);
    }

    updatePlaybookExecutionResponse(response) {
        this.props.updatePlaybookExecutionResponse(response);
    }

    updateReport(response) {
        this.props.updateReport(response);
    }

    updateSAMLResponse(response) {
        this.props.updateSAMLResponse(response)
    }

    wsFunctions(componentProps) {
        if (_ws !== null) {
            _ws.onopen = (evt) => {
                console.log("Socket OPEN");
            }
        }

        _ws.onclose = (evt) => {
            console.log("SOCKET CLOSED");
            _ws = null;
            if(!terminate){
                componentProps.initWebSockets(componentProps);
             }
        }

        _ws.onmessage = (evt) => {

            let data = JSON.parse(evt.data);

            if (data.type === "widget") {

                if (data.widget === "taskUpdates") {
                    if (data.success) {
                        let response = data.data[0];
                        if (response.processed === "y") {
                            componentProps.updateTask(response);
                            processedTasks = processedTasks.concat(response.requestid);
                        }
                    }
                } else if (data.widget === "playBookUpdates") {
                    let response = data;
                    if (data.success) {
                        processedPlaybooks = processedPlaybooks.concat(response.data.DivID);
                        componentProps.updatePlaybook(response.data);

                        if (typeof this.props.getSingleIncident !== 'undefined') {
                            this.props.getSingleIncident(undefined, "socket");
                        }
                    }
                } else if (data.widget === "playBookWorkPlanUpdates") {
                    let response = data;
                    response["childplaybook"] = false;
                    if (response.data !== null && typeof response.data.status !== 'undefined' && response.data.status !== null) {
                        if (response.data.status === "completed") {
                            processedPlaybooks = processedPlaybooks.concat(response.data.DivID);
                            if (typeof this.props.getSingleIncident !== 'undefined') {
                                this.props.getSingleIncident(undefined, "socket");
                            }
                        }
                        if (response.data.status === "failed") {
                            setTimeout(() => {
                                processedPlaybooks = processedPlaybooks.concat(response.data.DivID);
                              }, 7000);
                            if (typeof this.props.getSingleIncident !== 'undefined') {
                                this.props.getSingleIncident(undefined, "socket");
                            }
                        }
                    }
                    componentProps.updatePlaybookExecutionResponse(response);
                }
                // else if (data.widget === "checksForInstanceStatus") {
                //         let response = data;
                //         if (typeof this.props.updateAppStatus !== 'undefined') {
                //             processedPlaybooks = processedPlaybooks.concat("checksForInstanceStatus");
                //               this.props.updateAppStatus(response);
                //         }
                //
                //           componentProps.updatePlaybookExecutionResponse(response.data);
                //   }
                  else if (data.widget === "playBookWorkPlanUpdatesForSubPlaybookV2") {
                    let response = data;
                    response["childplaybook"] = true;
                    if (data.success) {
                        if (typeof response.data.status !== 'undefined' && response.data !== null && response.data.status !== null) {
                            if (response.data.status === "completed" || response.data.status === "failed") {
                                processedPlaybooks = processedPlaybooks.concat(response.peid);

                                if (typeof this.props.getSingleIncident !== 'undefined') {
                                    this.props.getSingleIncident(undefined, "socket");
                                }
                            }
                        }
                        componentProps.updatePlaybookExecutionResponse(response);
                    } else {
                        componentProps.updatePlaybookExecutionResponse(response);
                    }
                }
            } else if (data.type === "authentication") {
                if (data.data.userid === parseInt(helpers.getCookie('userid'))) { //&& data.data.companycode === helpers.getCookie('companycode')) {
                    let userData = helpers.getSessionStorage("userData");
                    if (userData !== null && userData !== "" && typeof userData !== 'undefined') {
                        let useObj = JSON.parse(userData);
                        if (data.data.sessionid !== useObj.sessionid) {
                            // console.log("Multiple login found");
                            //Kavya Changes - adding locastorage to identify duplicate login - 05/07/2023
                            helpers.setLocalStorage("isDuplicatelogin","true");
                            componentProps.setState({ notificationMessage: "We found new login from other system. This session will be logged out." })
                            componentProps.toggleNotificationModal();
                        }
                    }
                }
            } else if (data.type == "rolesupdates") {
                let _userData = JSON.parse(helpers.getSessionStorage("userData"));
                let roleID = data.data.id;
                let modules = data.data.module;
                let userRoles = _userData.roles;
                let userModules = _userData.modules;
                let changesInModules = false;

                for (let r = 0; r < userRoles.length; r++) {
                    if (roleID == userRoles[r].id) {
                        for (let m = 0; m < modules.length; m++) {
                            let moduleData = modules[m];
                            for (let um = 0; um < userModules.length; um++) {
                                let userModuleData = userModules[um];
                                if (moduleData.id == userModuleData.moduleid && moduleData.name == userModuleData.modulename) {
                                    if (moduleData.readonly == userModuleData.readonly && moduleData.readwrite == userModuleData.readwrite) {
                                        // console.log("NO changes for " + moduleData.name);
                                    } else {
                                        changesInModules = true;
                                        break;
                                    }
                                }
                                if (changesInModules) break;
                            }
                            if (changesInModules) break;
                        }
                    }
                    if (changesInModules) break;
                }

                if (changesInModules) {
                    componentProps.setState({ notificationMessage: "We found changes for your profile from Admin. Please login back." })
                    componentProps.toggleNotificationModal();
                }
            } else if (data.type == "userdeleted") {
                let _userData = JSON.parse(helpers.getSessionStorage("userData"));
                // console.log("userData", data.data.id, data.data.status);
                if (_userData.id == data.data.id && data.data.status == "deleted") {
                    // console.log("status deleted");
                    componentProps.setState({ notificationMessage: "Your account has been deleted." });
                    componentProps.toggleNotificationModal();
                    componentProps.props.history.push('logout');
                }
            } else if (data.type == "reportUpdates") {
                if(data.success){
                 if (parseInt(data.data.userid) === parseInt(helpers.getCookie('userid'))) { //&& data.data.companycode === helpers.getCookie('companycode')) {
                    if (data.success && (data.data.report_type == "application"|| data.data.report_type =="master") && this.props.type == "securaa") {
                        helpers.securaaNotification(<div>{"Report "+data.data.title+" downloaded successfully"}. <div onClick={() => {helpers.downloadFile(helpers._reportsDownloads + data.data.filepath,data.data.filename)}} className="pointer">Click here to download</div></div>,"success",20);
                        data.data.success = true;
                        componentProps.updateReport(data.data);
                    }
                 }
                }else{
                    if (parseInt(data.data.userid) === parseInt(helpers.getCookie('userid'))) {
                        if ((data.data.report_type == "application"|| data.data.report_type =="master") && this.props.type == "securaa") {
                            if (data.data.message !== null && data.data.message !== undefined && data.data.message !== ""){
                                helpers.securaaNotification(<div>{data.data.message}</div>,"error",20);
                            }else{
                                helpers.securaaNotification(<div>{"Unable to download Report "+data.data.title}</div>,"error",20);

                            }
                            data.data.success = false;
                            componentProps.updateReport(data.data);
                        }
                    }
                }
            } else if (data.type == "samllogin") {
                componentProps.updateSAMLResponse(data);
            }
        }
    }

    checkAccessTokenExpiryTimeFromDB() {
        var accessToken = helpers.getCookie("accesstoken");

        var url = restURL + "?module=user&rest=checkaccesstokenexpirytimefromdb&q=" + accessToken + "/";
        axios({
            method: "GET",
            url: url,
            headers: {
                access_token: accessToken,
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8",

            },
            responseType: "json"
        }).then(res => {
            //helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                console.log("expiry time for token ", accessToken, " is", res.data.data.expirytime);
                console.log("jwt_token for token ", accessToken, " is", res.data.data.jwt_token);
                if (res.data.data.expirytime > helpers.getCookie('session_expire_time')) {

                    if (res.data.data.jwt_token !== null && res.data.data.jwt_token !== "" && typeof res.data.data.jwt_token !== 'undefined') {
                        helpers.setCookie('jwt_token', res.data.data.jwt_token, 10);
                    }
                    helpers.setCookie("session_expire_time", res.data.data.expirytime);
                    helpers.setCookie("seesion_extension_requested", "yes");
                }
            } else {
                this.setState({
                    errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>
                });
            }
            this.setState({ loading: "hide" });
        }).catch(function (error) {
            console.log(error);
        });

    }

    render() {

        let modalStyle = { color: "" };
        if (typeof this.props.theme !== 'undefined') {
            modalStyle = this.props.theme.modal;
        }

        const fontColor = this.props.theme === null || this.props.theme === undefined ? "#cfd2da": this.props.theme ? this.props.theme.color : "#2A3139";
        return (
            <div>
                <Modal fade={false} isOpen={this.state.showNotificationModal} toggle={this.toggleNotificationModal} backdrop="static">
                    <ModalHeader toggle={this.redirect} style={modalStyle}><span style={{ color: fontColor, fontSize: "20px" }}>Message</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <h6 style={{ color: fontColor }}>{this.state.notificationMessage}</h6>
                        <div className="pull-right" style={{ marginTop: "20px" }}>
                            <Button size="sm" color="secondary" onClick={this.redirect}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal fade={false} isOpen={this.state.showSessionNotificationModal} toggle={this.toggleSessionNotificationModal} backdrop="static">
                    <ModalHeader style={modalStyle}><span style={{ color: fontColor, fontSize: "20px" }}>Message</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <h6 style={{ color: fontColor }}>{this.state.sessionNotificationMessage}</h6>
                        <div className="pull-right" style={{ marginTop: "20px" }}>
                            <Button size="sm" color="success" onClick={this.extendSessionTime}>Yes</Button>
                            <Button size="sm" color="secondary" onClick={this.ignoreSessionExtension}>No, Thanks</Button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal fade={false} isOpen={this.state.showFinalSessionNotificationModal} toggle={this.toggleFinalSessionNotificationModal} backdrop="static">
                    <ModalHeader style={modalStyle}><span style={{ color: fontColor, fontSize: "20px" }}>Message</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <h6 style={{ color: fontColor }}>{this.state.sessionFinalNotificationMessage}</h6>
                        <div className="pull-right" style={{ marginTop: "20px" }}>
                            <Button size="sm" color="success" onClick={this.closeFinalSessionNotificationModal}>Ok</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateTaskResponse: updateTaskResponse,
        updatePlaybookResponse: updatePlaybookResponse,
        updateReport: updateReport,
        updatePlaybookExecutionResponse: updatePlaybookExecutionResponse,
        updateSAMLResponse: updateSAMLResponse
    }, dispatch)
}

const exportSockets = withRouter(connect(mapStateToProps, mapDispatchToProps)(Sockets));
export default exportSockets;
