import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { getCaseCategories, getKillChainStage, getUsersList, getUsersGroup } from "../actions/caseActions";
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Col, Row } from "reactstrap";
import NotificationComponent from "./NotificationComponent";
import helpers from "../helpers";
import axios from "axios";
import ToastMessage from "./ToastMessage";
import { updateMycase } from "../actions/myCasesAction";
import { reloadComponentsForTenant } from "../actions/tenantAction";
import CreatableSelect from 'react-select/creatable';
import Select, { components } from 'react-select-v2';
import { getSoarWidgetData } from "../actions/widgets/soarWidgetAction"; //Divya

import ReactTooltip from 'react-tooltip';
const restURL = helpers.restURL;

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',
        padding: 1,
    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 200,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
    }
}
const customStyles2 = {
    option: (provided, state) => ({
        ...provided, borderBottom: '1px solid whitesmoke', color: "#2A3139", padding: 5,
    })
}
const groupStyles = {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-between',
};

const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: "12px",
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

var formatGroupLabel = options => (
    <div style={groupStyles}>
        <span style={{ fontSize: "14px" }}>{options.label}</span>
        <span style={groupBadgeStyles}>{options.options.length}</span>
    </div>
);


export class QuickActionCase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            caseDescription: "",
            sourceIP: "",
            destinationIP: "",
            caseUser: "",
            caseCategory: "",
            caseCategoryName: "---select---",
            caseSeverity: "",
            caseTenant: "",
            caseStatus: "",
            caseAssignee: "",
            caseAssigneeName: "---select---",
            caseKillChainStage: "",
            caseKillChainStageName: "---select---",
            caseDescriptionError: "",
            sourceIPError: "",
            destinationIPError: "",
            caseUserError: "",
            caseCategoryError: "",
            caseSeverityError: "",
            caseTenantError: "",
            caseStatusError: "",
            caseAssigneeError: "",
            caseKillChainStageError: "",
            categoryDropdownData: [],
            killChainDropdownData: [],
            userDropdownData: [],
            showIncidentModal: false,
            showNotificationModal: false,
            notificationMessage: "",
            btnDisabled: true,
            caseHash: "",
            caseHashError: "",
            caseEmail: "",
            caseEmailError: "",
            caseURL: "",
            caseURLError: "",
            showMessage: false,
            messageType: "",
            toastMessage: "",
            asigneeType: "",
            selectedTenant: "",
            tagsData: [{ value: 'high', label: 'high' }, { value: 'low', label: 'low' }, { value: 'strong', label: 'strong' }, { value: 'quadrel', label: 'quadrel' }, { value: 'quick', label: 'quick' }, { value: 'medium', label: 'medium' }, { value: 'length', label: 'length' }, { value: 'proxy', label: 'proxy' }, { value: 'task', label: 'task' }, { value: 'view', label: 'view' }, { value: 'play', label: 'play' }, { value: 'book', label: 'book' }, { value: 'risk', label: 'risk' }, { value: 'action', label: 'action' }, { value: 'comp', label: 'comp' }],
            selectedTag: [],
            metadataAll: [],
            metadatalist: [],
            metadataCustom: [],
            currentCategory: "",
            severitylist: [],
            sourceemail: "",
            destinationemail: "", sourceemailError: "", destinationemailError: "",useJSON:false,caseJSON:"",caseJSONError:"",btnDisabledJSON:true,viewJSONModal:false, //added thse last 3 caseJSON:"",caseJSONError:"",btnDisabledJSON:true for json as input preetham may26th 2023
            defaultJSONValue:[{"securaa_hashes":["1c46a11aa24a552748bb28203f23de6a0efc30a037fa3b06c03f0861c6ed1137","e6f8b8e63424486c2ddbcce1645f9e2215d15aa293dab8a2b9bbc0e6a99d724d","86d4ccacadc4a03f13d6be511bf0403dc84544bfc6deb4a26919164a6d2ce018"],"securaa_description":"JSON Input Case description","securaa_case_user":"john","securaa_hosts":["goalgoof.com","stilox.com","theteflacademy.co.uk"],"securaa_source_ips":["132.203.149.245","249.98.42.173","2.53.146.183"],"securaa_destination_ips":["172.31.20.10","192.168.20.20","10.25.62.31"],"securaa_email_ids":["john@stilox.com","admin@xyz.com"],"securaa_urls":["http://www.sample.org/head","http://www.system.info/play","http://www.sample.edu/crack#theory"],"securaa_source_email":["admin@xyz.com","cyber@stilox.com"],"securaa_destination_email":["admin1@xyz.com","cyber1@stilox.com"],"key1":"value1","key2":"value2","key3":"valu3"}]
        };
    }

    componentDidMount() {

        let { showIncidentModal } = this.props;
        if(showIncidentModal){
            setTimeout(() => {
            this.getIncidentsTags();
            this.getMetaData();
            this.props.getCaseCategories();
            this.props.getKillChainStage();
            this.props.getUsersList();
            this.props.getUsersGroup();
            // this.getseverityLists();
            this.props.getSoarWidgetData("getChangeSeverity", "GET", []); // Author:- Divya Changes:- calling getSoarWidgetData
        }, 10);
        }
        let selectedTenant = helpers.getCookie("selectedTenant");
        this.setState({ showIncidentModal, selectedTenant });
    }

    // getseverityLists() {
    //     var url = restURL + "?module=siem&rest=severities&q=" + helpers.getCookie('tenantcode') + "/";
    //     axios({
    //         method: "GET",
    //         url: url,
    //         headers: {
    //             access_token: helpers.getCookie("accesstoken"),
    //             jwt_token: helpers.getCookie('jwt_token'),//author : Preetham Date:May 12th 2023 removing commented code or adding jwt code in headers SDEV-2812
    //             "Content-Type": "application/json; charset=utf-8",

    //         },
    //         responseType: "json"
    //     }).then(res => {
    //         helpers.checkSessionTimeout(res.data.sessionexpired);
    //         if (res.data.success) {
    //             if (res.data.data != null) {
    //                 this.setState({
    //                     severitylist: res.data.data
    //                 })
    //             } else {
    //                 this.setState({
    //                     severitylist: []
    //                 })
    //             }
    //         } else {
    //             this.setState({
    //                 errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>
    //             });
    //         }
    //         this.setState({ loading: "hide" });
    //     }).catch(function (error) {
    //         console.log(error);
    //     });
    // }

    // Author: Divya    Description: Changing from ajax to redux
	// ---------------start------------
    preLoadData (res) {
        try {
            if (typeof res.data != "undefined") {
                helpers.checkSessionTimeout(res.sessionexpired);
                if (res.success) {
                    if (res.data != null) {
                        this.setState({
                        severitylist: res.data
                        })
                    } else {
                        this.setState({
                        severitylist: []
                        })
                    }
                } else {
                    this.setState({
                    errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>
                    });
                }
            this.setState({ loading: "hide" });
            }
        }
        catch(error) {
            console.log(error);
        }
    }
     // ----------------end-----------------
    componentWillReceiveProps(nextProps) {
        try{
        let { showIncidentModal } = nextProps;

        let selectedTenant = helpers.getCookie("selectedTenant");
        this.setState({ showIncidentModal, selectedTenant });

        if (showIncidentModal === true) {
            setTimeout(() => {
                this.setUserData();
            }, 10);
            //this.setUserData();
          
        }

        if(nextProps.showIncidentModal != this.props.showIncidentModal){
            this.getMetaData();
            this.props.getCaseCategories();
            this.props.getKillChainStage();
            this.props.getUsersList();
            this.props.getUsersGroup();
            // this.getseverityLists();
            this.props.getSoarWidgetData("getChangeSeverity", "GET", []); // Author:- Divya Changes:- calling getSoarWidgetData
        }

        if (nextProps.currentTenant !== this.props.currentTenant) {
            if(helpers.getCookie("tenantcode") !== "master"){
                this.props.getCaseCategories();
            }
        }

        if (typeof nextProps.categories !== 'undefined' && nextProps.categories !== null && nextProps.categories.length > 0) {
            let sortArray = helpers.sortArray(nextProps.categories, "name");
            setTimeout(() => {
                this.setState({ categoryDropdownData: sortArray }, function () {
                    this.loadCategoriesData();
                });
            }, 10);
            // this.setState({ categoryDropdownData: sortArray }, function () {
            //     //this.loadCategoriesData();
            // });
        }
        // Author:- Divya changes:- calling preLoadData for severitydata
        if (typeof nextProps.changeSeverity !== 'undefined' && nextProps.changeSeverity !== null){
            if(typeof nextProps.changeSeverity.changeSeverity !== 'undefined' && nextProps.changeSeverity.changeSeverity !== null){
            this.preLoadData(nextProps.changeSeverity.changeSeverity.data);   
            } 
		}
        if (helpers.getCookie("soar_support") === "yes") {
            if (typeof nextProps.killchainstages != 'undefined' && nextProps.killchainstages.length > 0) {
                let sortArray = helpers.sortArray(nextProps.killchainstages, "name");
                setTimeout(() => {
                    this.setState({ killChainDropdownData: sortArray }, function () {
                        //this.loadCategoriesData();
                    });
                }, 10);
                //this.setState({ killChainDropdownData: sortArray });
            }
        }        
    }catch(e){
        console.log("quickactioncase props error",e);
    }
    }


    loadCategoriesData = () => {
        var metadatalist = this.state.metaDataList;
        var categoriesList = this.state.categoryDropdownData;

        for (var i = 0; i < categoriesList.length; i++) {
            var categoryName = categoriesList[i]["name"];
            var currentCategoryArray = [];
            if (typeof metadatalist != "undefined" && metadatalist.length > 0) {
                for (var j = 0; j < metadatalist.length; j++) {
                    var categories = metadatalist[j]["categories"];

                    if (categories !== null) {
                        if (categories.indexOf(categoryName) > -1) {
                            currentCategoryArray.push(metadatalist[j])
                        }
                    }
                }
                this.setState({
                    [categoriesList[i]["name"] + "Array"]: currentCategoryArray
                });
            }
        }
    }

    setUserData = () => {

            let userDropdownData = [];
            if (typeof this.props.users !== 'undefined' && this.props.users !== null && this.props.users.length > 0) {
                let asignees = [];
                let users = this.props.users;
                users = helpers.sortArray(users, "firstname");
                for (let i = 0; i < users.length; i++) {
                    if (users[i].status === "active") {
                        let modules = users[i].modules;
                        for (let j = 0; j < modules.length; j++) {
                            if ((modules[j].modulename === "Dashboard" || modules[j].modulename === "Case Panel") && modules[j].readwrite === "y") {
                                let obj = { type: "user", data: users[i] };
                                asignees = asignees.concat(obj);
                                break;
                            }
                        }
                    }
                }
                userDropdownData = userDropdownData.concat(asignees);
            }
    
            if (typeof this.props.userGroups !== 'undefined' && this.props.userGroups !== null && this.props.userGroups.length > 0) {
                let userGroups = [];
    
                let groups = this.props.userGroups;
                groups = helpers.sortArray(groups, "group_name");
                for (let i = 0; i < groups.length; i++) {
                    let obj = { type: "group", data: groups[i] };
                    userGroups = userGroups.concat(obj);
                }
                userDropdownData = userDropdownData.concat(userGroups);
            }
    
            if (userDropdownData.length > 0) {
                userDropdownData = helpers.sortArrayDesc(userDropdownData, "type");
            }
    
            this.setState({ userDropdownData }); 
    }

    toggleNotificationModal = (state) => {
        this.setState({ showNotificationModal: state });
    }

    handleChange = (e) => {
        this.checkValueEmpty(e);
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === "caseAssignee") {
            if (e.target.value !== "" && (this.state.caseStatus === "NEW" || this.state.caseStatus === "")) {
                this.setState({ caseStatus: "ASSIGNED" });
            } else {
                this.setState({ caseAssigneeError: "" });
            }
        }

        if (e.target.name === "caseStatus" && (e.target.value === "NEW" || e.target.value === "ONHOLD" || e.target.value === "RESOLVED" || e.target.value === "CLOSED")) {
            this.setState({ caseAssignee: "", caseAssigneeName: "", caseAssigneeError: "" });
        } else if (e.target.name === "caseStatus" && (e.target.value === "INPROGRESS" || e.target.value === "ASSIGNED") && this.state.caseAssignee === "") {
            this.setState({ caseAssigneeError: "This field is required" });
        }
        if (e.target.name == "caseCategory") {

            let index = e.target.selectedIndex;
            let label = e.target[index].text;
            this.setState({
                currentCategory: label
            })
            this.updateCategoryUI(label);
        }

        if (e.target.name === "caseDescription") {
            //e.target.value = e.target.value.trim()
            this.setState({ [e.target.name]: e.target.value });
        }

        setTimeout(() => { this.validateForm(); }, 10);
    }

    handleChangeCustom = (e, formfields) => {
        this.checkValueEmptyCustom(e);
        if (formfields.field_type == "radio") {
            this.setState({ [e.target.name]: e.target.value });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
        setTimeout(() => { this.validateForm(); }, 10);
    }

    handleChangeCustomMulti = (selectedOptions, formfields) => {
        //this.checkValueEmptyCustom(e);
        let multiSelectedValues = selectedOptions.map(x => x.label);
        multiSelectedValues = multiSelectedValues.join();
        this.setState({ [formfields.id + "custom"]: selectedOptions, [formfields.id + "customFinal"]: multiSelectedValues });
        setTimeout(() => { this.validateForm(); }, 10);
    }

    checkValueEmptyCustom = (e) => {
        let errorField = e.target.name + `CustomError`;
        let fieldValue = e.target.value;
        if (fieldValue === "") {
            this.setState({ [errorField]: "This field is required" });
            return
        } else {
            let patternType = e.target.pattern;
            if (typeof patternType !== 'undefined' && patternType !== "" && patternType !== null) {
                patternType = patternType.trim();
                let regex = new RegExp(helpers._pattern[patternType]);
                if (!regex.test(fieldValue)) {
                    this.setState({ [errorField]: "Enter valid input" });
                } else {
                    this.setState({ [errorField]: "" });
                }
            } else {
                this.setState({ [errorField]: "" });
            }
        }
    }

    updateCategoryUI = (name) => {
        let modalStyle = { color: "" };
        let inputStyle = { color: "" };

        if (typeof this.props.theme !== 'undefined') {
            modalStyle = this.props.theme.modal;
            inputStyle = this.props.theme.input;
        }
        const fontColor = this.props.theme ? this.props.theme.color : "#2A3139";

        const customStyles = {
            option: (provided, state) => ({
                ...provided, color: "#CFD2DA", backgroundColor: "#1d2124", padding: 5
            }),
            Select: (provided, state) => ({
                ...provided, backgroundColor: "#CFD2DA", backgroundColor: "#1d2124"
            })
        }

        if (typeof this.state[name + "Array"] != "undefined") {
            return (
                <div>
                    {(this.state[name + "Array"].length > 0) ?
                        <div>
                            {this.state[name + "Array"].map((formFields, forFieldsIndex) => (
                                <React.Fragment key={forFieldsIndex}>

                                    {(formFields.field_type === "select") ?
                                        <Row>
                                            <Col sm="12" md="12" lg="12">
                                                <FormGroup>
                                                    {/* sriraksha changes: added wordBreak -09/06/2023 */} 
                                                    <label htmlFor={formFields.field_name} style={{ color: fontColor, wordBreak:"break-all" }}>{formFields.field_name} {(formFields.is_mandatory === true ? '*' : '(Optional)')}</label>
                                                    <select style={inputStyle} className="form-control" id={formFields.id + "custom"} name={formFields.id + "custom"} onChange={(e) => this.handleChangeCustom(e, formFields)} value={this.state[formFields.id + "custom"]} >
                                                        <option value="">---Select---</option>
                                                        {(typeof formFields.fieldvalues != 'undefined' && formFields.fieldvalues.length > 0) ?
                                                            <React.Fragment>
                                                                {formFields.fieldvalues.map((formFields3, indexFields3) => (
                                                                    <option key={indexFields3} value={formFields3}>{formFields3}</option>
                                                                ))} </React.Fragment> : <React.Fragment />}

                                                    </select>
                                                    <div className={"help-block with-errors errorMessage " + this.state[formFields.id + "CustomError"]}>please select on of the {formFields.field_name} values</div>
                                                </FormGroup></Col></Row> : <React.Fragment />
                                    }


                                    {(formFields.field_type === "multi select") ?
                                        <React.Fragment>
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <FormGroup>

                                                        <div style={{ textAlign: "left" }}>
                                                        {/* sriraksha changes: added wordBreak -09/06/2023 */}
                                                            <label className=" form-control-label" style={{ float: "none", color: fontColor, wordBreak:"break-all" }}>{formFields.field_name} {(formFields.is_mandatory === true ? '*' : '(Optional)')}</label>
                                                        </div>
                                                        <div className="form-group" style={{ textAlign: "left" }}>
                                                            <Select style={inputStyle}
                                                                styles={customStyles2}
                                                                value={this.state[formFields.id + "custom"]}
                                                                onChange={(selectedOption) => this.handleChangeCustomMulti(selectedOption, formFields)}
                                                                options={formFields.fieldvalues}
                                                                isMulti={true}
                                                                className="basic-multi-select"
                                                            />
                                                        </div>
                                                        <div className={"help-block with-errors errorMessage " + this.state[formFields.id + "CustomError"]}>{this.state[formFields.id + "Message"]}</div>
                                                    </FormGroup></Col></Row> </React.Fragment> : <React.Fragment />

                                    }

                                    {(formFields.field_type === "radio") ?
                                        <Row>
                                            <Col sm="12" md="12" lg="12">
                                                <FormGroup>
                                                    <div style={{ textAlign: "left" }}>
                                                    {/* sriraksha changes: added wordBreak -09/06/2023 */}
                                                        <label htmlFor={formFields.field_name} name={formFields.id + "custom"} style={{ color: fontColor, wordBreak:"break-all" }}>{formFields.field_name} {(formFields.is_mandatory === true ? '*' : '(Optional)')}</label><br />
                                                    </div>
                                                </FormGroup>
                                                <div className="form-group" style={{ textAlign: "left" }}>
                                                    {formFields.fieldvalues.map((formFields2, indexFields2) => (
                                                        <label key={indexFields2 + "radio"} className="radio-inline">
                                                            <input type="radio" name={formFields.id + "custom"} value={formFields2} id={formFields2} onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChangeCustom(e, formFields)} checked={this.state[formFields.id + "custom"] === formFields2} />{formFields2}
                                                        </label>
                                                    ))}
                                                </div>
                                            </Col></Row> : <React.Fragment />
                                    }



                                    {(formFields.field_type === "input") ?
                                        <React.Fragment>
                                            <React.Fragment>
                                                <Row>
                                                    <Col sm="12" md="12" lg="12">
                                                        <FormGroup id="">
                                                        {/* sriraksha changes: added wordBreak -09/06/2023 */}
                                                            <label htmlFor={formFields.field_name} style={{ color: fontColor, wordBreak:"break-all" }}>{formFields.field_name} {(formFields.is_mandatory === true ? '*' : '(Optional)')}</label>
                                                            <input placeholder={formFields.placeholder} style={inputStyle} type="text" className="form-control" id={formFields.id + "custom"} name={formFields.id + "custom"} required={formFields.is_mandatory} value={this.state[formFields.id + "custom"]} onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChangeCustom(e, formFields)} />

                                                            <div className={"help-block with-errors errorMessage " + this.state[formFields.id + "CustomError"]}>please enter {formFields.field_name}</div>
                                                        </FormGroup></Col></Row>
                                            </React.Fragment>
                                        </React.Fragment> : <React.Fragment />}

                                </React.Fragment>
                            ))}
                        </div> : <React.Fragment />
                    }
                </div>

            )
        }

    }
    checkValueEmpty = (e) => {
        let errorField = e.target.name + `Error`;
        let fieldValue = e.target.value;
        if ((e.target.name !== "caseEmail" && e.target.name !== "caseURL" && e.target.name !== "caseHash" && e.target.name !== "sourceemail" && e.target.name !== "destinationemail" && e.target.name !== "sourceIP" && e.target.name !== "destinationIP" && e.target.name !== "caseUser" && e.target.name !== "caseKillChainStage") && fieldValue === "") {
            this.setState({ [errorField]: "This field is required" });
            return
        } else {
            if (e.target.name === "caseCategory") {
                let index = e.nativeEvent.target.selectedIndex;
                let caseCategoryName = e.nativeEvent.target[index].text;
                this.setState({ caseCategoryName });
            } else if (e.target.name === "caseAssignee") {
                let index = e.nativeEvent.target.selectedIndex;
                // let caseAssigneeName = e.nativeEvent.target[index].text;
                let caseAssigneeName = e.nativeEvent.target[index].getAttribute('asigneename');
                let asigneeType = e.nativeEvent.target[index].getAttribute('asigneetype');
                this.setState({ caseAssigneeName, asigneeType });
            } else if (e.target.name === "caseKillChainStage") {
                let index = e.nativeEvent.target.selectedIndex;
                let caseKillChainStageName = e.nativeEvent.target[index].text;
                this.setState({ caseKillChainStageName });
            }

            let patternType = e.target.pattern;
            if (e.target.name === "caseEmail" || e.target.name === "sourceemail" || e.target.name === "destinationemail") {
                patternType = "email";
            } else if (e.target.name === "caseURL") {
                patternType = "url";
            }

            if ((e.target.name === "caseEmail" || e.target.name === "caseURL" || e.target.name === "caseHash") && fieldValue === "") {
                this.setState({ [errorField]: "" });
            }

            if (typeof patternType !== 'undefined' && patternType !== "" && patternType !== null) {
                patternType = patternType.trim();
                let regex = new RegExp(helpers._pattern[patternType]);
                if (e.target.name === "caseEmail" || e.target.name === "caseURL" || e.target.name === "sourceIP" || e.target.name === "destinationIP" || e.target.name === "sourceemail" || e.target.name === "destinationemail") {
                    let values = fieldValue.split(",");
                    for (let s = 0; s < values.length; s++) {
                        if (values[s] !== "") {
                            if (!regex.test(values[s])) {
                                this.setState({ [errorField]: "Enter valid input" });
                                return
                            } else {
                                this.setState({ [errorField]: "" });
                            }
                        } else {
                            this.setState({ [errorField]: "" });
                        }
                    }
                } else {
                    if (!regex.test(fieldValue)) {
                        this.setState({ [errorField]: "Enter valid input" });
                    } else {
                        this.setState({ [errorField]: "" });
                    }
                }
            } else {
                this.setState({ [errorField]: "" });
            }
        }
    }

    validateForm = () => {
        let caseStatus = this.state.caseStatus;

        var exec = true;
        if (this.state.metadataAll.length > 0) {
            for (var i = 0; i < this.state.metadataAll.length; i++) {
                var is_mandatory = this.state.metadataAll[i]["is_mandatory"];
                var id = this.state.metadataAll[i]["id"];
                if (is_mandatory) {
                    if (this.state[id + "custom"] == "" || typeof this.state[id + "custom"] == "undefined") {
                        exec = false;
                    }
                }
            }
        }

        let currentCategory = this.state.caseCategory;
        if (currentCategory != "") {
            let categoryArray = this.state[currentCategory + "Array"];
            if (typeof categoryArray != "undefined") {
                if (categoryArray.length > 0) {
                    for (var i = 0; i < categoryArray.length; i++) {
                        var is_mandatory = categoryArray[i]["is_mandatory"];
                        var id = categoryArray[i]["id"];
                        if (is_mandatory) {
                            if (this.state[id + "custom"] == "" || typeof this.state[id + "custom"] == "undefined") {
                                exec = false;
                            }
                        }
                    }
                }
            }
        }

        // if (caseStatus !== "" && caseStatus === "NEW") {
        //     this.setState({
        //         btnDisabled: this.state.caseDescription !== "" &&  (this.state.sourceIP !== "" ||  this.state.destinationIP !== "" || this.state.caseUser !== "" || this.state.sourceemail !="" || this.state.destinationemail !=""
        //         || this.state.caseEmail !="" || this.state.caseURL !="" || this.state.caseHash !="" ) && this.state.caseCategory !== "" && this.state.caseSeverity !== "" && this.state.caseStatus !== "" && this.state.caseDescriptionError === "" && this.state.sourceIPError === "" &&
        //             this.state.destinationIPError === "" && this.state.caseUserError === "" && this.state.caseCategoryError === "" &&
        //             this.state.caseSeverityError === "" && this.state.caseStatusError === "" && this.state.caseAssigneeError === "" &&
        //             this.state.caseKillChainStageError === "" && this.state.caseEmailError === "" && this.state.caseURLError === "" && this.state.caseHashError === "" && this.state.sourceemailError === "" && this.state.destinationemailError === "" && exec == true ? false : true
        //     });
        // } else {
        //     this.setState({
        //         btnDisabled: this.state.caseDescription !== "" &&  (this.state.sourceIP !== "" ||  this.state.destinationIP !== "" || this.state.caseUser !== "" || this.state.sourceemail !="" || this.state.destinationemail !=""
        //         || this.state.caseEmail !="" || this.state.caseURL !="" || this.state.caseHash !="" ) && this.state.caseCategory !== "" && 
        //             this.state.caseSeverity !== "" && this.state.caseStatus !== "" && this.state.caseDescriptionError === "" && this.state.sourceIPError === "" &&
        //             this.state.destinationIPError === "" && this.state.caseUserError === "" && this.state.caseCategoryError === "" &&
        //             this.state.caseSeverityError === "" && this.state.caseStatusError === "" && this.state.caseAssigneeError === "" &&
        //             this.state.caseKillChainStageError === "" && this.state.caseEmailError === "" && this.state.caseURLError === "" && this.state.caseHashError === ""  && this.state.sourceemailError === "" && this.state.destinationemailError === "" && exec == true ? false : true
        //     });
        // }

        if (caseStatus !== "" && caseStatus === "NEW") {
            // (this.state.sourceIP !== "" ||  this.state.destinationIP !== "" || this.state.caseUser !== "" || this.state.sourceemail !="" || this.state.destinationemail !=""
            // || this.state.caseEmail !="" || this.state.caseURL !="" || this.state.caseHash !="" )
            this.setState({
                btnDisabled: this.state.caseDescription !== "" && this.state.caseCategory !== "" && this.state.caseSeverity !== "" && this.state.caseStatus !== "" && this.state.caseDescriptionError === "" && this.state.sourceIPError === "" &&
                    this.state.destinationIPError === "" && this.state.caseUserError === "" && this.state.caseCategoryError === "" &&
                    this.state.caseSeverityError === "" && this.state.caseStatusError === "" && this.state.caseAssigneeError === "" &&
                    this.state.caseKillChainStageError === "" && this.state.caseEmailError === "" && this.state.caseURLError === "" && this.state.caseHashError === "" && this.state.sourceemailError === "" && this.state.destinationemailError === "" && exec == true ? false : true
            });
        } else if ((caseStatus === "ASSIGNED" || caseStatus === "INPROGRESS")) {
            this.setState({
                btnDisabled: this.state.caseDescription !== "" && this.state.caseCategory !== "" && this.state.caseSeverity !== "" && this.state.caseStatus !== "" && this.state.caseAssignee !== "" && this.state.caseDescriptionError === "" && this.state.sourceIPError === "" &&
                    this.state.destinationIPError === "" && this.state.caseUserError === "" && this.state.caseCategoryError === "" &&
                    this.state.caseSeverityError === "" && this.state.caseStatusError === "" && this.state.caseAssigneeError === "" &&
                    this.state.caseKillChainStageError === "" && this.state.caseEmailError === "" && this.state.caseURLError === "" && this.state.caseHashError === "" && this.state.sourceemailError === "" && this.state.destinationemailError === "" && exec == true ? false : true
            });
        } else {
            this.setState({
                btnDisabled: this.state.caseDescription !== "" && this.state.caseCategory !== "" &&
                    this.state.caseSeverity !== "" && this.state.caseStatus !== "" && this.state.caseDescriptionError === "" && this.state.sourceIPError === "" &&
                    this.state.destinationIPError === "" && this.state.caseUserError === "" && this.state.caseCategoryError === "" &&
                    this.state.caseSeverityError === "" && this.state.caseStatusError === "" && this.state.caseAssigneeError === "" &&
                    this.state.caseKillChainStageError === "" && this.state.caseEmailError === "" && this.state.caseURLError === "" && this.state.caseHashError === "" && this.state.sourceemailError === "" && this.state.destinationemailError === "" && exec == true ? false : true
            });
        }
    }

    resetStateVariables = () => {
        this.state.metadataAll.map((formfields, forFieldsIndex) => (
            this.setState({ [formfields.id + "custom"]: ""})
        ))
        this.setState({
            metadataAll: [],
            metadataCustom: [],
            currentCategory: "",
            caseDescription: "", sourceIP: "", destinationIP: "",
            caseUser: "", caseCategory: "", caseCategoryName: "",
            caseSeverity: "", caseTenant: "", caseStatus: "",
            caseAssignee: "", caseAssigneeName: "", caseKillChainStage: "",
            caseKillChainStageName: "", caseCategoryName: "---select---", caseAssigneeName: "---select---",
            caseKillChainStageName: "---select---", caseDescriptionError: "", sourceIPError: "",
            destinationIPError: "", caseUserError: "", caseCategoryError: "",
            caseSeverityError: "", caseTenantError: "", caseStatusError: "",
            caseAssigneeError: "", caseKillChainStageError: "", sourceemailError:"", destinationemailError:"", caseURLError:"", caseHashError:"", btnDisabled: true, caseURL: "", caseEmail: "", caseHash: "", sourceemail: "", destinationemail: "",selectedTag:[],caseJSON:"",caseJSONError:"",btnDisabledJSON:true
        });
    }
    
    getIncidentsTags = () => {
        let url = helpers.restURL + "?module=siem&rest=getincidenttags&q=" + helpers.getCookie('tenantcode') + "/" + helpers.getCookie('companycode') + "/";
        axios({
            method: "GET",
            url: url,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                this.checkForErrors(res.data);;
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    getMetaData = () => {
        var url = restURL + "?module=siem&rest=getMetadata&q=" + helpers.getCookie('tenantcode') + "/";
        axios({
            method: "GET",
            url: url,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                if (res.data.data.length > 0) {

                    for (var i = 0; i < res.data.data.length; i++) {
                        this.setState({
                            [res.data.data[i]["id"] + "CustomError"]: "hide"
                        })

                        if (res.data.data[i]["field_type"] == "multi select") {
                            this.setState({
                                [res.data.data[i]["id"] + "customFinal"]: ""
                            })
                            var fieldValues = res.data.data[i]["fieldvalues"];
                            var fieldValuesFinal = [];
                            for (var j = 0; j < fieldValues.length; j++) {
                                fieldValuesFinal.push({ "label": fieldValues[j], "value": fieldValues[j] })
                            }
                            res.data.data[i]["fieldvalues"] = fieldValuesFinal;
                        }
                    }

                    var metadataAll = res.data.data.filter(item => item.categorytype == "all");
                    var metadataCustom = res.data.data.filter(item => item.categorytype == "custom");

                    this.setState({ metaDataList: res.data.data, metadataAll: metadataAll, metadataCustom: metadataCustom }, function () {
                        this.loadCategoriesData();
                    });
                }
                else {
                    this.setState({ metaDataList: "", metaDataListError: "No Metadata found" })
                }
            } else {
                this.setState({ metaDataListError: "Failed to fetch data " });
            }
            this.setState({ loading: "hide" });
        }).catch(function (error) {
            console.log(error);
        });
    }


    checkForErrors(responsedata) {
        if (responsedata.data === null || typeof responsedata.data === 'undefined') {
            this.setState({ errorMessage: <h6>No Data</h6> });
        } else if (responsedata.displaymessage !== 'undefined' && responsedata.displaymessage !== "") {
            let displaymessage = "";
            displaymessage += <h5 className="text-center">{responsedata.displaymessage}</h5>;
            this.setState({ errorMessage: displaymessage });
        } else {
            var tagsDataArray = [];
            for (var i = 0; i < responsedata.data.length; i++) {
                var currentItem = { value: responsedata.data[i]["tag_z_name"], label: responsedata.data[i]["tag_z_name"] }
                tagsDataArray.push(currentItem);
            }
            this.setState({
                tagsData: tagsDataArray
            })
        }
    }

    saveNewIncidentTags = (newTags) => {
        let data = {
            zona_z_tenantcode: helpers.getCookie("tenantcode"),
            tag_z_name: newTags,
            rest: "createIncidentTag",
            module: "siem"
        };
        axios({
            method: "POST",
            url: helpers.restURL,
            headers: {
                'access_token': helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token')
            },
            dataType: "json",
            data: data
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                this.getIncidentsTags();
            } else {
            }
        }).catch((err) => {
            console.log(err);
        });
    }


    saveCase = () => {
        var customfields = [];
        if (this.state.metadataAll.length > 0) {
            for (var i = 0; i < this.state.metadataAll.length; i++) {
                var is_mandatory = this.state.metadataAll[i]["is_mandatory"];
                var id = this.state.metadataAll[i]["id"];
                var name = this.state.metadataAll[i]["field_name"];
                var field_type = this.state.metadataAll[i]["field_type"];
                var fieldvalue = this.state.metadataAll[i]["fieldvalues"];
               /* Kavya : condition Modified because custom field is not sent in req json  if value is not entered in custom field value */
                //if (this.state[id + "custom"] != "" && typeof this.state.metadataAll[i]["field_name"] != "undefined") {
                if (typeof this.state.metadataAll[i]["field_name"] != "undefined") {

                    var jsonfield = {};
                    jsonfield["id"] = id;
                    jsonfield["name"] = name;
                    jsonfield["field_type"] = field_type;
                    if (fieldvalue.length > 0) {
                        jsonfield["fieldvalues"] = fieldvalue;
                    } else {
                        jsonfield["fieldvalues"] = "";
                    }
                    if (this.state.metadataAll[i]["field_type"] == "multi select") {
                        jsonfield["value"] = this.state[id + "customFinal"];
                    } else {
                        jsonfield["value"] = this.state[id + "custom"];
                    }


                    customfields.push(jsonfield);
                }
            }
        }

        let currentCategory = this.state.currentCategory;
        if (currentCategory != "") {
            let categoryArray = this.state[currentCategory + "Array"];
            if (typeof categoryArray != "undefined") {
                if (categoryArray.length > 0) {
                    for (var i = 0; i < categoryArray.length; i++) {
                        var is_mandatory = categoryArray[i]["is_mandatory"];
                        var id = categoryArray[i]["id"];
                        var name = categoryArray[i]["field_name"];

                        if (this.state[id + "custom"] != "" && typeof categoryArray[i]["field_name"] != "undefined") {
                            var jsonfield = {};
                            jsonfield["name"] = name;
                            if (categoryArray[i]["field_type"] == "multi select") {
                                jsonfield["value"] = this.state[id + "customFinal"];
                            } else {
                                jsonfield["value"] = this.state[id + "custom"];
                            }
                            customfields.push(jsonfield);
                        }

                    }
                }
            }
        }


        let caseDescription = this.state.caseDescription.trim();
        let sourceIP = this.state.sourceIP.trim();
        let destinationIP = this.state.destinationIP.trim();
        let caseUser = this.state.caseUser.trim();
        let caseCategory = this.state.caseCategory.trim();
        let caseCategoryName = this.state.caseCategoryName.trim();
        let caseSeverity = this.state.caseSeverity.trim();
        let caseStatus = this.state.caseStatus.trim();
        let caseAssignee = this.state.caseAssignee === "" ? 0 : this.state.caseAssignee;
        let caseAssigneeName = this.state.caseAssigneeName.trim();
        let caseKillChainStage = this.state.caseKillChainStage === "" ? 0 : this.state.caseKillChainStage;
        let caseKillChainStageName = this.state.caseKillChainStageName.trim();
        let { caseEmail, caseURL, caseHash, asigneeType, sourceemail, destinationemail } = this.state;

        let { tagsData, selectedTag } = this.state;
        let newTags = [];
        let sourceIPArray = [];
        let destinationIPArray = [];
        let finalTags = [];
        for (var i = 0; i < selectedTag.length; i++) {
            var name = selectedTag[i]["label"];
            var newNames = tagsData.filter(item => item.label == name);
            finalTags.push(name);
            if (newNames.length == 0) {
                newTags.push(name);
            }
        }

        if (newTags.length > 0) {
            this.saveNewIncidentTags(newTags);
        }
        let groupID = 0;

        if (caseEmail !== "") {
            caseEmail = caseEmail.split(",");
        } else {
            caseEmail = [];
        }

        if (caseURL !== "") {
            caseURL = caseURL.split(",");
        } else {
            caseURL = [];
        }

        if (caseHash !== "") {
            caseHash = caseHash.split(",");
        } else {
            caseHash = [];
        }

        if (caseAssigneeName === "---select---") {
            caseAssigneeName = "";
        }

        if (caseKillChainStageName === "---select---") {
            caseKillChainStageName = "";
        }

        if (sourceIP != "") {
            sourceIPArray = sourceIP.split(',');
        }

        if (destinationIP != "") {
            destinationIPArray = destinationIP.split(',');
        }


        if (caseStatus === "NEW" && caseAssignee > 0) {
            caseStatus = "ASSIGNED";
        }

        if (caseAssignee !== 0) {
            caseAssignee = caseAssignee.split("_");
            caseAssignee = parseInt(caseAssignee[1]);
        }

        if (asigneeType !== "" && asigneeType === "group") {
            groupID = caseAssignee;
            caseAssignee = 0;
        }
        if (sourceemail !== "") {
            sourceemail = sourceemail.split(",");
        } else {
            sourceemail = [];
        }
        if (destinationemail !== "") {
            destinationemail = destinationemail.split(",");
        } else {
            destinationemail = [];
        }

        this.props.toggleIncidentModal(false);
        this.props.showLoading("show");
        let data = {
            zona_offense_source: caseDescription,
            zona_z_source_ips: sourceIPArray,
            zona_z_destination_ips: destinationIPArray,
            zona_z_category_id: parseInt(caseCategory),
            zona_z_kill_chain_stage_id: parseInt(caseKillChainStage),
            zona_z_kill_chain_stage: caseKillChainStageName,
            zona_z_is_confirmed: true,
            zona_z_status: caseStatus,
            zona_z_assigned_to: parseInt(caseAssignee),
            zona_z_severity: caseSeverity,
            company_code: helpers.getCookie('companycode'),
            zona_z_owner: caseAssigneeName,
            zona_z_category: caseCategoryName,
            zona_z_tenantcode: helpers.getCookie("tenantcode"),
            zona_z_incident_user: caseUser,
            zona_z_urls: caseURL,
            zona_z_emailids: caseEmail,
            zona_z_hash: caseHash,
            zona_z_source: "Securaa",
            zona_z_owner_type: asigneeType,
            zona_z_group_id: groupID,
            tags_list: finalTags,
            zona_metadata_fields: customfields,
            zona_z_source_email: sourceemail,
            zona_z_destination_email: destinationemail,
            rest: "zonaincident",
            module: "siem",
            rule_name:"",
            id:0,
            zona_z_risk_score:0,
            zona_z_domain_name:"",
            jira_key:""
        };

        axios({
            method: "POST",
            url: helpers.restURL,
            headers: {
                'access_token': helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token')
            },
            dataType: "json",
            data: data
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                this.setState({
                    notificationMessage: "New Case ID : " + res.data.data, 
                   // showMessage: true,messageType: "success", toastMessage: "Case saved successfully"
                });
                helpers.securaaNotification("Case saved successfully.","success",10)
                this.resetStateVariables();
                this.toggleNotificationModal(true);

                setTimeout(() => { this.setState({ showMessage: false }); }, 1000);
                helpers.checkCurrentDate();
                this.props.updateMycase();
                //window.location.reload();
            } else {
                // this.setState({
                //     showMessage: true, messageType: "error", toastMessage: "Case saving failed"
                // });
                helpers.securaaNotification("Case saving failed.","error",10)
                setTimeout(() => { this.setState({ showMessage: false }); }, 1000);
            }
            this.props.showLoading("hide");
        }).catch((err) => {
            console.log(err);
        });
    }

    changeTags = (options) => {
        this.setState({ selectedTag: options });
    }

    closeModal = () => {
        this.resetStateVariables();
        this.setState({
            useJSON:false
        });
        this.props.toggleIncidentModal(false)
    }
    handleKeyPress(target) {
        if (target.charCode === 32 && target.target.selectionStart === 0) {
            target.preventDefault();
        }
    }
 //added this below function to support toggle between case form and json form preetham may 26 2023

    toggleJSON=(e)=>{
        this.setState({
            useJSON:!this.state.useJSON
        },function(){
            this.resetStateVariables();
        })
    }


    //added this below function to support json as input validation preetham may 26 2023
    isValidJSON=(string)=>{
        try {
          let jsonObject = JSON.parse(string);
          // Get the keys from the JSON object
            var keys = Object.keys(jsonObject);
           
            // Get the length of keys
            var keyCount = keys.length;
              if(keyCount == 0){
                throw new Error("Empty json is selected");
                return false;
              }
            // Print the key count
          this.setState({btnDisabledJSON:false,caseJSONError:""});
        } catch (e) {
            this.setState({btnDisabledJSON:true,caseJSONError:e.message});
          return false;
        }
      
        return true;
      }

      handleChangeJSON=(e)=>{
        this.setState({
            caseJSON:e.target.value
        })
        this.isValidJSON(e.target.value)
      }

      //added this below function to support json as input save preetham may 26 2023
      saveCaseJSON=()=>{
        let caseAssignee = this.state.caseAssignee === "" ? 0 : this.state.caseAssignee;
        let caseAssigneeName = this.state.caseAssigneeName.trim();
         if (caseAssignee !== 0) {
            caseAssignee = caseAssignee.split("_");
            caseAssignee = parseInt(caseAssignee[1]);
        }
        let groupID = 0;
        let asigneeType = this.state.asigneeType;
        if (asigneeType !== "" && asigneeType === "group") {
            groupID = caseAssignee;
            caseAssignee = 0;
            asigneeType = "group"
        }

        if (caseAssigneeName === "---select---") {
            caseAssigneeName = "";
            asigneeType="";
        }



        let jsonData = {
            zona_z_tenantcode: helpers.getCookie("tenantcode"),
            zona_z_owner:caseAssigneeName,
            zona_z_assigned_to : caseAssignee,
            zona_z_owner_type:asigneeType,
            zona_z_group_id: groupID,
            userid:parseInt(helpers.getCookie('userid')),
            data : this.state.caseJSON,
            rest: "createcasebyjsoninput",
            module: "siem"
        };

        axios({
            method: "POST",
            url: helpers.restURL,
            headers: {
                'access_token': helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token')
            },
            dataType: "json",
            data: jsonData
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                this.setState({
                    notificationMessage: "New Case ID : " + res.data.data, 
                   // showMessage: true,messageType: "success", toastMessage: "Case saved successfully"
                });
                helpers.securaaNotification("Case saved successfully.","success",10)
                this.resetStateVariables();
                this.toggleNotificationModal(true);

                setTimeout(() => { this.setState({ showMessage: false }); }, 1000);
                helpers.checkCurrentDate();
                this.props.updateMycase();
                this.closeModal();
                //window.location.reload();
            } else {
                // this.setState({
                //     showMessage: true, messageType: "error", toastMessage: "Case saving failed"
                // });
                if(res.data.error !=  null && typeof res.data.error != "undefined" && res.data.error != ""){
                    helpers.securaaNotification(res.data.error,"error",10)
                }else{
                    helpers.securaaNotification("Case saving failed.","error",10)
                }
                
                setTimeout(() => { this.setState({ showMessage: false }); }, 1000);
               
            }
            this.props.showLoading("hide");
        }).catch((err) => {
            console.log(err);
        });
      }



    copyDefaultJSON =()=>{
        const jsonString = JSON.stringify(this.state.defaultJSONValue);
        navigator.clipboard.writeText(jsonString)
          .then(() => {
            this.successMessage("Copied");
        })
          .catch((error) => {
            this.successMessage("Failed to copy.");
        });
    }

    successMessage=(text)=>{
        helpers.securaaNotification(text,"success",10)
    }

    render() {

        let modalStyle = { color: "" };
        let inputStyle = { color: "" };
        let inputJSONStyle = {  };
        let themeColor ="" /*tojo changes: Dashboard- Theme issue arise in "add new case" tab in quick actions 29/06/2023*/
        if (typeof this.props.theme !== 'undefined') {
            modalStyle = this.props.theme.modal;
            inputStyle = this.props.theme.input;
            inputJSONStyle = this.props.theme.input;
            inputJSONStyle  = { ...inputJSONStyle,"height" : "300px"};
            themeColor = this.props.theme.theme /*tojo changes: Dashboard- Theme issue arise in "add new case" tab in quick actions 29/06/2023*/
        }
        const fontColor = this.props.theme ? this.props.theme.color : "#2A3139";

        const customStyles = {
            option: (provided, state) => ({
                ...provided, color: "#CFD2DA", backgroundColor: "#1d2124", padding: 5
            }),
            Select: (provided, state) => ({
                ...provided, backgroundColor: "#CFD2DA", backgroundColor: "#1d2124"
            })
        }

        return (
            <div>
            <ReactTooltip html={true} />
                <Modal size="lg" fade={false} isOpen={this.state.showIncidentModal} backdrop="static">
                    <ModalHeader style={modalStyle}><span style={{ color: fontColor, fontSize: "20px" }}>Report case</span>
                    
                    </ModalHeader>
                    <ModalBody style={modalStyle}>
                    {/*added this toggle button to switch json and case preetham may26th 2023 */}    
                    <Row>
                        <Col sm="12">
                            <span>
                                <h5 style={{ color: fontColor, textAlign: "right" }}>
                                    JSON Input&nbsp;<label className="toggleswitch pull-right">
                                    <input type="checkbox" id="JSONInputBtn" checked={this.state.useJSON} onChange={this.toggleJSON} />
                                    <span style={this.state.useJSON ? {backgroundColor:"#09897e"} : {backgroundColor:"rgb(189 191 191)"}} className="toggleslider round"></span>
                                    </label>
                                </h5>
                            </span>
                            </Col>
                        </Row>
                        {/*added this condotion to switch between json and case preetham may26th 2023 */}
                        {(!this.state.useJSON) ?
                            <React.Fragment>

                        {/* sriraksha changes: added onKeyPress funtion to input fields -30/05/2023 */}
                        <Row>
                            <Col sm="6">
                                <FormGroup>

                                    <Label style={{ color: fontColor }} for="caseDescription">Case Description <span>&#42;</span></Label>
                                    <Input onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChange(e)} value={this.state.caseDescription} style={inputStyle} type="text" name="caseDescription" id="caseDescription" placeholder="Case" pattern="alphanumeric" />
                                    <div className="errorMessage">{this.state.caseDescriptionError}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="sourceIP">Source IP </Label>
                                    <Input style={inputStyle} onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChange(e)} value={this.state.sourceIP} type="text" name="sourceIP" id="sourceIP" placeholder="Source IP (use , to add multiple ips)" pattern="ipv4v2" />
                                    <div className="errorMessage">{this.state.sourceIPError}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="destinationIP">Destination IP </Label>
                                    <Input style={inputStyle} onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChange(e)} value={this.state.destinationIP} type="text" name="destinationIP" id="destinationIP" placeholder="Destination IP (use , to add multiple ips)" pattern="ipv4v2" />
                                    <div className="errorMessage">{this.state.destinationIPError}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="caseUser">User </Label>
                                    <Input style={inputStyle} onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChange(e)} pattern="alphanumeric_with_spacecomma" value={this.state.caseUser} type="text" name="caseUser" id="caseUser" placeholder="User (use , to add multiple user)" />
                                    <div className="errorMessage">{this.state.caseUserError}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="caseCategory">Category<span>&#42;</span></Label>
                                    <Input style={inputStyle} type="select" onChange={(e) => this.handleChange(e)} value={this.state.caseCategory} name="caseCategory" id="caseCategory">
                                        <option value="">---Select---</option>
                                        {this.state.categoryDropdownData.map(category => (
                                            <option key={category.id} value={category.id} >{category.name}</option>
                                        ))}
                                    </Input>
                                    <div className="errorMessage">{this.state.caseCategoryError}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="caseSeverity">Severity <span>&#42;</span></Label>
                                    <Input style={inputStyle} type="select" onChange={(e) => this.handleChange(e)} value={this.state.caseSeverity} name="caseSeverity" id="caseSeverity">
                                        <option value="">---Select---</option>
                                        {/* <option vlaue="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                        <option value="Critical">Critical</option> */}
                                        {this.state.severitylist.map(severity => (
                                            <option key={severity.id} value={severity.name} >{severity.name}</option>
                                        ))}
                                    </Input>
                                    <div className="errorMessage">{this.state.caseSeverityError}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="caseTenant">Tenant</Label>
                                    <Input style={inputStyle} type="text" onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChange(e)} name="caseTenant" id="caseTenant" placeholder="Tenant" disabled readOnly value={this.state.selectedTenant} pattern="plainstring" />
                                    <div className="errorMessage">{this.state.caseTenantError}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="caseStatus">Status <span>&#42;</span></Label>
                                    <Input style={inputStyle} type="select" onChange={(e) => this.handleChange(e)} value={this.state.caseStatus} name="caseStatus" id="caseStatus">
                                        <option value="">---select---</option>
                                        <option value="NEW">NEW</option>
                                        <option value="ASSIGNED">ASSIGNED</option>
                                        <option value="INPROGRESS">INPROGRESS</option>
                                        <option value="ONHOLD">ONHOLD</option>
                                        <option value="RESOLVED">RESOLVED</option>
                                        <option value="CLOSED">CLOSED</option>
                                    </Input>
                                    <div className="errorMessage">{this.state.caseStatusError}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="caseAssignee">Assignee</Label>
                                    <Input style={inputStyle} type="select" className="fa-select" onChange={(e) => this.handleChange(e)} value={this.state.caseAssignee} name="caseAssignee" id="caseAssignee">
                                        <option value="">---Select---</option>
                                        {this.state.userDropdownData.map((option, index) => {
                                            if (option.type === "user")
                                                return <option key={index} value={`user_${option.data.id}`} asigneetype={option.type} asigneename={option.data.firstname + " " + option.data.lastname}>&#xf2be; {option.data.firstname + " " + option.data.lastname}</option>
                                            else if (option.type === "group")
                                                return <option key={index} value={`group_${option.data.id}`} asigneetype={option.type} asigneename={option.data.group_name}>&#xf0c0; {option.data.group_name}</option>
                                        })}
                                    </Input>
                                    <div className="errorMessage">{this.state.caseAssigneeError}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="caseKillChainStage">Kill Chain Stage </Label>
                                    <Input style={inputStyle} type="select" name="caseKillChainStage" id="caseKillChainStage" onChange={(e) => this.handleChange(e)} value={this.state.caseKillChainStage}>
                                        <option value="">---Select---</option>
                                        {this.state.killChainDropdownData.map(killchainstage => (
                                            <option key={killchainstage.id} value={killchainstage.id} killchainstage={killchainstage.name}>{killchainstage.name}</option>
                                        ))}
                                    </Input>
                                    <div className="errorMessage">{this.state.caseKillChainStageError}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="sourceemail">Source Email </Label>
                                    <Input style={inputStyle} onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChange(e)} value={this.state.sourceemail} type="textarea" name="sourceemail" id="sourceemail" placeholder="Source Email ID(s) (Use , to add multiple emails)" pattern="email" />
                                    <div className="errorMessage">{this.state.sourceemailError}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="destinationemail">Destination Email </Label>
                                    <Input style={inputStyle} onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChange(e)} value={this.state.destinationemail} type="textarea" name="destinationemail" id="destinationemail" placeholder="Destination Email ID(s) (Use , to add multiple emails)" pattern="email" />
                                    <div className="errorMessage">{this.state.destinationemailError}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col sm="12" md="12" lg="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="caseEmail">Email ID(s)</Label>
                                    <Input onChange={(e) => this.handleChange(e)} value={this.state.caseEmail}
                                        style={inputStyle} type="textarea" name="caseEmail" id="caseEmail" placeholder="Email ID(s) (Use , to add multiple emails)" pattern="email" />
                                    <div className="errorMessage">{this.state.caseEmailError}</div>
                                </FormGroup>
                            </Col>
                        </Row>*/}
                        <Row>
                            <Col sm="12" md="12" lg="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="">URL(s)</Label>
                                    <Input onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChange(e)} value={this.state.caseURL}
                                        style={inputStyle} type="textarea" name="caseURL" id="caseURL" placeholder="URL(s) (Use , to add multiple URLs)" pattern="url" />
                                    <div className="errorMessage">{this.state.caseURLError}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="caseHash">File Hash</Label>
                                    <Input onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChange(e)} value={this.state.caseHash}
                                        style={inputStyle} type="textarea" name="caseHash" id="caseHash" placeholder="File Hash (Use , to add multiple file hash)" />
                                    <div className="errorMessage">{this.state.caseHashError}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }} for="caseTag">Select Tag</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="12">
                                <FormGroup>
                                    <CreatableSelect type="select"
                                        style={inputStyle}
                                        styles={themeColor === "dark" ? customStyles :""}/*tojo changes: Dashboard- Theme issue arise in "add new case" tab in quick actions 29/06/2023*/
                                        name="tagsData"
                                        id="tagsData"
                                        options={this.state.tagsData}
                                        value={this.state.selectedTag}
                                        onChange={(selectedOption) => this.changeTags(selectedOption)}
                                        isMulti
                                        placeholder="Select Tags"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        




                        {(this.state.metadataAll.length > 0) ?
                            <div>
                                {this.state.metadataAll.map((formFields, forFieldsIndex) => (
                                    <React.Fragment key={forFieldsIndex}>

                                        {(formFields.field_type === "select") ?
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <FormGroup>
                                                    {/* sriraksha changes: added wordBreak -09/06/2023 */}
                                                        <label htmlFor={formFields.field_name} style={{ color: fontColor, wordBreak:"break-all" }}>{formFields.field_name} {(formFields.is_mandatory === true ? '*' : '(Optional)')}</label>
                                                        <select style={inputStyle} className="form-control" id={formFields.id + "custom"} name={formFields.id + "custom"} onChange={(e) => this.handleChangeCustom(e, formFields)} value={this.state[formFields.id + "custom"]} >
                                                            <option value="">---Select---</option>
                                                            {(typeof formFields.fieldvalues != 'undefined' && formFields.fieldvalues.length > 0) ?
                                                                <React.Fragment>
                                                                    {formFields.fieldvalues.map((formFields3, indexFields3) => (
                                                                        <option key={indexFields3} value={formFields3}>{formFields3}</option>
                                                                    ))} </React.Fragment> : <React.Fragment />}

                                                        </select>
                                                        <div className={"help-block with-errors errorMessage " + this.state[formFields.id + "CustomError"]}>please select on of the {formFields.field_name} values</div>
                                                    </FormGroup></Col></Row> : <React.Fragment />
                                        }


                                        {(formFields.field_type === "multi select") ?
                                            <React.Fragment>
                                                <Row>
                                                    <Col sm="12" md="12" lg="12">
                                                        <FormGroup>

                                                            <div style={{ textAlign: "left" }}>
                                                            {/* sriraksha changes: added wordBreak -09/06/2023 */}
                                                                <label className=" form-control-label" style={{ float: "none", color: fontColor, wordBreak:"break-all" }}>{formFields.field_name} {(formFields.is_mandatory === true ? '*' : '(Optional)')}</label>
                                                            </div>
                                                            <div className="form-group" style={{ textAlign: "left" }}>
                                                                <Select style={inputStyle}
                                                                    styles={customStyles2}
                                                                    value={this.state[formFields.id + "custom"]}
                                                                    onChange={(selectedOption) => this.handleChangeCustomMulti(selectedOption, formFields)}
                                                                    options={formFields.fieldvalues}
                                                                    isMulti={true}
                                                                    className="basic-multi-select"
                                                                />
                                                            </div>
                                                            <div className={"help-block with-errors errorMessage " + this.state[formFields.id + "CustomError"]}>{this.state[formFields.id + "Message"]}</div>
                                                        </FormGroup></Col></Row> </React.Fragment> : <React.Fragment />


                                        }

                                        {(formFields.field_type === "radio") ?
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <FormGroup>
                                                        <div style={{ textAlign: "left" }}>
                                                        {/* sriraksha changes: added wordBreak -09/06/2023 */}
                                                            <label htmlFor={formFields.field_name} name={formFields.id + "custom"} style={{ color: fontColor, wordBreak:"break-all" }}>{formFields.field_name} {(formFields.is_mandatory === true ? '*' : '(Optional)')}</label><br />
                                                        </div>
                                                    </FormGroup>
                                                    <div className="form-group" style={{ textAlign: "left" }}>
                                                        {formFields.fieldvalues.map((formFields2, indexFields2) => (
                                                            <label key={indexFields2 + "radio"} className="radio-inline">
                                                                <input type="radio" name={formFields.id + "custom"} value={formFields2} id={formFields2} onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChangeCustom(e, formFields)} checked={this.state[formFields.id + "custom"] === formFields2} />{formFields2}
                                                            </label>
                                                        ))}
                                                    </div></Col></Row> : <React.Fragment />
                                        }



                                        {(formFields.field_type === "input") ?
                                            <React.Fragment>
                                                <React.Fragment>
                                                    <Row>
                                                        <Col sm="12" md="12" lg="12">
                                                            <FormGroup id="">
                                                            {/* sriraksha changes: added wordBreak -09/06/2023 */}
                                                                <label htmlFor={formFields.field_name} style={{ color: fontColor, wordBreak:"break-all" }}>{formFields.field_name} {(formFields.is_mandatory === true ? '*' : '(Optional)')}</label>
                                                                <input placeholder={formFields.placeholder} style={inputStyle} type="text" className="form-control" id={formFields.id + "custom"} name={formFields.id + "custom"} required={formFields.is_mandatory} value={this.state[formFields.id + "custom"]} onKeyPress={this.handleKeyPress} onChange={(e) => this.handleChangeCustom(e, formFields)} />

                                                                <div className={"help-block with-errors errorMessage " + this.state[formFields.id + "CustomError"]}>please enter {formFields.field_name}</div>
                                                            </FormGroup></Col></Row>
                                                </React.Fragment>


                                            </React.Fragment> : <React.Fragment />}

                                    </React.Fragment>
                                ))}
                            </div> : <React.Fragment />
                        }
                        
                        <div>
                            {this.updateCategoryUI(this.state.currentCategory)}
                        </div>
                        </React.Fragment>:
                        <React.Fragment>
                        <Row>
                        <Col sm="6">
                          {/*added this block to display json input box and assignee input box  preetham may26th 2023 */}             
                            <FormGroup>
                                <Label style={{ color: fontColor }} for="caseAssignee">Assignee</Label>
                                <Input style={inputStyle} type="select" className="fa-select" onChange={(e) => this.handleChange(e)} value={this.state.caseAssignee} name="caseAssignee" id="caseAssignee">
                                    <option value="">---Select---</option>
                                    {this.state.userDropdownData.map((option, index) => {
                                        if (option.type === "user")
                                            return <option key={index} value={`user_${option.data.id}`} asigneetype={option.type} asigneename={option.data.firstname + " " + option.data.lastname}>&#xf2be; {option.data.firstname + " " + option.data.lastname}</option>
                                        else if (option.type === "group")
                                            return <option key={index} value={`group_${option.data.id}`} asigneetype={option.type} asigneename={option.data.group_name}>&#xf0c0; {option.data.group_name}</option>
                                    })}
                                </Input>
                                <div className="errorMessage">{this.state.caseAssigneeError}</div>
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm="12" md="12" lg="12">
                            <FormGroup>
                            <ReactTooltip html={true} />
                                <div style={{width:"100%"}}><Label style={{ color: fontColor,width:"100%" }} for="caseJSON">JSON * <span className="jsonTemplate"  style={{float:"right"}}> Copy sample template <i className='fa fa-copy cursor-point' onClick={() => this.copyDefaultJSON(this.state.defaultJSONValue)} style={{ marginLeft: 'auto'}} data-class="my-tooltip" data-place="right" data-tip="Copy Sample JSON Template" /> </span></Label></div>
                                <Input style={inputJSONStyle} onChange={(e) => this.handleChangeJSON(e)} value={this.state.caseJSON} type="textarea" name="caseJSONError" id="caseJSONError" placeholder="Input can be a single JSON object or Array of JSON objects" pattern="json"  rows="2"/>
                                <div className="errorMessage">{this.state.caseJSONError}</div>
                            </FormGroup>
                        </Col>
                    </Row>

                        </React.Fragment>}
                       {/*added this condotion to switch between json and case save buttons preetham may26th 2023 */}             
                        {!this.state.useJSON ?
                        <div className="pull-right" style={{ marginTop: "20px" }}>
                            <Button size="sm" color="success" id="CaseSave" disabled={this.state.btnDisabled} onClick={() => this.saveCase()}>Save</Button>
                            <Button size="sm" id="CaseClose" onClick={() => this.closeModal()}>Close</Button>
                        </div>:
                        
                        <div className="pull-right" style={{ marginTop: "20px" }}>
                        <Button size="sm" id="CaseSaveJSON" color="success" disabled={this.state.btnDisabledJSON} onClick={() => this.saveCaseJSON()}>Save</Button>
                        <Button size="sm" ID="CaseCloseJSON" onClick={() => this.closeModal()}>Close</Button>
                        </div>}
                   

                    </ModalBody>
                </Modal>            

                <NotificationComponent showNotificationModal={this.state.showNotificationModal}
                    notificationMessage={this.state.notificationMessage} toggleNotificationModal={this.toggleNotificationModal} />

                <ToastMessage showMessage={this.state.showMessage} messageType={this.state.messageType}
                    toastMessage={this.state.toastMessage} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    theme: state.reducer.theme.theme,
    categories: state.reducer.caseReducer.categories,
    killchainstages: state.reducer.killChainReducer.killchainstages,
    users: state.reducer.userReducer.users,
    userGroups: state.reducer.userGroups,
    currentTenant: state.reducer.currentTenant,
    changeSeverity: state.reducer.soarWidgetReducer, //author:divya change:- adding quickActionSeverity data
    reloadComponentsForTenant: reloadComponentsForTenant,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getCaseCategories: getCaseCategories,
        getKillChainStage: getKillChainStage,
        getUsersList: getUsersList,
        getUsersGroup: getUsersGroup,
        updateMycase: updateMycase,
        getSoarWidgetData: getSoarWidgetData //author:divya change:- adding getSoarWidgetData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickActionCase)
