import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RadialGauge } from '@progress/kendo-react-gauges';
import { bindActionCreators } from "redux";

export class GaugeChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: 0
        };
    }

    componentDidMount() {
        let value = this.props.confidencevalue;
        if (typeof value !== 'undefined') {
            this.setState({ value });
        }
    }

    componentWillReceiveProps(nextProps) {
        let value = nextProps.confidencevalue;
        if (typeof value !== 'undefined' && this.state.value !== value) {
            this.setState({ value });
        }
    }

    render() {
        let fontColor = this.props.theme ? this.props.theme.color : "#2A3139";
        var ticksColor = fontColor;
        const radialOptions = {
            value: this.state.value,
            shape: 'arrow',
            scale: {
                minorUnit: 0,
                majorUnit: 0,
                max: 10,
                ranges: [
                    { from: 0, to: 2, color: '#78d237' },
                    { from: 2, to: 5, color: 'orange' },
                    { from: 5, to: 10, color: '#c20000' }
                ],
                rangeSize: 4,
                ticksColor: '#999',
                labelsColor: ticksColor,
                majorTicks: { visible: true, color: ticksColor },
                minorTicks: { visible: true, color: ticksColor },
                labels: { color: ticksColor, visible: true },
                ticksColor,
            },
            pointer: [{
                value: this.state.value,
                color: '#999'
            },
            ]
        };

        return (
            <RadialGauge {...radialOptions} />
        );
    }
}

function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GaugeChart)
