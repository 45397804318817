import React, { Component } from 'react';
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { Card, Nav, Col, Container } from 'react-bootstrap';
import { Button, Input, Row, Label } from "reactstrap";
//import Menu from "./Menu";
import { withRouter } from 'react-router-dom';
import helpers from "../helpers";
// import { toast } from 'react-toastify';
import AssetBatchSetting from "./AssetBatchSetting";
import AssetBusinessHierarchySetting from "./AssetBusinessHierarchySetting";
import FrequencyBatchSetting from "./FrequencyBatchSetting";
const restURL = helpers.restURL;



class AssetSetting extends Component {
	constructor() {
		super();
		this.state = {
			serverIP: "", enableupdate: false, ServerIPerror: "hide"
		}
		this.handleIPchange = this.handleIPchange.bind(this);
		this.Testconnectivity = this.Testconnectivity.bind(this);
		this.updateServer = this.updateServer.bind(this);
		this.getServerdetails = this.getServerdetails.bind(this);
	}

	showFailedNotification = (message) => {
		helpers.securaaNotification(message,"error",10);
		// toast.error(message, {
		// 	position: "top-right",
		// 	autoClose: 2000,
		// 	closeOnClick: true,
		// 	hideProgressBar: true,
		// 	pauseOnHover: false,
		// 	draggable: false
		// });
	}

	showSuccessNotification = (message) => {
		helpers.securaaNotification(message,"success",10);
		// toast.success(message, {
		// 	position: "top-right",
		// 	autoClose: 2000,
		// 	closeOnClick: true,
		// 	hideProgressBar: true,
		// 	pauseOnHover: false,
		// 	draggable: false
		// });
	}

	handleIPchange(e) {
		this.setState({ serverIP: e.target.value, enableupdate: false })
	}
	getServerdetails() {
		var url = restURL;
		axios({
			method: "GET",
			url: url + "?module=manager&rest=getServerIp/avm",
			headers: {
				access_token: helpers.getCookie('accesstoken'),
				jwt_token: helpers.getCookie('jwt_token'),
			},
			responseType: "json"
		}).then(res => {
			helpers.checkSessionTimeout(res.data.sessionexpired);
			if (res.data.success) {
				this.setState({ serverIP: res.data.data })
			} else {
				if (res.data.displaymessage.toLowerCase() == "securaa server is not reachable") {
					this.showFailedNotification("AVM server is not reachable.");
				} else {
					this.showFailedNotification(res.data.displaymessage);
				}
			}
		}).catch(function (error) {
			console.log(error);
		});
	}

	Testconnectivity() {
		if (this.state.serverIP != "") {
			var ServerIP = this.state.serverIP;
			var url = restURL;
			axios({
				method: "GET",
				url: url + "?module=manager&rest=verifyServerConnectivity/avm/" + ServerIP,
				headers: {
					access_token: helpers.getCookie('accesstoken'),
					jwt_token: helpers.getCookie('jwt_token'),
				},
				responseType: "json"
			}).then(res => {
				if (res.data == null || res.data == undefined) {
					this.showFailedNotification("Unable to get Response.");
				} else {
					helpers.checkSessionTimeout(res.data.sessionexpired);
					if (res.data.success) {
						this.setState({ enableupdate: true })
						this.showSuccessNotification(res.data.displaymessage);
					} else {
						if (res.data.displaymessage.toLowerCase() == "securaa server is not reachable") {
							this.showFailedNotification("AVM server is not reachable.");
						} else {
							this.showFailedNotification(res.data.displaymessage);
						}
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		} else {
			this.setState({ ServerIPerror: "show" })
			setTimeout(() => { this.setState({ ServerIPerror: "hide", }); }, 1000);
		}
	}
	updateServer() {
		var url = helpers.restURL;
		var data = {
			rest: 'updateconfig',
			module: 'manager',
			codemodule: 'avm',
			tenantcode: helpers.getCookie("tenantcode"),
			ipaddress: this.state.serverIP
		};
		axios({
			method: "POST",
			url: url,
			headers: {
				access_token: helpers.getCookie('accesstoken'),
				jwt_token: helpers.getCookie('jwt_token'),
				"Content-Type": "application/json; charset=utf-8"
			},
			dataType: "json",
			data: JSON.stringify(data),
			responseType: "json"
		}).then(res => {
			if (res.data == null || res.data == undefined) {
				this.showFailedNotification("Unable to get response.");
			} else {
				helpers.checkSessionTimeout(res.data.sessionexpired);
				if (res.data.success) {
					this.showSuccessNotification(res.data.displaymessage);
				} else {
					if (res.data.displaymessage.toLowerCase() == "securaa server is not reachable") {
						this.showFailedNotification("AVM server is not reachable.");
					} else {
						this.showFailedNotification(res.data.displaymessage);
					}
				}
			}
		}).catch(function (error) {
			console.log(error);
		});
	}
	componentDidMount() {
		this.getServerdetails();
	}

	render() {
		let divStyle = { color: '' };
		let inputStyle = { color: "" };
		if (typeof this.props.theme !== 'undefined') {
			divStyle = { background: "rgb(39, 43, 53)", color: "rgb(207, 210, 218)", "box-shadow": "white 0px 0px 1px 0px", borderRadius: "4px", height: "200px", overflow: "auto" };
			inputStyle = this.props.theme.input;
		}
		const fontColor = this.props.theme ? this.props.theme.color : "#2A3139";
		return (
			<div>
				<div className="homeContainer" style={{ "padding": "10px" }}>
					<Col sm="6" lg="6" md="6" xs="6" >
						<div style={divStyle} className="x_panel">
							<div className="x_title">
								<h5 style={{ color: fontColor, textAlign: "left" }}>
									AVM Server Configuration</h5>
							</div>
							<div className="x_content" style={{ width: "60%" }}>
								<Label style={{ float: 'left', color: fontColor }}>Server IP</Label>
								<Input type="text" name="Server IP" placeholder="Enter AVM Server IP" value={this.state.serverIP} onChange={(e) => this.handleIPchange(e)} style={inputStyle} ></Input>
								<div className={"help-block with-errors " + this.state.ServerIPerror}>Please Enter Server IP</div>

								<Button size="sm" color="success" style={{ float: 'left', marginTop: "5px", color: fontColor }} onClick={this.Testconnectivity} > &nbsp;Test Connectivity</Button>
								<Button size="sm" color="success" style={{ float: 'left', marginTop: "5px", color: fontColor }} disabled={!this.state.enableupdate} onClick={this.updateServer} > Update&nbsp;</Button>


							</div>
						</div>
					</Col>
					<Col sm="6" lg="6" md="6" xs="6">
						<div style={divStyle} className="x_panel">
							<div className="x_title">
								<h5 style={{ color: this.props.theme ? this.props.theme.color : "#2A3139", textAlign: "left" }}>
									<i className="fa fa-clock-o"></i> Asset Batch Settings</h5>
							</div>
							<div className="x_content">
								<AssetBatchSetting />
							</div>
						</div>
					</Col>

					<Col sm="6" lg="6" md="6" xs="6">
						<div style={divStyle} className="x_panel">
							<div className="x_title">
								<h5 style={{ color: this.props.theme ? this.props.theme.color : "#2A3139", textAlign: "left" }}>
									<i className="fa fa-clock-o"></i> Vulnerability Batch Settings</h5>
							</div>
							<div className="x_content">
								<FrequencyBatchSetting />
							</div>
						</div>
					</Col>

					<Col sm="6" lg="6" md="6" xs="6">
						<div style={divStyle} className="x_panel">
							<div className="x_title">
								<h5 style={{ color: this.props.theme ? this.props.theme.color : "#2A3139", textAlign: "left" }}>
									<i className="fa fa-clock-o"></i> Asset Business Hierarchy Settings</h5>
							</div>
							<div className="x_content">
								<AssetBusinessHierarchySetting />
							</div>
						</div>
					</Col>
				</div>
			</div >
		)
	}
}

function mapStateToProps(state) {
	return {
		theme: state.reducer.theme.theme
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch)
}

const exportAssetSetting = withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetSetting));
export default exportAssetSetting;
