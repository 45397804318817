import { combineReducers } from "redux";
import themeReducer from "./themeReducer";
import resizeReducer from "./resizeReducer";
import datePickerReducer from "./datePickerReducer";
import customDashboardReducer from "./customDashboardReducer";
import tenantReducer from "./tenantReducer";
import caseReducer from "./caseCategoryReducer";
import killChainReducer from "./killChainReducer";
import userReducer from './userReducer';
import taskReducer from "./taskReducer";
import playbookReducer from "./playbookReducer";
import updatePlaybookResponse from "./updatePlaybookResponse";
import tiSearchReducer from "./tiSearchReducer";
import reportReducer from "./reportReducer";
import taskHeaderReducer from "./taskHeaderReducer";
import customAppReducer from "./customAppReducer";
import playbookExecutionReducer from "./playbookExecutionReducer";
import fullScreenReducer from "./workPlanReducer";
import playbookWorkPlanUpdateReducer from "./workPlanTaskUpdate";
import taskEvidanceReducer from "./taskEvidanceReducer";
import isEvidanceReducer from "./isEvidanceReducer";
import isEvidanceRemovedReducer from "./isEvidanceRemovedReducer";
import { serializeDiagram, cloneSelected, fitToScreen } from "./newPlaybookReducer";
import updateMyCases from "./myCasesReducer";
import userGroupReducer from "./userGroupReducer";
import casePanelReducer from "./casePanelReducer";
import samlReducer from "./samlReducer";
import { getTipTags } from "./tipReducer";
import indicatorByTagsReducer from "./indicatorByTagsReducer";
import provicersReducer from "./providersReducer";
import providerstagsReducer from "./providerstagsReducer";
import dashboardlistReducer from "./dashboardlistReducer";
import soarWidgetReducer from "./widgets/soarWidgetReducer";
import generalReducer from "./platform/generalReducer";
import caseManageReducer from "./platform/caseManageReducer";
import userManageReducer from "./platform/userManageReducer";
import automationPlaybookReducer from "./widgets/automationPlaybookReducer";
import reportListReducer from "./reports/reportListReducer";
import appConfigureReducer from "./apps/appConfigureReducer";
import  updatetipdataReducer  from "./updatetipdataReducer";
import drillDownReducer from "./drillDownReducer";
import logoupdateReducer from "./logoUpdateReducer"
import refreshReducer from "./refreshReducer"
import performanceUpdateReducer from "./performanceUpdateReducer";
export default combineReducers({
    theme: themeReducer,
    resize: resizeReducer,
    dateRangeValue: datePickerReducer,
    loadwidgets: customDashboardReducer,
    currentTenant: tenantReducer,
    caseReducer: caseReducer,
    killChainReducer: killChainReducer,
    userReducer: userReducer,
    taskUpdate: taskReducer,
    playbookDefinition: playbookReducer,
    playbookUpdate: updatePlaybookResponse,
    tisearchdata: tiSearchReducer,
    reportUpdate: reportReducer,
    taskHeaderColor: taskHeaderReducer,
    customappdata: customAppReducer,
    playbookExecutionUpdate: playbookExecutionReducer,
    fullScreen: fullScreenReducer,
    workPlanTasks: playbookWorkPlanUpdateReducer,
    taskEvidanceUpdate: taskEvidanceReducer,
    isEvidance: isEvidanceReducer,
    isEvidanceRemoved: isEvidanceRemovedReducer,
    serializeDiagram: serializeDiagram,
    cloneSelected: cloneSelected,
    fitToScreen: fitToScreen,
    updateMyCases: updateMyCases,
    userGroups: userGroupReducer,
    updateCaseTimeLine: casePanelReducer,
    samlresponse: samlReducer,
    tiptags: getTipTags,
    updateIndicatorsByTagsData: indicatorByTagsReducer,
    updateprovidersData: provicersReducer,
    updateproviderstagsData: providerstagsReducer,
    updatedashboardlist: dashboardlistReducer,
    soarWidgetReducer: soarWidgetReducer,
    updatetipdata:updatetipdataReducer,
    generalReducer:generalReducer,
    caseManageReducer:caseManageReducer,
    drillDowndata:drillDownReducer,
    logoupdatedata:logoupdateReducer,
    refreshData:refreshReducer,
    performanceUpdate:performanceUpdateReducer
    // userManageReducer:userManageReducer,
    // automationPlaybookReducer:automationPlaybookReducer,
    // reportListReducer:reportListReducer,
    // appConfigureReducer:appConfigureReducer,
});
